.Heading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1C1C1C;
}

.Tab {
    font-weight: 600;
    font-size: 16px;
    color: #2F8FFF;
    position: relative;
}

.HorizontalLine {
    position: absolute;
    border: 2px solid #2F8FFF;
    width: 96px
}