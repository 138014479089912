    .options {
        color: #AEAEAE;
        font-size: 10px;
    }

    .progressBar {
        width: 90%;
        background-color: #F3F3F3;
        border-radius: 10px;
        height: 10px;
        .metPlusPartial{
            background-color: #FFF2CC;
            height: 100%;
            border-radius: 10px;
            .met {
                background-color: #E5FCDE;
                height: 100%;
                border-radius: 10px;
            }
        }
    }

    .stats{
        font-family: Gilroy-SemiBold,sans-serif;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.00019968050764873624px;
        text-align: left;
        padding-top:10px;
        width:90%;
        .statsMet{
            display: inline-block;
            color:#00AA00;
            width: 40%;
        }
        .statsPartially{
            display: inline-block;
            color:#FCBF07;
            width: 30%;
        }
        .statsNotMet{
            display: inline-block;
            color: #939393;
            text-align: right;
            width: 30%;
        }
    }
    .notAvailable{
        color: #aeaeae;
        font-size: 24px;
        margin-right: 20px;
    }