/* Filters css starts */
/* calender css starts */
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: none !important;
    font-family: 'Graphik' !important;
    opacity: 0.70;
}
.react-calendar__navigation__label{
    font-size: 12px !important;
}
.react-calendar__tile{
    font-size: 10px;
}
.react-calendar__tile--now, .react-calendar__tile--active {
    background:  white !important;
    border: 1px solid #000000 !important;
    border-radius: 100% !important;
    color: #000000 !important;
}
.react-calendar__month-view__weekdays {
    font-weight: 300 !important;
}
abbr[title] {
    font-size: 10px !important;
    text-decoration: none !important;
}
.filter-calender{
    width: 266px;
}
/* calender css ends */

/* Searchbar css starts */
.filter-search{
    height: 87px;
    width: 266px;
}
.filter-searchbar{
    margin-top: 14px;
}
.filter-searchbar .filter-search-input{
    padding-left: 2rem;
    width: 100%;
    border: 1px solid #D2D2D2;
    height: 30px;
    border-radius: 4px;
    background-color: #F4F4F4;
    color: #d2d2d2;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    outline: none;
}
.filter-searchbar .filter-search-icon{
    position: absolute;
    z-index: 2;
    display: block;
    line-height: 29px;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    padding-left: 10px;
}
/* Searchbar css ends */

/* Range Slider css starts */
.filter-slider{
    height: 136px;
    width: 266px;
}
.card #slider {
    position: relative;
    margin: 0 auto;
    width: 234px;
    height: 8px;
    background: #D5D5D5;
    border-radius: 5px;
    cursor: pointer;
}
.card #slider #min {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 0;
    height: 8px;
    border-radius: 5px;
    background: #D5D5D5;
}
.card #slider #min:hover::before {
    opacity: 1;
}
.card #slider #min:hover::after {
    opacity: 1;
}
.card #slider #min::before {
    opacity: 0;
    content: attr(data-content);
    display: block;
    position: absolute;
    top: -40px;
    right: -23px;
    width: 40px;
    padding: 3px;
    text-align: center;
    color: white;
    background: #D5D5D5;
    border-radius: 20px;
}
.card #slider #min::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    top: -18px;
    right: -8px;
    border-top: 8px solid #274684;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}
.card #slider #min #min-drag {
    position: absolute;
    right: -7.5px;
    top: -8px;
    box-sizing: border-box;
    height: 20px;
    width: 9px;
    border: 2px solid #FFFFFF;
    border-radius: 4.5px;
    background-color: #29153B;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.5);
    background: #071739;
    transition: all 0.3s;
}
.card #slider #min #min-drag:hover {
    box-shadow: 0 0 0 6px rgba(39, 70, 132, .2);
}
.card #slider #max {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 0;
    height: 8px;
    border-radius: 5px;
    background: #4C8DFF;
}
.card #slider #max:hover::before {
    opacity: 1;
}
.card #slider #max:hover::after {
    opacity: 1;
}
.card #slider #max::before {
    opacity: 0;
    content: attr(data-content);
    display: block;
    position: absolute;
    top: -40px;
    right: -23px;
    width: 40px;
    padding: 3px;
    text-align: center;
    color: white;
    background: #274684;
    border-radius: 20px;
}
.card #slider #max::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    top: -18px;
    right: -8px;
    border-top: 8px solid #274684;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}
.card #slider #max #max-drag {
    position: absolute;
    right: -7.5px;
    top: -8px;
    box-sizing: border-box;
    height: 20px;
    width: 9px;
    border: 2px solid #FFFFFF;
    border-radius: 4.5px;
    background-color: #29153B;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.5);
    background: #071739;
    transition: all 0.3s;
}
.card #slider #max #max-drag:hover {
    box-shadow: 0 0 0 6px rgba(39, 70, 132, .2);
}
.filter-value[type="number"]::-webkit-outer-spin-button, .filter-value[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.filter-value[type="number"] {
    -moz-appearance: textfield;
}

/* Range Slider css ends */

/* Selector css starts */
.filter-selector{
    height: auto;
    width: 266px;
}
.input-selected{
    border-radius: 4px;
    min-width: max-content;
    padding: 3px 8px;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    margin: 0px 8px 8px 0px;
    font-weight: 300ṁ;
    align-items: center;
}

.input-selected img{
    margin-right: 3px;
    height: 10px;
    width: 10px;
}
.active-input-select{
    background-color: #eff3f8;
    border: 1px solid #4C8DFF;
}
.inactive-input-select{
    border: 1px solid #D2D2D2;
    background-color: #F4F4F4;
}
/* Selector css ends */

/* Common css in all filters starts */
.filter-value{
    box-sizing: border-box;
    height: 23px;
    width: 100px;
    border: none;
    border: 1px solid #D2D2D2;
    border-radius: 4px;
    outline: none;
    opacity: 0.44;
    color: #000000;
    font-size: 10px;
    letter-spacing: 0px;
    line-height: 12px;
}
.filter-value:hover,.filter-value:focus{
    border: 1px solid #4C8DFF !important;
    background-color: #F4F8FF !important;
}
.filter-type{
    opacity: 0.65;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}
.border-shadow{
    padding: 15px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 17px 4px rgba(0,0,0,0.13);
}
/* Common css in all filters ends */
/* Filters css ends */
