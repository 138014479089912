@mixin Circle {
    width: 10px;
    height: 10px;
    border-radius: 9px;
    margin-right: 8px;
}
.tableData{
    font-family: Gilroy-Medium,sans-serif;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.00019968050764873624px;
    text-align: left;
    height: 80px;
    border-bottom: 1px solid #E9E9E9;
}
.complianceComments{
    height: 58px;
    width: 180px;
    left: 0px;
    top: 0px;
    padding:5px;
    border:none;
    border-radius: 4px;
    background: #F6F6F6;
}

.ComplianceDropdown {
    position: relative;

    .OptionSelected {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F6F6F6;
        border-radius: 4px;
        width: 116px;
        padding: 5px;
    }

    .Options {
        width: 116px;
        position: absolute;
        padding: 5px;
        margin-top: 5px;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0640844);
        border-radius: 6px;
        z-index: 1;

        span {
            margin: 2px 0;
            padding:5px 2px;
            border-radius: 2px;
        }
        span:hover{
            background-color: #E9E9E9;
        }
    }

    .GreenCircle {
        @include Circle;
        background: #00AA00;
    }

    .GreyCircle {
        @include Circle;
        background: #939393;
    }

    .YellowCircle {
        @include Circle;
        background: #FCBF07;
    }

    .BlueCircle {
        @include Circle;
        background: #2F8FFF;
    }
}
.pl30{
    padding-left: 30px;
}
.detailCol{
    padding: 2px 15px;
    width:25%;
}
.CollectionComments {
    textarea {
        background-color: #f6f6f6;
        border-radius: 6px;
        border-color: #f6f6f6;
    }
}

.commentsBox {
    font-family: gilroy-medium, sans-serif;
    background-color: #f6f6f6;
    border-radius: 6px;
    border-color: #f6f6f6;
    overflow: auto;
    resize: vertical;
    width: 139px;
    height: 61px;
    padding: 10px;
}
.commentStyle{
    color: #C5C5C5;
    font-size: 8px;
}

.downloadFileBtn {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    margin: 4px 0;

    .downloadIcon {
        margin-right: 8px;
    }
}
