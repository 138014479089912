.tableRow {
    td {
        font-size: 12px;
        padding: 12px;
        border-bottom: 1px solid #eee;
    }
}

.companyName {
    padding: 0px 34px !important;
    width: 21%;
}

.lenderName {
    padding: 0px 5px 0px 0px !important;
    width: 21%;
}

.amount {
    padding: 0px 0px 0px 8px !important;
    width: 12%;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

.transactionDate {
    padding: 0px 0px 0px 8px !important;
    width: 14%;

    input {
        border: 0.5px solid #4C8DFF;
        border-radius: 4px;
        padding: 0.2rem 0.5rem;
        opacity: 0.7;
    }
}

.statusContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px !important;
    width: 74px;
    border-radius: 6px;
}

.utrInputContainer {
    width: 12%;
    color: #000000;
    font-weight: 600;
    padding: 0px 10px 0px 8px !important;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;

    .utrInput {
        width: 55%;
        border: 0.5px solid #4C8DFF;
        border-radius: 4px;
        padding: 0.2rem 0.5rem;
        opacity: 0.7;
    }
}

.statusInputContainer {
    width: 12%;
    font-size: 12px;
    color: #000000;
    font-weight: 600;
    padding: 0px 0px 0px 8px !important;

    .statusSelect {
        background-color: #F6F6F6;
        height: 24px;
        width: 94px;
        font-size: 10px;
        font-weight: 500;
        border-radius: 4px;
        padding: 0rem 0.5rem;
        border: none;
    }
}

.actionButtonsContainer {
    width: 10%;
    max-width: 100px !important;
    padding: 0px !important;

    button {
        background-color: rgba(255, 255, 255, 0);
        border: none;
    }
}

.actionButtons {
    display: flex;
    
    button {
        border-radius: 4px;
        cursor: pointer;
        border: none;
    }
}