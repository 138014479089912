.ViewFilterContainer{
    border: none;
    font-family: Gilroy-Bold, serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #fff;
    border-radius: 0px;
    padding: 4px 4px;
    cursor: pointer;
  }
  
  .ViewFilterSelect{
    width: 10rem;
    border: none;
    padding: 2px 8px;
    background-color: #fff;
  
  }