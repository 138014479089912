.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy-Semibold", sans-serif;
}

.modalContent {
  background-color: #ffffff;
  width: min(538px, 95%);
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 34px;
  display: flex;
  flex-direction: column;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 33px
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
        font-size: 1rem;
        font-weight: 600;
        color: #333;
    }
}

.inputFieldContainer {
  display: flex;
  flex-direction: column;
}

.selectContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  border: 0.5px solid #ccc;
  border-radius: 6px;
  
  &:focus-within {
    border-color: #4C8DFF;
  }
  
  &.hasValue {
    border-color: #4C8DFF;
    background-color: #fff;
  }
}

.input {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 12px;
  background-color: #F9F9F9;
  border: none;
  border-radius: 6px;
  outline: none;
  
  &:focus {
    background-color: #fff;
  }

  .hasValue & {
    background-color: #fff;
  }
}

label {
  font-size: 8px;
  color: #8D8D8D;
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 0px;
}

.addButton {
  padding: 10px 20px;
  height: 41px;
  font-size: 12px;
  color: #fff;
  background-color: #4C8DFF;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.twoColumnRow {
  display: flex;
  gap: 20px;
  
  .inputFieldContainer {
    flex: 1;
  }
}

.dropdownContainer {
  position: relative;
  width: 470px;
}