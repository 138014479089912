$container-gap: 15px;
.underwriting_table__container {
    display: flex;
    flex-wrap: nowrap;
  .contract_setting__container {
    height: fit-content;
    font-family: 'Gilroy-SemiBold';
    margin-left: 35px;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    // padding: 0 15px;
    width: 180px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .heading {
      font-family: 'Gilroy-SemiBold',sans-serif;
      font-size: 12px;
      line-height: 14px;
      margin: 15px auto 10px auto;
      padding: 0 15px;
    }
 
    .score_container{
        padding: 10px 15px 15px 15px;
        font-size: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        input{
            width: 20px;
            height: 18px;
            margin: 0 -10px 0 10px;
        }
    }

    .sync_contracts{
      margin: 0 15px;
      padding: 8px 10px;
      background: #4C8DFF;
      border-radius: 4px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 9px;
      font-family: 'Gilroy-Medium',sans-serif;
      cursor: pointer;

      &>img{
        height: 11px;
        margin-right: 5px;
        margin-bottom: 2px;
      }
    }

    .footer{
      margin-top: 20px;
      background: #F3F3F3;
      font-family: 'Gilroy-Medium',sans-serif;
      font-size: 10px;
      line-height: 15px;
      color: black;
      padding: 8px;
      text-align: center;

      .last_synced{
        font-style: italic;
      }
    }
  }
}

.namecolumn{
  min-width: 230px;
  position: sticky;
  left: 0;
  border-radius: 1px solid black;
  background-color: white;
}

.subrow{
  padding-left: 20px !important;
  color: #939393;
  background-color: white;
  position: sticky;
  left: 0;
}

.expandimg{
  margin-left: 10px;
  transition: transform 200ms ease-in-out;
}

.rotateimg{
  transform: rotate(180deg);
}