@mixin status($bgcolor, $color) {
    float: left;
    height: 24px;
    width: 84px;
    border-radius: 4px;
    font-family: 'Gilroy-Medium';
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: $bgcolor;
    color: $color;
}

.CheckoutRow {

    .Collected {
        @include status(#E5FCDE, #49A71B);
    }

    .In_Process {
        @include status(#F4F4F4, #989898);
    }

    .CustomPayment {
        float: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 21px;
        width: 72px;
        padding: 3px;
        border-radius: 4px;
        background-color: #E9EAEB;
        cursor: pointer;

        span {
            text-align: center;
            width: 33px;
            border-radius: 2px;
            color: #4F4F4F;
            font-family: 'Gilroy-SemiBold';
            font-size: 10px;
        }

        .NoActive {
            background-color: white;
        }

        .YesActive {
            color: white;
            background-color: #27BA27;
        }
    }
}

.HasSearch {
    display: flex;
    position: relative;
    z-index: 0;

    .SearchLogo {
        margin-left: -36px;
        z-index: 2;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2rem;
        text-align: center;
        pointer-events: none;

        img {
            height: 14px;
            width: 14px;
        }
    }

    input {
        padding: 10px;
        height: 34px;
        width: 100%;
        max-width: 232px;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        border-radius: 6px;
        font-family: 'Gilroy-Medium';
        color: #c3c3c3;
        border: 0.5px solid #f9f9f9;
        background-color: #f9f9f9;

        &:valid,
        &:focus-visible,
        &:focus-within,
        &:focus {
            font-family: 'Gilroy-SemiBold';
            outline: none;
            color: #000000;
            border: 0.5px solid #c1c1c1;
            background-color: white;
        }
    }
}

.BoxWithBorder {
    padding: 10px 0;
    position: absolute;
    z-index: 3;
    width: 100%;
    max-width: 232px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
    max-height: 135px;
    overflow: overlay;

    &::-webkit-scrollbar {
        width: 19px !important;
        scroll-padding-right: 100px !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px !important;
        border-left: 0px solid transparent !important;
        border-right: 0px solid transparent !important;
        margin: 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e6e6e6 !important;
        border-radius: 10px !important;
        border-top: 6px solid white !important;
        border-bottom: 6px solid white !important;
        border-left: 6px solid white !important;
        border-right: 6px solid white !important;
    }

    .CustomerName {
        cursor: pointer;
        padding: 3px 10px;

        &:hover {
            background-color: #f9f9f9;
        }
    }
}

.HighlightText {
    background-color: #e8f4ff;
}