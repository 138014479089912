@mixin PGStatus {
  height: 17px;
  border-radius: 4px;
  font-size: 10px !important;
  text-transform: uppercase;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConnectNewPGBtn {
  height: 28px;
  width: 122px;
  margin-right: 15px;
  border: 1px solid #4c8dff;
  border-radius: 4px;
  color: #4c8dff;
  font-size: 10px;
  font-weight: 600;
  background-color: white;
}

.ExecuteCollection {
  height: 28px;
  width: 122px;
  border: 1px solid #4c8dff;
  border-radius: 4px;
  background-color: #4c8dff;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;

  &:disabled {
    cursor: no-drop;
    background-color: #b7b7b7;
    border: none;
    color: rgba(0, 0, 0, 0.65);
  }
}

.PGCollectionTable {
  border: 1px solid #e9e9e9;
  border-radius: 6px;

  th {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    border-top: 0px;
  }

  td {
    color: #2f363f;
    font-size: 12px;
    font-weight: 300;
  }
}

.AmountToCollectInput {
  height: 25px;
  width: 142px;
  border: 0.5px solid #f9f9f9;
  border-radius: 6px;
  background-color: #f9f9f9;
  outline: none;
  color: #c3c3c3;
  font-size: 12px;
  padding: 12px;
  font-weight: 300;

  &:valid,
  &:focus {
    color: #000000;
    border: 0.5px solid #4c8dff;
    background-color: #ffffff;
  }
}

.RAZORPAY {
  @include PGStatus;
  width: 108px;
  color: #9e3cb9 !important;
  background-color: #fdf4ff;
}

.PAYTM {
  @include PGStatus;
  width: 108px;
  color: #4c8dff !important;
  background-color: #e4eeff;
}

.CONNECTED {
  @include PGStatus;
  width: 80px;
  color: #49a71b !important;
  background-color: #e5fcde;
}

.AVAILABLE {
  @include PGStatus;
  width: 80px;
  color: #49a71b !important;
  background-color: #e5fcde;
}

.PROCESSED {
  @include PGStatus;
  width: 80px;
  color: #989898 !important;
  background-color: #f4f4f4;
}

.table_heading{
  margin: 30px 0;
}
