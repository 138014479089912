.banner {
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
}

.Refunded {
    @extend .banner;
    background: #e5fcde;
    color: #00aa00;
}

.NotClaimed {
    @extend .banner;
    background: #E8F2FF;
    color: #2F8FFF;
}

.Submitted {
    @extend .banner;
    background: #fdf4ff;
    color: #9e3cb9;
}

.Rejected {
    @extend .banner;
    background: #fdf0ec;
    color: #df666a;
}

.actionButton {
    height: 30px;
    border: none;
    width: 7rem;
    font-family: gilroy-semibold, sans-serif;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 4px;
}

.WhiteBtn {
    @extend .actionButton;
    border: 0.5px solid #2f8fff;
    color: #2f8fff;
}

.BlueBtn1 {
    @extend .actionButton;
    background-color: #2f8fff;
    color: #fff;
}

.dNone {
    display: none;
}

.btnConatiner {
    display: flex;
    justify-content: right;
    margin-left: 1rem;
    align-items: center;
    padding: 1.5rem 0 0.5rem 0;
    gap: 1rem;

    button {
        padding: 0.3rem;
        font-size: 12px;
        width: 5.5rem;
        box-shadow: none;
        font-family: gilroy-semiBold;
        border-radius: 4px;
    }

    .Active {
        background-color: #2f8fff;
        color: white;
        border: 0.5px solid #4c8dff;
    }

    .Inactive {
        background-color: #f3f3f3;
        border: 0.5px solid #dbdbdb;
    }
}

.modal-contentTds {
    margin-left: 25%;
    margin-bottom: 70%;
    width: 330px;
}

.pickDate {
    display: flex;
    width: 98%;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;

    label {
        color: #8d8d8d;
        font-family: 'Gilroy-Medium';
        font-size: 8px;
        position: relative;
        top: -1.3rem;
        left: 50%;
        margin-bottom: 0px !important;
    }
}

.TableLabel {
    font-family: gilroy-medium, sans-serif;
    font-size: 12px;
    margin-left: 3rem;
    margin-bottom: -2rem;
}

.basicInfoContainer {
    background-color: #f9f9f9;
    padding: 1.3rem;
    border-radius: 4px;
    font-size: 11px;
}

.fileBtn {
    width: 100%;
    background-color: transparent;
    border: none;
    color: #4C8DFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 12px;
    font-family: gilroy-semibold, sans-serif;
    &:disabled{
        color: #8D8D8D;
        cursor: not-allowed;
    }
}

.uploadContainer {
    // border-radius: 4px;
    // padding: 0.5rem;
    position: relative;
    // background-color: #F9F9F9;
}

.moderatorComments {
    padding: 0.5rem;
    font-family: gilroy-medium, sans-serif;
    width: 100%;
    border: 0.5px solid #dbdbdb;
    border-radius: 4px;
    font-size: 12px;

    &:focus {
        border: 0.5px solid #2f8fff;
    }

    &:disabled {
        border: none;
        background-color: #F9F9F9;
    }

}

.uploadLabel {
    position: absolute;
    bottom: 78%;
    left: 0.5rem;
    font-family: gilroy-medium, sans-serif;
    font-size: 8px;
    color: #8D8D8D;
    margin: 0;
}

.rejectBtn {
    width: 45%;
    background-color: #DF666A;
    border: none;
    color: white;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    border-radius: 4px;
    padding: 0.5rem;
    &:disabled {
        cursor: not-allowed;
    }
}

.approveBtn {
    width: 45%;
    background-color: #00AA00;
    border: none;
    color: white;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    border-radius: 4px;
    padding: 0.5rem;
    &:disabled {
        cursor: not-allowed;
    }
}

.sendToInv {
    width: 100%;
    background-color: #2F8FFF;
    border: none;
    color: white;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    border-radius: 4px;
    padding: 0.5rem;
    &:disabled{
        cursor:no-drop;
    }
}

.resendToInv {
    width: 100%;
    background-color: white;
    border: none;
    color: #2F8FFF;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    border:1px solid #2F8FFF;
    border-radius: 4px;
    padding: 0.5rem;
}