.DataVaultOverview{
  width: calc(100vw - 240px);
  padding-bottom: 40px;
}

.BottomSection{
  margin-top: 25px;

  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 30px;
}

.team-head {
  font-size: 20px;
}