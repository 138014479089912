.ManageInvestorModal .modal-content {
  width: 1000px;
  position: relative;
  right: 179px;
}

.ManageInvestorModal .modal-header {
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
}

.ManageInvestorModal .modal-header button {
  padding: 0px;
  border: none;
  background: transparent;
}

.modal-table table {
  width: 100%;
  border-spacing: 0px;
}

.modal-table {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  margin-bottom: 15px;
  // max-height: 363px;
  overflow: overlay;
  scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
  scrollbar-width: thin !important;
}

.modal-table::-webkit-scrollbar {
  width: 10px !important;
  scroll-padding-right: 100px !important;
}

.modal-table::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 10px !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  margin-top: 0px;
  margin-bottom: 2px;
}

.modal-table::-webkit-scrollbar-thumb {
  background: #e6e6e6 !important;
  border-radius: 0px !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid white !important;
}

.modal-table thead {
  position: sticky;
  top: 0;
  height: 40px;
  background: #fff;
  outline: 0.5px solid #979797;
}

.modal-table thead tr th {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.58;
}

.modal-table thead tr th:after {
  bottom: -1px;
  border-bottom: none;
}

.modal-table thead tr {
  border-bottom: 0.5px solid #979797;
}

.modal-table tr th,
.modal-table tr td {
  padding: 10px;
}

.modal-table tbody {
  overflow-y: auto;
  max-height: calc(100% - 40px);
}

.modal-table tbody tr {
  font-size: 10px;
  border-top: 0.5px solid #242424;
}

.Deal-Name-Size {
  border-radius: 12px;
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 15px;
}

.DN {
  color: #909090;
}

.ManageInvestorModal .totalOffer {
  color: #909090;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.totalOffer span {
  color: #73c96e;
  font-weight: 600;
}

.ManageInvestorModal .Match-bg-green {
  color: #4bb845;
  width: 35px;
  float: left;
}

.ManageInvestorModal .Match-bg-orange {
  color: #e18500;
  width: 35px;
  float: left;
}

.ManageInvestorModal .Match-bg-red {
  color: #980d0d;
  width: 35px;
  float: left;
}

.ManageInvestorModal table input {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #989898;
  padding: 5px 7px;
  font-size: 10px;
  width: 70px;
}

.switch {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 70px;
  background-color: #f9f9f9;
  color: #989898;
  height: 25px;
  display: flex;
  padding: 2px;

  .notInterested {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
  }

  .red {
    color: #ffffff;
    background-color: #f18c8a;
    border-radius: 2px;
  }

  .interested {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
  }

  .green {
    color: #ffffff;
    background-color: #27ba27;
    border-radius: 2px;
  }
}

.ManageInvestorModal table input:valid {
  border: 0.5px solid #4c8dff;
  color: #000000;
  background-color: #ffffff;
}

.ManageInvestorModal .Match-tooltip {
  width: 282px;
  padding: 0px;
  opacity: unset;
  overflow-y: auto;
  pointer-events: auto;
  max-height: 60%;
}

.Match-tooltip::before {
  border-bottom: 8px solid grey !important;
}

.ConnectPGModal {
  .modal-content {
    width: 538px;
    margin: auto;
    padding: 15px;
  }

  input {
    height: 44px;
    width: 100%;
    border: 0.5px solid #f9f9f9;
    border-radius: 6px;
    background-color: #f9f9f9;
    outline: none;
    color: #c3c3c3;
    font-size: 12px;
    padding: 12px;
    font-weight: 300;

    &:valid,
    &:focus {
      color: #000000;
      border: 0.5px solid #4c8dff;
      background-color: #ffffff;
    }
  }

  .modal-footer {
    button {
      height: 44px;
      border: none;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      border-radius: 6px;
      background-color: #4c8dff;
    }
  }
}

.SetupFrequencyModalWidth .modal-content {
  width: 538px;
  padding: 10px;
}

.sanctionNotApprovedModal {
  margin-top: 50px;

  @mixin theme($color, $backgroundColor, $borderColor) {
    background-color: $backgroundColor;
    color: $color;
    border: 1px solid $borderColor;
  }

  .closeModal {
    background: none;
    border: none;
    position: relative;
    left: 225px;
  }

  .warningImg {
    position: relative;
    top: -19px;
  }

  button {
    padding: 5px 77px;
    border-radius: 8px;
  }

  .continueLine {
    margin: 15px 0px 30px 0px;
  }

  .cancelBtn {
    @include theme(#ffffff, #007bff, #007bff);
  }

  .continueBtn {
    margin-left: 31px;
    @include theme(rgb(229, 0, 0), #ffffff, rgb(229, 0, 0));
  }

  .notSignedLine {
    color: rgb(229, 0, 0);
    font-weight: bold;
  }
}

.cancelNachModal {
  .closeModal {
    background: none;
    border: none;
  }
  // textarea {
  //   width: 460px;
  //   height: 61px;
  // }
  .b-row {
    margin-top: 20px;
    button {
      padding: 10px 65px 10px 65px;
      color: #ffffff;
    }
    .KeepNach {
      background-color: #00BD00;
    }
    .CancelNach {
      background-color: #DF666A;
    }
  }
  
}

.saveRow {
  display: flex;
  justify-content: space-between;
}

// Document Vault Modal css starts
.CompanyDocumentVaultModal .modal-content {
  width: 75vw;
  right: 50%;

  .PDFOuterSection {
    background: #D8D8D8;
    border-radius: 5px;
    padding: 25px;

    .PDFInnerSection {
      background-color: white;
      height: 100%;
    }
  }

  .ModalBody {
    .title {
      font-family: Gilroy-SemiBold;
      font-size: 16px;
    }

    .DocSection {
      background: #F7F7F7;
      border: 1px solid #EBEBEB;
      border-radius: 6px;
      padding: 5px;

      .text-truncate {
        max-width: 70%;
      }

      .upload {
        font-family: Gilroy-SemiBold;
        font-size: 10px;
        color: #4C8DFF;
      }

      .delete {
        font-family: Gilroy-SemiBold;
        font-size: 10px;
        color: #ED6E72
      }
    }

    .CheckList {
      border: 1px solid #D2D2D2;
      border-radius: 4px;
      padding: 10px;

      label {
        margin-bottom: 0;
        font-family: Gilroy-Medium;
        font-size: 10px;
      }
    }

    textarea {
      background: #FFFFFF;
      border: 0.5px solid #BEBEBE;
      border-radius: 3px;
      min-height: 94px;
      max-height: 94px;
      overflow-y: auto;
      width: calc(100% - 2rem);
      padding: 10px;
      font-family: Gilroy-Regular;
      font-size: 10px;
    }

    .shareCurrent {
      font-family: Gilroy-Medium;
      font-size: 10px;
      margin-top: -10px;
    }

    .ModalBtn {
      margin-top: 14em;
      button {
        width: 45%;
        border-radius: 4px;
        border: none;
        padding: 5px 0;
      }

      .NeedUpdate {
        border: 1px solid #4C8DFF;
        background: #FFFFFF;
        color: #4C8DFF;
      }

      .Verified {
        border: 1px solid #00AA00;
        background: #00AA00;
        color: #FFFFFF;
      }

      .VerifiedDisable {
        border: 1px solid #C8C8C8;
        background: #C8C8C8;
        color: #FFFFFF;
      }
    }
  }
}
#pdf-download {
  display: none;
}

.closeNeftModal {
  cursor: pointer;
}

.rejection-textarea {
  height: 100px;
  width: 100%; 
  max-width: 100%; 
  max-height: 100px; 
  overflow-y: auto; 
  resize: none; 
  text-overflow: ellipsis;
  
  .tooltip {
    max-width: 400px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
    z-index: 100;
  }
}

.neftModalButtons {
  text-align: center;
  margin-top: 20px;
  font-family: 'gilroy-medium', sans-serif;
  button {
    padding: 0.625rem 4rem;
    color: #ffffff;
    max-width: 200px;
  }
  .keepNeft {
    color: #ffffff;
    background-color: #8CDA8E;
  }
  .cancelNeft {
    color: #ffffff;
    background-color: #E05D62;
  }
}

// Document Vault Modal css ends