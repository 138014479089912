/* styles.css */
.customModal {
    border: none;
    height: 80%;
    z-index: 1000000;
}

.modalTitleAlert {
    color: #d95656;
    font-family: Gilroy;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.63px;
    letter-spacing: 0.000319488812237978px;
    text-align: left;
}

.generateBtn {
    cursor: pointer;
    width: 93px;
    height: 32px;
}

.generateBtnCancel {
    cursor: pointer;
    width: 93px;
    height: 32px;
}

.generateBtnAction {
    cursor: pointer;
    width: 93px;
    height: 32px;
    background-color: #2F8FFF;
    color: white;
}