.dropdown-menu {
    left: 0;
    position: absolute;
    top: 100%;
    display: grid;
    max-height: 300px;
    width: 100%;
    background-color: white;
    background-clip: padding-box;
    margin-top: 5px;
    border: 1px solid rgb(237, 237, 237);
    z-index: 1000;
    transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0px 0px 10px 0px #0000001a;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.12px;
    cursor: pointer;
    overflow-y: auto;
    // @include no-bg-scroll;

    .dropdown-item {
        display: grid;
        grid-template-columns: 3fr 1fr;
        align-items: center;
        padding: 10px;
        width: 100%;

        font-size: 12px;
  font-weight: 350;

        border-bottom: 1px solid #eeeeee;
        white-space: normal; /* Allows text to wrap */
        word-wrap: break-word; /* Breaks long words onto the next line */

        .Left {
        }

        .Right {
            color: #c1c1c1;
        }

        &:hover {
            background-color: #f8f9fa;
        }
    }

    .AddNew {
        padding: 8px;
        display: flex;
        align-items: center;
        font-weight: 425;
        color: black;
        cursor: pointer;

        &:hover {
            background-color: #f8f9fa;
        }

        img {
            width: 15px; // Set a fixed width for the image
            height: 15px; // Set a fixed height for the image
            object-fit: contain; // Ensure the image is scaled properly
            padding: 0px;
        }
    }
}

.no-results {
    padding: 10px 16px;
    color: #8d8d8d;
    text-align: left;
    font-size: 14px;
}