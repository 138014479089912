$font-family: 'Gilroy-Semibold', sans-serif;

.RecordWireTransferContainer {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: #fff;

  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    .companyInputContainer {
      display: flex;
      gap: 0.2rem;
      flex-direction: column;
    }

    .investorSelectContainer {
      display: flex;
      gap: 0.1rem;
      flex-direction: column;

      .investorSelect {
        width: 100%;
        padding: 0.625rem 1rem;
        font-size: 0.75rem;
        font-family: $font-family;
        border: 1px solid #ccc;
        border-radius: 4px;
        &:focus {
          border-color: rgba(76, 141, 255, 1);
        }
      }

      & > label  {
        margin: 0;
        font-size: 0.5rem;
        color: rgba(141, 141, 141, 1);
        font-family: $font-family;
      }

    }
  }

  .uploadScreenShot {
    margin-top: 1rem;

    & > label {
      font-size: 0.875rem;
      font-family: $font-family;
      margin-bottom: 1rem;
    }
  }
}