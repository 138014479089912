.Selected {
    border-radius: 3px;
    font-family: "Gilroy-Regular",sans-serif;
    font-size: 10px;
    padding: 8px 10px;
    width: 100%;
    margin-right: 0.5rem;
    display: flex;
    justify-content: space-between;
    border: 0.5px solid #4C8DFF;
    background-color: #FFFFFF;
    position: relative;
    cursor: default;
}
.SelectedWithoutType{
    border-radius: 3px;
    font-family: "Gilroy-Regular",sans-serif;
    font-size: 10px;
    padding: 8px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 0.5px solid #4C8DFF;
    background-color: #FFFFFF;
    position: relative;
    cursor: default;
}
.DisabledSelected {
    color: #808080;
    background: #F9F9F9;
    border: 0.5px solid #E5E5E5;
}

.Input::placeholder {
    font-size: 7px;
}
#coborrowerName{
    width:100%;
    margin: 0;
}
#coborrowerDropdown{
    width: 50%;
    margin-right:0.5rem;
}

.removeActive,.removeInActive{
    padding-left: 0.5rem;
}
.removeActive:hover{
    cursor: pointer;
}
.removeInActive:hover{
    cursor: default;
}
input::-webkit-calendar-picker-indicator {
    opacity: 100;
}

.Options {
    font-size: 0.6rem;
    font-weight: 100;
    width: 120px;
    position: absolute;
    margin-top: 30px;
    margin-left: 80px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0640844);
    border-radius: 6px;
    z-index: 1;

    span {
        margin: 2px 0;
        padding:5px 2px;
        border-radius: 2px;
    }
    span:hover{
        background-color: #E9E9E9;
    }
}
.OptionsHide{
    display: none;
}
.ApplicableCompanyText{
    width: 100%;
    text-align: right;
    font-family: 'Gilroy',sans-serif;
    font-style: normal;
    cursor: default;
    font-size: 10px;
    font-weight: 500;
    line-height: 9.42px;
    letter-spacing: 0.00019968050764873624px;
    text-align: left;
    padding-top: 10px;

}
.containerBox{
    display: flex;
    flex-direction: row;
    
}