@mixin DownloadIcon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #c9c9c9;
    margin-left: 15px;
}
.DownloadIconActive {
    @include DownloadIcon;
    margin-bottom: -4px;
    &:hover {
        cursor: pointer;
        background-color: #2f8fff;
    }
}
.DownloadIconNotActive {
    @include DownloadIcon;
    margin-bottom: -4px;
    &:hover {
        background-color: #c9c9c9;
    }
}

.Progressbar {
    display: flex;
    justify-content: space-between;
    counter-reset: step;
    font-size: 12px;
    margin: auto;
    padding-left: 50px;
    flex-direction: column;
}
.LineBox {
    display: flex;
    margin-top: -10px;
    &:last-child {
        display: none;
    }
}
.LineSubBox {
    margin-left: 3px;
    display: flex;
    align-items: center;
    &:nth-child(1) {
        margin-top: 20px;
    }
}
.SmallDone {
    margin-right: 10px;
}
.SmallNotDone {
    margin-right: 10px;
}
.SubList {
    margin-left: 35px;
    margin-top: 10px;
}
