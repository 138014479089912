.LimitRoadmap {
  margin-left: 30px;
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(112, 144, 176, 0.16);
  border-radius: 8px;
  padding: 19px;
  width: 98%;
}

.LRContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.LRLeft {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.greysub{
  color: #B3B3B3;
  font-weight: 200;
  font-size: 9px;
}

// .LRRight {
//   display: flex;
//   align-items: center;
// }

.Addmore {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #4C8DFF;
  margin-left: 60px;
  margin-top: 10px;
  cursor: pointer;
  width: fit-content;
  float: right;
  margin-right: 20px;
  
}

.Tranch {
  margin-top: 5px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
}


.LRInput {
  border: 0.5px solid #BEBEBE;
  background: #FFFFFF;
  border-radius: 3px;
  font-family: "Gilroy-Regular";
  font-size: 10px;
  padding: 8px 10px;
  width: 100px;
  margin-left: 20px;
}
.LRInputArr{
  border: 0.5px solid #BEBEBE;
  background: #FFFFFF;
  border-radius: 3px;
  font-family: "Gilroy-Regular";
  font-size: 10px;
  padding: 8px 10px;
  width: 50px;
  margin-left: 20px;
}
.Comments {
  width: 200px;
}

.LRRemove {
  cursor: pointer;
  margin-left: 10px;
}