@mixin RemoveBtnStyle{
  background-color: #ffffff;
  border: none;
  color: #ffffff;
}

.dealsHead {
  margin-left: 34px;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 28px;
  margin-top: 27px;
}

.hr {
  // border: 0.2px solid #dcdcdc;
  width: 100%;
  margin-bottom: 40px;
  background-color: #dcdcdc;
}

.TableCard {
  margin: 0px 35px 0px 35px;
  border-radius: 6px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
  display: block;
  position: relative;
  overflow: overlay;
  scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
  scrollbar-width: thin !important;
  height: calc(100vh - 150px);

  &::-webkit-scrollbar {
    width: 19px !important;
    scroll-padding-right: 100px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px !important;
    border-left: 0px solid transparent !important;
    border-right: 0px solid transparent !important;
    margin: 48px 0 5px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e6e6 !important;
    border-radius: 10px !important;
    border-top: 6px solid white !important;
    border-bottom: 6px solid white !important;
    border-left: 6px solid white !important;
    border-right: 6px solid white !important;
  }

  table {
    width: 100%;

    thead {
      background-color: #f9fafc;
      border-bottom: 1px solid #e9e9e9;
      font-family: Gilroy-Bold;

      th {
        position: sticky !important;
        z-index: 1;
        top: 0;
        padding: 12px 0;
        background-color: #f9fafc;
        vertical-align: middle;
        color: #535b62;
        font-size: 14px;
        font-weight: 500;

        &:first-child {
          padding-left: 10px;
        }
      }
    }

    tbody {
      font-family: Gilroy-Medium;

      tr {
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        border-bottom: 1px solid #e8e3e3;
        background-color: white;
        height: 73px;

        td {
          vertical-align: middle;

          &:first-child {
            padding-left: 10px;
            // padding-right: 70px;
          }
        }
      }
    }
  }
}

.dealsSection {
  position: relative;
  width: 100%;
}

.headerBtn {
  cursor: pointer;
}

.action_deal,
.action_send_kyc,
.action_drawdown {
  height: 24px;
  width: 119px;
  border-radius: 4px;
  background-color: #7513a5;
  border: none;
  color: #ffffff;
  font-weight: 300;
  font-size: 10px;
}

.action_drawdown {
  @include RemoveBtnStyle
}

.action_reminder,
.action_resend_kyc {
  box-sizing: border-box;
  height: 24px;
  width: 119px;
  border: 1px solid #7513a5;
  border-radius: 4px;
  color: #7513a5;
  font-weight: 300;
  background-color: #ffffff;
  font-size: 10px;
}

.action_reminder {
  @include RemoveBtnStyle
}

.action_resend_kyc {
  @include RemoveBtnStyle
}

.action_completed{
  @include RemoveBtnStyle
}

.action_sanction {
  height: 24px;
  width: 119px;
  border-radius: 4px;
  background-color: #4c8dff;
  border: none;
  color: #ffffff;
  font-weight: 300;
  font-size: 10px;
}

.deal_input {
  border: none;
  width: 100px;
}

#row_fade_out {
  color: #c3c3c3;
}

.lastActivity {
  font-size: 9px;

  .timing {
    color: #a4a4a4;
  }
}



.tableBox {
  height: inherit;
  &:hover {
      background-color: #e9eaee;
  }
}

.addOn {
  font-size: 10px;
  padding: 1px 4px;
  background-color: rgba(252, 191, 7, 0.4);
  border-radius: 3px;
  margin-left: 5px;
  color: #A14F17;
}

.debtSyndication {
  font-size: 10px;
  padding: 1px 4px;
  background-color: rgba(96, 33, 179, 0.2);
  border-radius: 3px;
  margin-left: 5px;
  color: #6021B3;
}

.rrf {
  font-size: 10px;
  padding: 1px 4px;
  background-color: #E4EEFF;
  border-radius: 3px;
  margin-left: 5px;
  color: #2F8FFF;
}

.dealComments {
  textarea {
      background-color: #f6f6f6;
      border-radius: 3px;
      border-color: #D1D1D1;
  }
  padding-left: 0;
}

.commentsBox {
  font-family: gilroy-medium, sans-serif;
  background-color: #f6f6f6;
  border-radius: 6px;
  border-color: #f6f6f6;
  overflow: hidden;
  resize: vertical;
  width: 167px;
  height: 61px;
  padding: 10px;
  &:hover{
      overflow: scroll;
  }
  .commentRow {
    font-size: 8px;

    .comment {
      font-weight: 500;
      font-size: 10px;
      color: #000000;
      margin: 0;
      font-family: 'Gilroy';
    }
  }
}

.tabsWrap {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding-top: 10px;    
  padding-right: 10px;  
  padding-left: 0px;   
  padding-bottom: 0px;
}

.tabsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;    
  padding-right: 0px;  
  padding-left: 10px;   
  padding-bottom: 10px;
}
