.DealBox {
    font-family: 'Gilroy-SemiBold';
    font-size: 12px;
    width: 450px;
    height: 420px;
    background-color: #F9F9F9;
    padding: 10px;
    border-radius: 8px;
    .uwSignal {
        font-family: 'Gilroy-Medium';
        font-size: 9px;
        padding: 10px;
        background-color: rgba(76, 141, 255, 0.1);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        height: 40px;
    }
    .labelTypes {
        display: flex;
        justify-content: space-between;
        width: 50%;
    } 
    .labelTypes div {
        width : 38%;
    }
    .labelTypes div:first-child {
        width : 28%;
    }
    .boolTypeLabelWidth {
        display: flex;
        align-items: flex-end;
        gap: 23px;
        width: 50%;
    }
    .lineClass {
        width: 100%;
        height: 1px;
        background-color: #E5E5E5;
        margin: 5px;
    }
    .generalInput {
        border: 0.5px solid #C5C5C5;
        border-radius: 6px;
        width: 70px;
        margin-left: 5px;
        // background-color: #D1D1D1;
    }
    .checkBoxInput {
        border: 0.5px solid #C5C5C5;
        border-radius: 6px;
        background-color: #D1D1D1;
    }
    .nonRatedInput {
        display: flex;
        align-items: flex-end;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 10px;
        gap: 24px;
    }
    .width50 {
        width: 50%;
        display: flex;
    }
    .editBtn {
        display: flex;
        justify-content: flex-end;
    }
    // .width40 {
    //     width: 50%;
    //     display: flex;
    //     align-items: flex-end;
    // }
    .headingwrapper{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .dealevaluation{
            margin: 0;
            margin-right: 10px;
        }
        .capitalRequested{
            margin: 0;
            margin-right: 10px;
            color: #979797;
            font-family: Gilroy-Medium,Serif;
            span{
                color:#2F8FFF;
            }
        }
    }
}

.dealEvaluationtooltip{
    width: 18rem;
}