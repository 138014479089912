.banner {
    background: url(../assets/background.jpg);
    width: 100%;
    height: 100vh !important;
    background-size: cover !important;
    object-fit: cover !important;
}
.recur-logo {
    text-align: left;
    padding: 20px;
}
.recur-logo:hover{
  cursor: pointer;
}
.for-companies-with-revenue {
    height: 22px;
    /* width: 450px; */
    color: #83B9FF;
    font-family: Graphik;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 22px;
    text-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

  }
  .recur-revenue {
    position: relative;
    top: 145px;
    height: 200px;
    left: 13% !important;
  }
  .login-card{
    height:440px!important;
  }
  .access-to-your-annual {
    height: 112px;
    width: 530px;
    color: #FFFFFF;
    font-family: Graphik;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 56px;
    text-shadow: 0 2px 18px 0 rgba(0,0,0,0.5);
  }
  .inverted-comma{
      font-family: auto;
  }
  form h4 {
    color: #222E43;
  font-family: Graphik;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
  }
  .recur-allows-you {
    height: 58px;
    width: 520px;
    color: #FFFFFF;
    font-family: Graphik;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    padding-top:30px;
  }
  .recur-card{
    background: #FFFFFF;
    padding: 41px;
    width: 434px !important;
    height: 590px;
    flex: none;
    margin-left: 60px;
    border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 15px 4px rgba(0,0,0,0.23);
  margin-top: 60px;
  }
  .login img {
      position: absolute;
      padding-top: 4px;
  }
  .login p {
      padding-left: 30px;
      color: #348CFF;
      padding-top: 4px;
  }
  .form-control {
    height: calc(2.5em + .75rem + 2px);
  }
  .button {
      background: #4C8DFF;
  }
  .requesting-access {
    color: #FFFFFF;
  font-family: Graphik;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 14px;
  margin-top: 30px;
  width: 403px;
  position: relative;
  right: 37px;
}
  article p {
  opacity: 0.5;
  color: #348CFF;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.8px;
  line-height: 17px;
  }
  article img {
    padding-top: 30px;
  }
  .btn-block {
    display: block;
    width: 120px;
}
  .request-submitted {
    padding-top: 60px;
  }
  .request-submitted h3 {
  opacity: 0.29;
  color: #000000;
  font-family: Graphik;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
  }
  .request-submitted p {
  opacity: 0.4;
  color: #000000;
  font-family: Graphik;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding-top: 9px;
  }
  .form-group {
    padding-top: 13px;
  }
  .home {
    color: #000000;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
    top: 42px;
    cursor:pointer;
  }
  .recur-card h4 {
    font-family: Graphik;
  }
  article hr {
    border: 1px solid #979797;
    opacity: 0.26;
    margin-top: 60px;
  }
  .remove-decor:hover{
    text-decoration: none !important;
  }
  .remove-decor{
    font-weight: 300;
  }
  .form-icons{
    padding-top: 11px;
    margin-right: 8px;
  }

  /* Media queries */
  @media only screen and (max-width: 550px) {
    .recur-revenue{
      left:10%;
      top:35px;

    }
    .banner{
      width: calc(100%);
      overflow-x: hidden;
    }
    .access-to-your-annual{
      font-size: 24px;
      width: 287px;
      line-height: 35px;
      height:70px;
    }
    .recur-allows-you{
      width: 340px;
      padding-top:5px;
      font-size: 14px;
    }
    .recur-card{
      transform: scale(.7);
    transform-origin: -15% -9% 0px;
    margin-left: 55px;
      /* margin-top:110px; */
    }
    .btn-block{
      width:155px;
    }
    .for-companies-with-revenue{
      font-size: 12px;
    }
  }
