.need-info-table-wrapper{
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 0px;
    overflow: auto;
    overflow-y: scroll;
    max-height: 350px;
}
.need-info-table{
    margin-bottom: 0 !important;
    tr{
        cursor: pointer;
    }
    td{
        margin:auto;
        vertical-align: middle;
    }
}
.data-submitted-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
    .needinfo-tooltip{
        width: 310px;
        padding: 12px;
        opacity: unset;
        overflow-y: auto;
        pointer-events: auto;
        max-height: 60%;
        p{
            word-break: break-all;
            margin: 0;
        }
    }
}
.needinfoWrapper{
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .needInfoHeadingWrapper{
        display: flex;
        align-items: center;
        .needInfoheading{
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            color: #000000;
            line-height: 20px;
        }
        .warningText{
            margin: 0 0 0 10px;
            color: #DF666A;
            font-size: 12px;
            margin-top: 2px;
        }

    }

    .needInfoCTAWrapper{
        display:flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 30px;
        
        .toogleWrapper{
            display:flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: -0.5rem;
            p{
                margin: 0 0 0.6rem 0;
                font-weight: 600;
                font-size: 12px;
            }
        }
    }
}

