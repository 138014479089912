.rejection-textarea{
  width: 100%;
  padding: 0.7rem;
  height: 10rem;
  border-radius: 0.375rem;
  border: 0.5px solid #FFF;
  background: #F9F9F9;
  color: #979797;
  font-family: Gilroy-Medium,serif;
  font-size: 0.75rem;
}
.approval-input{
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.375rem;
  border: 0.5px solid #FFF;
  background: #F9F9F9;
  color: #979797;
  font-family: Gilroy-Medium,serif;
  font-size: 0.75rem;
}
.RejectionCancelBtn{
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: 1px solid #000;
  background: #FFF;
  color: #000;
  font-family: Gilroy-medium,serif;
  font-size: 0.75rem;
}
.RejectionBtn{
  padding: 0.5rem 1rem;
  border: 1px solid #DF666A;
  border-radius: 0.375rem;
  background: #DF666A;
  color: white;
  font-family: Gilroy-medium,serif;
  font-size: 0.75rem;
}
.ApproveBtn{
  padding: 0.5rem 1rem;
  border: 1px solid #0A0;
  border-radius: 0.375rem;
  background: #0A0;
  color: white;
  font-family: Gilroy-medium,serif;
  font-size: 0.75rem;
}

.ApproveAllBtn{
  padding: 0.5rem 1rem;
  border: 1px solid #2f8fff;
  border-radius: 0.375rem;
  background: #2f8fff;
  color: white;
  font-family: Gilroy-medium,serif;
  font-size: 0.75rem;
  width: 15%;
}

.RejectionAllBtn{
  padding: 0.5rem 1rem;
  border: 1px solid #2f8fff;
  border-radius: 0.375rem;
  background: white;
  color: #2f8fff;
  font-family: Gilroy-medium,serif;
  font-size: 0.75rem;
  width: 15%;
}