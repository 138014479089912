.alertBox{
    border: 1px solid #D31515;
    padding: 0.5rem;
    border-radius: 5px;
    height : 190px;
    overflow : scroll;
    width : 600px
}

.dateText{
    color:#8D8D8D;
    font-size: 8px;
    line-height: 9.5px;
    font-family:'Gilroy-Bold';
}

.messageText{
    color: #000000;
    font-size: 10px;
    line-height: 12.5px;
    padding-bottom: 0.5rem;
    font-family: 'Gilroy';
}

.alertText{
    color: #D31515;
    font-family: 'Gilroy';
}