.pickDate {
  display: flex;
  width: 98%;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;

  label {
    color: #8d8d8d;
    font-family: 'Gilroy-Medium';
    font-size: 8px;
    position: relative;
    top: -1.3rem;
    left: 50%;
    margin-bottom: 0px !important;
  }
}

.TableLabel {
  font-family: gilroy-medium, sans-serif;
  font-size: 12px;
  margin-left: 3rem;
  margin-bottom: -2rem;
}

.basicInfoContainer {
  background-color: #f9f9f9;
  padding: 1.3rem;
  border-radius: 4px;
  font-size: 11px;
}
.StatusBannerCustomerSupport{
  font-family: Gilroy-Semibold, serif;
  display: flex;
  margin-right: 2rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
}
.tdsTableHeadWrapper{
  display: flex;
  justify-content: space-between;
  padding-right:2.5rem ;
}
.tdsTableHeadContainer{

  margin-left: 2.5rem;
  .TdsTableLabel{
    color: #8d8d8d;
    font-family: 'Gilroy-Medium',serif;
    font-size: 0.75rem;
    margin-bottom: 0.2rem;
  }
  .TdsbtnConatiner{
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 1.5rem 0 0.5rem 0;
    gap: 1rem;
    .ButtonContainer {
      position: relative;
      label {
        color: #8d8d8d;
        font-family: 'Gilroy-Medium', serif;
        font-size: 8px;
        position: absolute;
        bottom: 2rem;
        margin-bottom: 0 !important;
      }
    }
    button {
      padding: 0.3rem;
      font-size: 12px;
      min-width: 5.5rem;
      box-shadow: none;
      font-family: gilroy-semiBold,serif;
      border-radius: 4px;
      white-space: nowrap;
    }

    .Active {
      background-color: white;
      color: #2F8FFF;
      border: 0.5px solid #2F8FFF;
    }

    .Inactive {
      border: 0.5px solid #DBDBDB;
      background: #F3F3F3;
      color: black;
    }
  }
}
.refetchButton {
  margin-top:2rem;
  background: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #2f8fff;
  border-radius: 4px;
  font-family: Gilroy-Semibold, serif;
  border: 1px solid #2f8fff;
  padding: 0.5rem 0.5rem;
  font-size: 12px;
  height: 2rem;
  white-space: nowrap;
}
.VerifiedDropDown {
  margin-top:2rem;
  background: #2f8fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  border-radius: 4px;
  font-family: Gilroy-Semibold, serif;
  border: none;
  padding: 0.5rem 0.5rem;
  font-size: 12px;
  height: 2rem;
  white-space: nowrap;
}
.filterItem{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}
.redDot{
  display: inline-block;
  margin-right: 0.5rem;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.header_input{
  position: relative;
  input{
    background: inherit;
    background-image: url('../../../../assets/search.svg');
    background-repeat: no-repeat;
    background-position: center left;
    text-indent: 20px;
    border: none;
  }
}
.filter_arr_wrapper{
  margin: 30px 35px;
  font-family: Gilroy-SemiBold;
  span{
      border-radius: 4px;
      font-size: 12px;
      padding: 6px;
      background-color: #E8F0FF;
      display: inline-block;
      cursor: pointer;
      margin-left: 10px;

      &:first-child{
          margin-left: 0;
      }
  }
 .clear_all{
  font-size: 12px;
  color: #2F8FFF;
}
}