.view-docs-modals-container {
  font-family: Graphik;

  @mixin Status {
    min-width: 75px;
    border-radius: 4px;
    font-family: "Gilroy-Medium";
    font-size: 10px;
    text-align: center;
    padding: 2px 0;
    text-transform: uppercase;
  }

  .expiring,
  .signExpiring,
  .docsReady,
  .notSent {
    @include Status;
    color: #eb5e00;
    background-color: #fff5ee;
  }

  input {
    font-size: 8px;
  }

  .signing {
    @include Status;
    color: #4c8dff;
    background-color: #e4eeff;
  }

  .docsShared,
  .bidding,
  .sent {
    @include Status;
    color: #6021b3;
    background-color: #f2e8ff;
  }

  .expired,
  .signExpired,
  .passed {
    @include Status;
    color: #afafaf;
    background-color: #f5f5f5;
  }

  .approved,
  .sanctioned,
  .signed,
  .active {
    @include Status;
    color: #49a71b;
    background-color: #e5fcde;
  }

  .countersigned {
    @include Status;
    color: #49a71b;
    background-color: #e5fcde;
    padding: 5px;
  }

  .investor-intrested-wrapper {
    background-color: #ededed;
    border-radius: 2px;
    padding: 2.5px;
    cursor: pointer;
    width: 75px;

    span {
      width: 50%;
      display: inline-block;
      text-align: center;
      border-radius: 2px;
    }
  }

  .additional-doc-inputs-wrapper {
    display: flex;

    .additional-doc-input-description {
      width: 342px !important;
      border: none;
      background-color: #f9f9f9;
      padding-left: 7px;
      height: 24px;

      &focus {
        width: 342px !important;
      }
    }

    .add-Additional-docs-upload {
      display: flex;
      place-items: center;
      margin-left: 7px;
      width: 100px;
      height: 24px;
      border: 1px solid #ebebeb;
      border-radius: 4px;
      color: #4c8dff;
      font-family: Gilroy-SemiBold;
      font-size: 10px;
      cursor: pointer;
    }
  }

  .add-Additional-docs {
    display: grid;
    place-items: center;
    align-items: center;
    padding: 7.5px;
    width: 168px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    color: #4c8dff;
    font-family: Gilroy-SemiBold;
    font-size: 10px;
    cursor: pointer;
  }

  .resend-for-signing-btn {
    border-radius: 4px;
    background-color: transparent;
    color: #4c8dff;
    font-size: 10px;
    font-weight: 600;
    padding: 6px 13px !important;
    border: 1px solid #4c8dff;
  }

  .send-deal-toUgrow-btn {
    height: 33px;
    width: 100%;
    border-radius: 4px;
    color: #ffffff;
    font-family: Gilroy-SemiBold;
    border: none;
    background-color: #6b1d9f;
  }

  .send-deal-toUgrow-btn-disabled {
    border: none;
    height: 33px;
    width: 100%;
    border-radius: 4px;
    color: #ffffff;
    font-family: Gilroy-SemiBold;
    background-color: #dcdcdc;
  }

  .regenerateBtn {
    font-size: 8px;
    background-color: #ffffff;
    border: 0.5px solid #4c8dff;
    color: #4c8dff;
    margin-left: 6px;
    border-radius: 2px;
  }

  .cancelSignedBtn {
    font-size: 8px;
    background-color: #ffffff;
    border: 0.5px solid #D95656;
    color: #D95656;
    margin-left: 6px;
    border-radius: 2px;
  }

  .signSwitch {
    display: flex;
    align-items: center;
    background-color: #E9EAEB;
    margin-right: 16px;
    padding: 4px;
    border-radius: 4px;

    &.disabled {
      opacity: 0.8;
      cursor: progress;

      button {
        cursor: progress;
      }
    }

    button {
      border: none;
      background-color: transparent;
      font-size: 10px;
      border-radius: 2px;
      font-family: 'Gilroy-SemiBold';

      &.aadhaar {
        background-color: #ffffff;
      }

      &.dsc {
        background-color: #27BA27;
        color: #ffffff;
      }
    }
  }
}
.DocsCard{
  height: 260px;
  max-width: 93%; /* Adjust the percentage value as needed */
  margin: 0 auto;
  border-radius: 6px;
background: #FFF;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
overflow-y: scroll;
}

.UploadName{
  color: #ADADAD;
font-family: Gilroy;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 12px; /* 100% */
}
.RowTitle{
  color: #000;
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.SanctionLimit,.OptionSelect{
  width: 240px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #F9F9F9;
  border: 0.5px solid #F9F9F9;
  margin-right: 15px;
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; 
  padding: 0px 0px 0px 10px;
}
.OptionSelect{
  margin-right: 15px;
  appearance: none;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center; 
  justify-content: flex-start;
}

/* styles.css */

.dealTermsContainer {
  display: flex;
  padding: 0 24px;
  overflow: hiddens;
}

.bottomCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
  column-gap: 20px;
  padding: 0 24px;
  margin-top: 20px;
}

.mainCard,
.rightBox,
.leftBox {
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.mainCard{
  padding-bottom: 20px;
  width: 100vw;
}

.leftBox,
.rightBox {
  display: flex;
  align-items: center;
  padding: 30px 15px;
}

.leftBox .newDiv1,
.leftBox .newDiv2,
.rightBox .newDiv3,
.rightBox .newDiv4 {
  display: flex;
  align-items: center;
}

.newDiv1,
.newDiv3 {
  margin-left: 24px;
}

.newDiv2 {
  justify-content: flex-end;
  margin-right: 24px;
  width: 75px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #c7212c;
}

.dealTermsHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
}

.dealTermsHeaderButtons {
  display: flex;
  align-items: flex-end;
}

.lastEdited {
  color: #ADADAD;
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 12px;
  font-style: normal;

}

.blueButton {
  height: 28px;
  width: 115px;
  margin-left: 10px;
  padding: 4px 10px;
  display: flex;
  justify-content:center ;
  align-items: center;
  border-radius: 4px;
  background: #4C8DFF;
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 10px;
  color: white;
  border: none;
}


.cancelButton {
  height: 28px;
  width: 115px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
justify-content:center ;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #DF666A;
  background: #DF666A;
  color: white;
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 10px;
}

.topDiv5 {
  margin-top: 20px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  width: 115px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #4C8DFF;
}

.input-right {
  margin-left: 24px;
  width: 100%;
}

/* New styles for the input containers */
.input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* Add some spacing between each input container */
}

.label1,
.label2,
.label3,
.label4,
.labelIPA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 12px;
  font-style: normal;
  color: #000;
  margin-right: 10px;
  width: auto;
  /* Add some spacing between the label and input field */
}

.label3 {
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 12px;
  font-style: normal;
  color: #000;
}

.label2,
.label3,
.label4 {
  margin-top: 26px;
}

.inputData {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  width: 114px;
  height: 20px;
  border-radius: 3px;
  border: 0.5px solid #D6D6D6;
  color: #ADADAD;;
  background: #ffffff;
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 10px;
  font-style: normal;
  line-height: 12px;
}
.shareIPAWithCompany {
  margin-top: 20px;
  color: #ffff;
  font-family: Gilroy;
  font-size: 10px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 16px;
  background-color: #4C8DFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.inputDataActive {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  width: 114px;
  height: 20px;
  border-radius: 3px;
  border: 0.5px solid #2F8FFF;
  background: #ffffff;
  color: #000;
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 10px;
  font-style: normal;
  line-height: 12px;
}

.textArea {
  border-radius: 3px;
  color: #ADADAD;;
  border: 0.5px solid #D6D6D6;
  background: #ffffff;
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 10px;
  font-style: normal;
  line-height: 12px;
}

.textAreaActive {
  border-radius: 3px;
  border: 0.5px solid #2F8FFF;
  background: #ffffff;
  font-family: "Gilroy SemiBold", sans-serif;
  font-size: 10px;
  color: #000;
  font-style: normal;
  line-height: 12px;
}
.truncated-text {
text-overflow: ellipsis;
width: 200px;
padding-right: 10px;
}
.truncated-textIPA{
  text-overflow: ellipsis;
  width: 90px;
}

.UploadCard,.UploadCardIndicative{
  width: 250px;
  height: 44px;
  border-radius: 4px;
  border: 0.5px solid #D9D9D9;
  background: #FFF;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: flex-start;
  font-family: 'Gilroy-SemiBold';
  font-size: 10px;

  }

  .UploadCardIndicative{
    width: 114px;
    height: 20px;
  }

.UploadCard span{
  color: #4c8dff;
  /* Add styles for text truncation */
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}
.UploadCardIndicative span {
  color: #4c8dff;
  /* Add styles for text truncation */
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  width: 65px;
}

.UploadCard button {
  background: none;
  border: none;
  color: #4c8dff;
  cursor: pointer;
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.UploadCard:hover button {
  display: block;
}
.MarkSanctionButton,
.MarkSanctionButtonDisabled {
  display: flex;
  max-width: 93%;
  flex: 1;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.10);
}

.MarkSanctionStatusDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 2rem 3rem;
}

.MarkSanctionSelect {
  display: flex;
  flex: 1;
  width: 10rem;
}

.MarkSanctionButton {
  border: 1px solid #2F8FFF;
  background: #2F8FFF;
  cursor: pointer;
}

.MarkSanctionButtonDisabled {
  background: #F4F4F4;
  background: #989898;;
  cursor: not-allowed;
}
.MarkSanctionTextDisabled,
.MarkSanctionText{
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 114.286% */
}

.MarkSanctionTextDisabled{
  color: #F4F4F4;
}

.SanctionText{
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  font-family: "Gilroy-SemiBold";
  font-size: 1.5rem;
}

.SanctionCompleteClear{
  padding-top: 0.5rem
}

.SanctionBoxInnerText{
  font-size: 0.875rem;
  font-family: 'Gilroy-Medium';
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}

.ButtonsContainer{
  display: flex;
  padding-top: 2rem;
  padding-bottom: 1rem;
  justify-content: flex-end;
}

.SkipButton,.SendMailButton{
width: 100%;
height: 2rem;
margin-right: 1rem;
border-radius: 6px;
font-size: 0.625rem;
}

.SkipButton{
  border: 1px solid #2F8FFF;
  color: #2F8FFF;
  background: none;
}

.SendMailButton{
  border: 1px solid #2F8FFF;
  color: #FFFFFF;
  border-radius: 6px;
  background:#2F8FFF;
}

.PaddingTop24Left24{
  padding: 24px 0px 0px 24px;
}

.PaddingTop24Right24Left14{
  padding: 24px 24px 0px 14px;
}
.PaddingTop24Right24Left24{
  padding: 24px 24px 0px 24px;
}

.PaddingTop24Bottom24Left24{
  padding: 24px 0px 24px 24px;
}

.PaddingTop24Left14{
  padding: 24px 0px 0px 14px;
}

.PaddingUploadCard{
  padding : 24px 14px 0px 14px;
}
.PaddingTop10,.AddnUploadCard,.UploadedDocCard{
  padding: 10px 0px 0px 0px;
}
.AddnUploadCard,.UploadedDocCard{
  display: flex;
  align-items: center;
}

.UploadedDocCard{
  justify-self: flex-start;
}

.ConfirmModal{
  top:25%;
  z-index: 9999;
  margin-bottom: 100px;
  overflow-y: hidden;
}

.clearIcon{
  margin-right: 0.5rem;
  width: 14px;
  height: 14px;
}

.ButtonsRow{
  display: flex;
  justify-content: space-between;
}

.PaddingRight16{
  padding-right: 1rem;
}

.CloudUpload{
    width: 20px;
    margin-right:0px;
}

.UploadCardIcons{
  margin-right: 0.5rem;
  width: 10px;
}

.PaddingLeft10{
  padding-left:10px;
}

.PaddingTop11Left14{
  padding:11px 0px 0px 14px;
}

.PaddingTop24Right26{
  padding: 24px 26px 0px 0px;
}

.SanctionLimitText{
  font-size: 10px;
  font-family: 'Gilroy-SemiBold';
}

.PaddingTop24Right24{
  padding: 24px 24px 0px 0px;
}

.FS12{
  font-size: 12px;
}

.PaddingTop24{
  padding-top: 24px;
}

.DFlex{
display: flex;
}

.PaddingLeft14{
  padding-left: 14px;
}

.permissionBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-left: 7px;
  margin-right: 2px;
  width: 100%;
  cursor: pointer;
}

.permissionBox > *:not(:last-child) {
  margin-right: 2px;
}


.customDropdownText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 150%;
  max-height: 148px;
  overflow-y: auto;
  background-color: #fff;
  z-index: 1000;
}

.optionsBox {
  display: flex;
  align-items: center;
  padding: 0 5px 0 5px;
  border: 0.5px solid #2F8FFF;
}

.checkboxLabel {
  margin-left: 5px;
  padding-top: 5px;
}