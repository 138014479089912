@mixin padding-left-15 {
    padding-left: 15px;
}

@mixin padding-left-30 {
    padding-left: 30px;
}

.TableCard,
.TableCardHover {
    margin: 0px 35px 0px 35px;
    border-radius: 6px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
    display: block;
    position: relative;
    overflow: overlay;
    scrollbar-color: rgb(168, 168, 168) #f1f1f1;
    scrollbar-width: thin;
    height: calc(100vh - 170px);
    z-index: 1;
    // max-height: 100vh;
    // min-height: 100vh;

    &::-webkit-scrollbar {
        width: 19px;
        scroll-padding-right: 100px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        margin: 48px 0 5px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e6e6e6;
        border-radius: 10px;
        border-top: 6px solid white;
        border-bottom: 6px solid white;
        border-left: 6px solid white;
        border-right: 6px solid white;
    }

    table {
        width: 100%;

        thead {
            font-family: 'Gilroy-Bold';
            background-color: #f9fafc;
            height: 47px;
            border-bottom: 1px solid #e9e9e9;

            th {
                position: sticky;
                top: 0;
                background-color: #f9fafc;
                vertical-align: middle;
                color: #535b62;
                font-size: 14px;
                z-index: 4;
            }
        }

        tbody {
            font-weight: 500;

            tr {
                color: #000000;
                font-size: 10px;
                border-bottom: 1px solid #e8e3e3;
                background-color: white;
                height: 73px;

                td {
                    vertical-align: middle;
                }
            }
        }
    }
}

.TableCardHover table tbody tr {
    &:hover {
        background-color: #f9fafc;
    }
}

.PaddingL_15 {
    @include padding-left-15;
}

.PaddingL_30 {
    @include padding-left-30;
}

tr {
    td:nth-child(1) {
        padding-left: 10px;
    }
}