.ImgStyle {
    width: 29px;
    height: 40px;
    margin-top: -30px;
    margin-bottom: 15px;
}
.ButtonContainer {
    margin-top: 34px;
    .Cancel {
        width: 45%;
        height: 40px;
        border-color: #D95656;
        color: #D95656;
        background-color: white;
        font-size: 15px;
    }
    .Delete {
        width: 45%;
        height: 40px;
        background-color: red;
        font-size: 15px;
    }
}
.BreakWord {
    word-break: break-word;
}