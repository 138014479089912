.addOn {
    font-size: 10px;
    padding: 1px 4px;
    background-color: rgba(252, 191, 7, 0.4);
    border-radius: 3px;
    margin-left: 3px;
    color: #A14F17;
}

.debtSyndication {
    font-size: 10px;
    padding: 1px 4px;
    background-color: rgba(96, 33, 179, 0.2);
    border-radius: 3px;
    margin-left: 3px;
    color: #6021B3;
}

.rrf {
    font-size: 10px;
    padding: 1px 4px;
    background-color: #E4EEFF;
    border-radius: 3px;
    margin-left: 3px;
    color: #2F8FFF;
}

.s_moderation {
    width: 68px;
    height: 25px;
    background-color: #2F8FFF;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    padding: 5px 10px;
}

.lastModerated {
    display: flex;
    div {
        color: #939393;
        font-family: 'Gilroy-Medium';
        font-size: 11px;
        padding-top: 1.4px;
        padding-left: 3px;
    }
}

.UWStatus {
    border-radius: 4px;
    text-align: center;
    padding: 4px 0px;
}
.uws_notstarted {
    background-color: #E4EEFF;
    color: #2F8FFF;
}     
.uws_approved {
    background-color: #E5FCDE;
    color: #49A71B;
}  
.uws_needinfo {
    background-color: #FFEBDD;
    color: #E3752B;
}       
.uws_ongoing {
    background-color: #FFF2CB;
    color: #BAA913;
} 
.uws_waitlisted, .uws_notapproved {
    background-color: #F3F3F3;
    color: #939393
}
        
.riskFlag {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
}

.uwFlagStyles {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
}

.cta {
    cursor: pointer;
    &:hover {
        background-color:#F9FAFC;
    }
}

.tableBox {
    &:hover {
        background-color: #e9eaee;
    }
}

.red {
  background-color: #F40000;
}
.blue {
  background-color: #2F8FFF;
}
.green {
  background-color: #4CB71A;
}
.yellow {
  background-color: #FCBF07;
}
.violet {
    background-color: #8F00FF;
}
.grey {
    background-color: #5A5A5A;
}

.wrapperStyles{
    div{
        background-color: white;
    }
}

.filter_arr_wrapper{
    margin: 30px 25px;
    font-family: Gilroy-SemiBold;
    span{
        border-radius: 4px;
        font-size: 12px;
        padding: 4px;
        background-color: #E8F0FF;
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;

        &:first-child{
            margin-left: 0;
        }
    }
   .clear_all{
    font-size: 12px;
    color: #2F8FFF;
  }
}
.input_company_status {
    input {
        width: 70px;
    }
 }
 .input_underwriter, .input_uw_status {
    input {
        width: 100px;
    }
 }

.header_input{
    position: relative;
    input{
        background: inherit;
        background-image: url('../../../../../assets/search.svg');
        background-repeat: no-repeat;
        background-position: center left;
        text-indent: 20px;
        border: none;
    }
}

.showRelevant {
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 12px;
    margin-right: 20px;
    margin-top: 7px;
}

.toogleBtn{
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 12px;
    margin-right: 20px;
    margin-top: 6px;
}

.row_alignment {
    &:first-child {
        width: 18.5%;
        padding-left: 0.5rem;
    }
    &:nth-child(2){
        width: 8%;
        padding-left: 0.75rem;
    }
      &:nth-child(3){
        width: 8%;
        padding-left: 0.75rem;
    }
      &:nth-child(4) {
        width: 9%;
    }
    &:nth-child(5) {
        width: 11%;
    }
    &:nth-child(6) {
        width: 11%;
    }
    &:nth-child(7) {
        width: 10%;
    }
    &:nth-child(8) {
        width: 8%;
    }
    &:nth-child(9) {
        padding-left: 0.75rem;
    }
    &:last-child {
        width: 2%;
    }   
}
.uw_table {
    .uw_table_header {
        table {
            thead {
                th {
                   @extend .row_alignment;
                   padding-left: 0;
                   padding-right: 0;           
                }
            }
        }
    }
    .uw_table_body {
        table {
            tbody {
                td {
                    /* padding-left: 0px;
                    padding-right: 0px; */
                    @extend .row_alignment;
                    //text-align: left;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    font-family: 'Gilroy', sans-serif;
    input::placeholder {
        color: #757575;
        font-family: 'Gilroy-Medium';
    }
    table {
        width: 100%;
        thead {
            font-family: 'Gilroy-Medium';
            background-color: #F9FAFC;
            height: 47px;
            // border-bottom: 1px solid #e9e9e9;
            th {
                position: sticky;
                top: 0;
                background-color: #f9fafc;
                vertical-align: middle;
                color: #757575;
                font-size: 14px;
                z-index: 4;
                font-weight: 100;
            }
        }

     }
}
.commentsBox {
    font-family: gilroy-medium, sans-serif;
    background-color: #f6f6f6;
    border-radius: 6px;
    border-color: #f6f6f6;
    overflow: hidden;
    resize: vertical;
    width: 139px;
    height: 61px;
    padding: 10px;
    .commentRow {
        color: #C5C5C5;
        font-size: 8px;
    }
    &:hover{
        overflow: scroll;
    }
}

.aggregateNumbers {
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 10px;
    color: #9A9A9A;
    width: 800px;
    padding: 0px 15px;
    .not_shared {
        color: #2F8FFF;
    }
    .partially_shared {
        color: #2F8FFF;
    }
    .shared {
        color: #00B2FF;
    }
    .query_pending {
        color: #E3752B;
    }
    .approved {
        color: #08B0B3;
    }
    .completed {
        color: #49A71B;
    }
    .expired {
        color: #939393;
    }
    .hideExpiredToggleBtn {
        margin-top: 5px;
        font-size: 12px;
    }
}

.aggregateNumbersV2 {
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 10px;
    color: #9A9A9A;
    width: 350px;
    padding: 0px 15px;
    justify-content: space-between;
    // flex-wrap: wrap;
    .hideExpiredToggleBtn {
        margin-top: 5px;
        font-size: 12px;
    }
}

.companiesAggregrate__wrapper{
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 12px;
    color: #9A9A9A;
    margin: 0 1rem;
    >div{
        margin-right: 1.5rem;
    }
    .not_started {
        color: #2F8FFF;
    }
    .on_going{
        color: #08B0B3;
    }
    .need_info {
        color: #E3752B;
    }
    .waitlisted {
        color: #939393;
    }
    .expired {
        color: #DF666A;
    }
}
