.wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 5px 5px;
    // max-width: 100px;
    p{
        margin: 0;
        text-transform: capitalize;
    }
}
.wrapper.received{
    color: #4C8DFF;
    background-color: #E5EFFF;
}
.wrapper.requested{
    color: #979797;
    background-color: #F7F7F7;
}
.wrapper.approved{
    color:  #49A71B;
    background-color: #E5FCDE;
}
.wrapper.incomplete{
    color: #DF666A;
    background-color: #FCDEDE;
}
.wrapper.open{
    color: #4C8DFF;
    background-color: #E5EFFF;
}