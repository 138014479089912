.Scheduled {
    padding: 10px;
    background-color: #F9F9F9;
    border-radius: 6px;
    width: 80%;

    table {
        width: 100%;
    }

    th {
        color: #898989;
        font-size: 8px;
        text-transform: uppercase;
        font-weight: 300;
    }

    td {
        color: #000000;
        font-size: 10px;
        font-weight: 300;
    }
}