$drop-down-width: 130px;

@mixin Circle {
    width: 10px;
    height: 10px;
    border-radius: 9px;
    margin-right: 8px;
}
.delayChargeText {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 130px;
    font-size: 11px;
    color:red;
    margin-top: 3px;
    font-family: Gilroy;
}

.ProgressBottom {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 260px;
    font-size: 12px;
    color: #D5D4D5;
    margin-top: 3px;
    font-family: Gilroy-Bold;
}

.ProgressTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    font-size: 12px;
    margin-bottom: 3px;
    font-family: Gilroy-Bold;

    .greenText {
        color: #03AA00;
    }

    .redText {
        color: #D80100;
    }
}

.CollectionProgressBar {
    height: 10px;
    width: 260px;
    background-color: #D80100;
    border-radius: 40px;

    .GreyPart {
        background-color: #EEEEED;
        width: 180px;
        height: 10px;
        border-radius: 40px;

        .GreenPart {
            background-color: #03AA00;
            width: 100px;
            height: 10px;
            border-radius: 40px;
        }
    }
}

.CollectionComments {
    textarea {
        background-color: #f6f6f6;
        border-radius: 6px;
        border-color: #D1D1D1;
    }
}

.CollectionDropdown {
    position: relative;

    .OptionSelected {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F6F6F6;
        border-radius: 4px;
        width: $drop-down-width;
        padding: 5px;
    }

    .Options {
        width: $drop-down-width;
        position: absolute;
        padding: 5px;
        margin-top: 5px;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0640844);
        border-radius: 6px;
        z-index: 1;

        span {
            margin: 7px 0;
        }
        div  {
            margin-top: 2px;
        }
    }

    .GreenCircle {
        @include Circle;
        background: #00AA00;
    }

    .RedCircle {
        @include Circle;
        background: #D80000;
    }

    .YellowCircle {
        @include Circle;
        background: #FCBF07;
    }
}

.CollectionDropdownNach {
    position: relative;

    .OptionSelectedNach {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F6F6F6;
        border-radius: 4px;
        width: 100px;
        padding: 5px;
    }

    .OptionsNach {
        width: 100px;
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin-top: 5px;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0640844);
        border-radius: 6px;
        z-index: 1;

        span {
            margin: 7px 0;
        }
    }
}

.commentsBox {
    font-family: gilroy-medium, sans-serif;
    background-color: #f6f6f6;
    border-radius: 6px;
    border-color: #f6f6f6;
    overflow: hidden;
    resize: vertical;
    width: 139px;
    height: 61px;
    padding: 10px;

    &:hover{
        overflow: scroll;
    }
}

.UseNach {
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 21px;
    width: 72px;
    padding: 3px;
    border-radius: 4px;
    background-color: #E9EAEB;
    cursor: pointer;

    span {
        text-align: center;
        width: 33px;
        border-radius: 2px;
        color: #4F4F4F;
        font-family: 'Gilroy-SemiBold';
        font-size: 10px;
    }

    .NoActive {
        background-color: #D80000;
        color: #ffffff;
    }
    
    .YesActive {
        background-color: white;
        color: #000000
    }
}