.needInfoDrawer{
    position: relative;
    height: 100%;
    .needInfoHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        .closeDrawer{
            position: absolute;
            right: 5%;
            top: 0%;
            cursor: pointer;
        }
        .headingText{
            font-weight: 600;
            font-size: 14px;
        }
    }
    .needInfofooter{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .needInfoMainContainer{
        padding: 20px;
        height: calc(100% - 20%);
        overflow-y: scroll;
    }
}
.horizontalLine{
    border-color: #D7D7D7;
}