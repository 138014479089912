.SelectBox {
    border: 0.5px solid #D2D2D2;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 40px;
    width: 100%;
    margin-top: 2px;
    font-weight: 500 !important;
    font-size: 10px;
    padding: 10px;
    color: #ADADAD;
    &:disabled{
        background-color: #F1F1F1;
        border: none;
    }
    &:focus, &:valid{
        border: 0.5px solid #4C8DFF;
        color: #000000;
    }
}