@mixin status($right) {
    font-weight: 500;
    font-size: 10px;
    width: max-content;
    margin-top: -14px;
    position: absolute;
    right: $right;
}

.Right_0 {
    @include status(0px);
}

.Right_unset {
    @include status(unset);
}