.Container {
  margin: 1.5rem 0 2rem 0;
}
.RequestChangeContainer {
  font-family: Gilroy-semibold, san-serif, serif;
  font-size: 14px;
}
.ChangeText {
  color: #2f8fff;
  cursor: pointer;
  font-size: 12px;
}
.RequestTypeBanner {
  margin-top: 1rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-family: Gilroy-semibold, san-serif, serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 1.75rem;
  background: #2f8fff33;
  color: #2f8fff;
}
