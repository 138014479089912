.investorname{
    font-family: 'Gilroy';
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60px;
    color: #999999;
    font-weight: 600;
    font-size: 10px;
}

.collectedAmt{
    margin: 0;
    color: #00AA00;
    font-weight: 600;
    font-size: 10px;
}

.dueAmountWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    .upperAmount{
        font-family: 'Gilroy-Bold';
        font-size: 10px;
        margin: 0;
        color: #D80100;
    }
    .lowerAmount{
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 10px;
        margin: 0;
        color: #979797;
    }
}

.bucketWrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    color: #979797;
    flex-direction: column;
    .upperAmount{
        font-family: 'Gilroy';
        font-weight: 600;
        font-size: 10px;
        margin: 0;
        color: #000000;
    }
    .lowerAmount{
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 10px;
        margin: 0;
        color: #979797;
    }
    .portfolioUpperAmount{
        font-family: 'Gilroy-Bold';
    }
}