.UpdateMailCard {
    height: max-content;
    width: 18%;
    padding: 20px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
    font-family: "Gilroy-Medium";
    font-size: 12px;
    margin-right: 50px;
    margin-top: 1rem;
    .Text {
        font-family: "Gilroy-Medium";
    }
    select {
        height: 34px;
        width: 100%;
        margin: 20px 0 16px 0;
        border: 0.5px solid #FFFFFF;
        border-radius: 6px;
        background-color: #F9F9F9;
        color: #ADADAD;
        padding: 5px;
    }
    .Buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        .PreviewBtn {
            padding: 5px 8px;
            border: 1px solid #4C8DFF;
            border-radius: 4px;
            background-color: #fff;
            color: #4C8DFF;
        }
        .EmailBtn {
            padding: 5px 8px;
            border: 1px solid #00BD00;
            border-radius: 4px;
            background-color: #00BD00;
            color: #FFFFFF;
        }
    }
}