.BalanceConfirmationWrapper {
  .DrawerHeading {
    padding: 1.5rem;
    border-bottom: 1px solid #DCDCDC;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .HeadingText {
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Gilroy-SemiBold, sans-serif;
      font-size: 1.5rem;
      font-style: normal;
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .StatusBanner {
      font-family: Gilroy-Semibold, serif;
      display: flex;
      justify-content: center;
      font-size: 0.625rem;
      align-items: center;
      padding: 0.5rem;
      border-radius: 4px;
    }

    .CrossIcon {
      cursor: pointer;
    }
  }
}

.QuestionContainer {
  margin: 0 0 2rem 0;
  padding: 0 1.5rem;
  p {
    font-family: Gilroy-Semibold, serif;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  .ButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
  .PillButton {
    border-radius: 1.75rem;
    border: 1px solid #2f8fff;
    background: white;
    color: #2f8fff;
    font-family: Gilroy-medium, serif;
    display: flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    font-size: 0.875rem;
    align-items: center;
  }
  .SelectedPill {
    @extend .PillButton;
    background: rgba(47, 143, 255, 0.2);
    border: 1px solid rgba(47, 143, 255, 0.2);
  }
  .InputWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .InputContainer {
    display: flex;
    align-items: center;
    gap: 0.38rem;
    label {
      margin: 0;
      color: #000;
      font-family: Gilroy-semibold, serif;
      font-size: 0.75rem;
    }
  }
  .DateInput {
    margin-top: 0.5rem;
    border-radius: 0.375rem;
    border: 0.5px solid #bfbfbf;
    color: #000;
    width: 45%;
    font-family: Gilroy, serif;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    background: #fff;
  }
  .DrawdownContainer {
    margin-top: 2rem;
  }
  .DrawDownListContainer {
    border-radius: 0.5rem;
    border: 1px solid #e5e5e5;
    overflow-y: auto;
  }
  .AllDrawdowns {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem 1.5rem;
    cursor: pointer;
    font-family: Gilroy-Semibold, serif;
    font-size: 0.85938rem;
    font-style: normal;
    border-bottom: 1px solid #e5e5e5;
    &:hover {
      background: #f1f6ff;
    }
    label {
      margin: 0;
    }
  }
  .DrawdownItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 1rem;
    padding: 1rem 3rem;
    font-family: Gilroy-Semibold, serif;
    font-size: 0.85938rem;
    font-style: normal;
    border-bottom: 1px solid #e5e5e5;
    &:hover {
      background: #f1f6ff;
    }
    label {
      margin: 0;
      display: flex;
      align-items: center;
    }
    .InvestorName {
      display: inline-block;
      width: 35%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.TdsDrawerFooter {
  margin-top: auto;
  .FooterButtonsContainer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
    border-top: 1px solid #b7b7b7;
    background: #fff;
    gap: 0.5rem;
    .FooterRejectBtn {
      border-radius: 0.375rem;
      border: 1px solid #2f8fff;
      color: #2f8fff;
      font-family: Gilroy, serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      background: #fff;
    }
    .FooterSendReminderBtn {
      border: 1px solid #2f8fff;
      color: white;
      font-family: Gilroy, serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem;
      background: #2f8fff;
      &:disabled {
        background: #b7b7b7;
        border: 1px solid #b7b7b7;
        color: #fff;
      }
    }
    .FooterResolveBtn {
      border: 1px solid #2f8fff;
      color: white;
      font-family: Gilroy, serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem;
      background: #2f8fff;
      &:disabled {
        background: #b7b7b7;
        border: 1px solid #b7b7b7;
        color: #fff;
      }
    }

    .FooterCloseBtn {
      border: 1px solid #2f8fff;
      color: #2f8fff;
      font-family: Gilroy, serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem;
      background: white;
    }
  }
}

.detailsContainer {
  margin-top: 2rem;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  background: #f9f9f9;
  p {
    margin: 0;
    font-family: Gilroy-semibold, serif;
    font-size: 0.875rem;
    color: #000;
  }
  .ViewOnlyTextContainer {
    p {
      margin: 0;
      font-family: Gilroy-semibold, serif;
      font-size: 0.875rem;
      color: #000;
      span {
        font-family: Gilroy, serif;
        color: #7f7f7f;
      }
    }
  }
  .DrawdownsContainer {
    border-radius: 0.5rem;
    border: 0.5px solid #bcbcbc;
    background: #fff;
    .DrawdownItems {
      padding: 1rem;
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #bcbcbc;
      .InvestorName {
        display: inline-block;
        width: 10ch;
        margin-right: 0.5rem;
        color: black;
        font-size: 0.875rem;
        font-family: Gilroy-semibold, serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        color: #7f7f7f;
        font-family: Gilroy-Medium, serif;
        font-size: 0.75rem;
      }
    }
  }
}
.AttachmentContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .AttachmentItem {
    color: #2f8fff;
    padding: 1rem;
    border-radius: 0.5rem;
    background: #f9f9f9;
    font-family: Gilroy-Semibold, serif;
    font-size: 0.85938rem;
    cursor: pointer;
  }
}

.UploadContainer {
  margin-top: 2rem;
}
