@mixin PGStatus {
    height: 17px;
    border-radius: 4px;
    font-size: 10px !important;
    text-transform: uppercase;
    // float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.IntegrateNewPG {
    height: 28px;
    width: 122px;
    border: 1px solid #4C8DFF;
    border-radius: 4px;
    color: #4C8DFF;
    font-size: 10px;
    font-weight: 600;
    background-color: white;
}

.PGIntegrationTable {
    border: 1px solid #E9E9E9;
    border-radius: 6px;

    th {
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        border-top: 0px;
    }

    td {
        color: #2F363F;
        font-size: 12px;
        font-weight: 300;
    }

    .pointerCursor {
        cursor: pointer;
    }
}

.RAZORPAY {
    @include PGStatus;
    width: 108px;
    color: #9e3cb9 !important;
    background-color: #fdf4ff;
}

.PAYTM {
    @include PGStatus;
    width: 108px;
    color: #4c8dff !important;
    background-color: #e4eeff;
}

.CONNECTED {
    @include PGStatus;
    width: 80px;
    color: #49A71B !important;
    background-color: #E5FCDE;
}

.IN_ERROR {
    @include PGStatus; 
    width: 80px;
    color: rgb(255, 0, 0);
    background-color: #FFCCCB;
}

.pg_integration__container {
    .account_id {
        display: flex;
    }
}

.mr10 {
    margin-right: 10px;
}