.uwHeading {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    padding-left: 10px;
    .uwRiskFlag{
        margin: 0;
        margin-right: 10px;
        margin-top: 15px
    }
    .uwLogo {
        margin-top: 23px;
        padding-bottom: 5px
    }
}

.overviewUwFlagToolTip {
    width : 18rem;
}
.uwFlagComponent {
    font-family: "Gilroy-semibold";
}