.Trade-contracts{
    border-radius: 6px;
    background-color: #F9F9F9;
    padding: 10px;
    margin: 30px 0 0 0;
}
.VSclr{
    color: #4C8DFF;
}
.drawdown-select{
    border: 0.5px solid #4C8DFF;
    border-radius: 3px;
    background-color: #FFFFFF;
    font-size: 11px;
    width: 100%;
    height: 35px;
}
.Trade-contract-slider .MuiSlider-rail{
    height: 11px !important;
    border-radius: 7px !important;
    background-color: #B7B7B7 !important;
}
.Trade-contract-slider .MuiSlider-track{
    height: 11px !important;
    border-radius: 7px !important;
    background: linear-gradient(90deg, #232323 0%, #4C8DFF 51.26%, #F700F3 100%);
}
.Trade-contract-slider .MuiSlider-thumb{
    height: 21px;
    width: 21px;
    background-color: #232323;
    border: 2px solid #FFFFFF;
}
.Trade-contract-slider .MuiSlider-thumb:focus,
.Trade-contract-slider .MuiSlider-thumb:hover,
.Trade-contract-slider .MuiSlider-thumb:active{
    box-shadow: 0 2px 6px 1px rgba(0,0,0,0.5) !important;
}
.drawdowns-border{
    border: 1px solid #E9E9E9;
    border-radius: 6px;
    padding: 10px;
    margin: 30px 0 0 0;
}
.Listed-bg{
    border-radius: 4px;
    background-color: #EAEAEA;
    width: 100%;
    min-width: 80px;
    float: left;
    padding: 5px 0;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
}
.Order-Placed-bg{
    border-radius: 4px;
    background-color: #E4EEFF;
    color: #4C8DFF;
    padding: 5px;
    width: 100%;
    min-width: 80px;
    float: left;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
}
.Open-bg{
    border-radius: 4px;
    background-color: #E4EEFF;
    color: #4C8DFF;
    padding: 5px;
    width: 100%;
    min-width: 80px;
    float: left;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
}
.Active-bg{
    border-radius: 4px;
    background-color: #E5FCDE;
    color: #49A71B;
    padding: 5px;
    width: 100%;
    min-width: 80px;
    float: left;
    text-align: center;
    font-weight: 600;
    font-size: 10px;  
}
.Not-Approved-bg{
    border-radius: 4px;
    background-color: #e79696;
    padding: 5px 10px;
    color: #c21616;
    font-weight: 500;
    width: 100%;
    min-width: 80px;
    float: left;
    text-align: center;
    font-size: 10px;  
}
.Waitlisted-bg{
    border-radius: 4px;
    background-color: #e79696;
    padding: 5px 10px;
    color: #c21616;
    font-weight: 500;
    width: 100%;
    min-width: 80px;
    float: left;
    text-align: center;
    font-size: 10px;  
}
.DrawdownletterDone{
    border-radius: 6px;
    background-color: #F8FDF8;
    padding: 20px;
    margin-top: 30px;
}
#VS{opacity: unset; font-size: 10px; width: 260px;}
#VS.place-top::before,
#VS::before {
  border-bottom: 8px solid #dee2e6 !important;
}
