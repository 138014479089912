.tableContainer{
    margin-top: 2rem;
}
.noDataText{
    margin: 2rem 1rem;
    font-size: 0.7rem;
    display: block;
}
.tableRows{
    font-size: 0.8rem !important;
}
.confirmButton{
  background-color: #508cfc;
  width: 85px;
  height: 30px;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 12px;
// credits: Jagrat
}
.header_input{
    position: relative;
    input{
        background: inherit;
        background-image: url('../../../../../../../../assets/search.svg');
        background-repeat: no-repeat;
        background-position: center left;
        text-indent: 20px;
        border: none;
        // width: fit-content;
    }
}

.header-text{
    white-space: nowrap;
    padding: 0 5px;
}
.trunc{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
.trunc:hover {
    overflow: visible;
    white-space: normal;
  }
  
  .trunc:hover div {
    position: absolute;
    max-width: 30%;
    background-color: white;
    padding: 0.5rem;
    box-shadow: 0 0 4px 0 black;
    border-radius: 4px;
  }

.attachmentLink{
    color: #2F8FFF;
    cursor: pointer;
}
.showDocModal{
    margin-left: -23rem;
    width: 80rem;
    height: 100%;
}
.customBackdropClassName {
    z-index: 1200;
  }
