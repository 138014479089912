@mixin UserIcon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin UserName {
    margin-left: 5px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

.ChatContainer {
    overflow: auto;
    padding-top: 20px;
    .RecurChat {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .User {
            margin-right: 10px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            .RecurIcon {
                @include UserIcon;
                color: #00df9a;
                background: #dafff3;
            }
            .RecurUserName {
                @include UserName;
                color: #00df9a;
            }
            .LastActivity {
                margin-top: 2px;
                margin-left: 10px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                color: #b9b9b9;
            }
        }
        .Message {
            max-width: 90%;
            padding: 12px;
            margin-bottom: 10px;
            background: #f7f7f7;
            border-radius: 10px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: right;
            color: #000000;
        }
    }
    .InvestorChat {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .User {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            .InvestorIcon {
                @include UserIcon;
                color: #6021b3;
                background: #f8f2ff;
            }
            .InvestorUserName {
                @include UserName;
                color: #6021b3;
            }
            .LastActivity {
                margin-top: 2px;
                margin-left: 10px;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                color: #b9b9b9;
            }
        }
        .Message {
            max-width: 90%;
            padding: 12px;
            margin-bottom: 10px;
            background: #f7f7f7;
            border-radius: 10px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-align: left;
            color: #000000;
        }
    }
}

.RecurFilesContainer {
    justify-content: flex-end;
}
.InvestorFileContainer {
    margin-left: 0 !important;
    margin-right: 8px;
}
.RecurFileContainer {
    background: #00df9a !important;
}