.Select {
    border-radius: 3px;
    font-family: "Gilroy-Regular";
    font-size: 10px;
    padding: 8px 10px;
    width: 100%;
    margin-right: 0.5rem;
    display: flex;
    justify-content: space-between;
    border: 0.5px solid #4C8DFF;
    background-color: #FFFFFF;
    position: relative;
    cursor: default;
}

.DisabledSelect {
    color: #808080;
    background: #F9F9F9;
    border: 0.5px solid #E5E5E5;
}

.checkBox {
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
    width: 220px;
    position: fixed;
    top: 84.5%;
    padding: 15px;
    z-index: 10;

    position: absolute;
    top: 280px;

    input {
        width: fit-content;
    }
}

.dropdownOptions {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0px;
    }

    input {
        margin-bottom: 0;
        margin-right: 8px;
        width: 15px !important;
    }

    label {
        margin-bottom: 0;
        font-family: 'Gilroy-Medium';
        font-size: 13px;
    }
}

.roleLabel {
    cursor: pointer;
}

.addCollection{
    width: 100%;
    text-align: right;
    
    font-family: 'Gilroy',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    color: #4C8DFF;
    cursor: default;
}
.addCollection:hover{
    cursor: pointer;
}