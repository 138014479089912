.Button {
    font-weight: 600;
    font-size: 14px;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    background-color: rgba(76, 141, 255, 1);
    border: 0.5px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    position: relative;
    transition: all 150ms ease-in-out;

    &:active {
        transform: translateY(2px);
    }

    .Icon {
        margin-right: 5px;
        margin-bottom: 1px;
    }

    &:focus {
        outline: none;
    }
}

.White {
    color: black;
    border: 1px solid black;
    background-color: white;
}

.Blue {
    background-color: rgba(76, 141, 255, 1);
    border: 1px solid rgba(76, 141, 255, 1);
    color: white;
}

.WhitePurple {
    color: rgba(76, 141, 255, 1);
    border: 1px solid rgba(76, 141, 255, 1);
    background-color: white;
}

.Disabled {
    background: rgba(219, 219, 219, 1);
    pointer-events: none;
    border: 1px solid rgba(219, 219, 219, 1);
    cursor: not-allowed;
    color: rgba(151, 151, 151, 1);
}

.Tiny {
    font-weight: 500;
    font-size: 10px;
}

.Small {
    font-family: "Gilroy-Semibold", sans-serif;
    font-weight: 600;
    font-size: 12px;
}

.Wide {
    width: 100%;
}
