.toggleContainer {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    font-family: gilroy-semibold, sans-serif;
    font-size: 0.7rem;
    white-space: nowrap;
    gap: 1rem;
}
.totExpiryDatePicker{
    width: 6.18rem;
    height: 1.75rem;
    border-radius: 0.25rem;
    border: 1px solid #4C8DFF;
    padding: 0.5rem;
    margin-left: 0.5rem;
    font-size:0.625rem;
    color:#4C8DFF;
}
.totExpiryDatePicker::-webkit-inner-spin-button,
.totExpiryDatePicker::-webkit-calendar-picker-indicator{
    display: none;
    -webkit-appearance: none;
}