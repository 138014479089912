.riskTooltipWrapper{
    position:absolute;
    background: white;
    border-radius: 0.5rem;
    width: 16rem;
    padding: 1rem;
    z-index: 100;
    right: 0;
    h6{
        white-space: nowrap;
    }
    
}
.riskRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    color: #999999;
}
.riskRowLeft{
    font-size: 10px;
    white-space: nowrap;
}
.requestedRowRight{
    font-size: 10px;
    white-space: nowrap;
}