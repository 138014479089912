/* Overview status starts */
.TotComStatus {
  height: 17px;
  width: 70px;
  font-size: 8px;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sent-tot-status {
  color: #d6bb0a;
  background-color: #fffad7;
}

.Accepted-tot-status {
  color: #49a71b;
  background-color: #e5fcde;
}

.discount-applicable-status {
  color: #C89E74;
  background-color: #FDF5EF;
  padding: 0 2px;
}

.Creating-tot-status {
  color: #a71b1b;
  background-color: #f4f4f4;
}

.Tentative-tot-status,
.Created-tot-status,
.Not-Sent-tot-status {
  color: #989898;
  background-color: #f4f4f4;
}

/* Overview status ends */
