.StatusBar {
    font-size: 18px;
    font-family: 'Gilroy-SemiBold', sans-serif;
    display: flex;
    gap: 3%;
    padding: 15px 0px;
    .underLine {
        border-bottom: 3px solid black;
    }
    .eachTab {
        padding-bottom: 5px;
    }
}