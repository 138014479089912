.complianceSection {
    position: relative;
    width: 100%;
  }
  


.ComplianceTableCard {
    text-align: center;
    margin: 0px 35px 0px 35px;
    border-radius: 6px;
    display: block;
    border: 1px solid #DCDCDC;
    position: relative;
    overflow: overlay;
    scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
    scrollbar-width: thin !important;
    max-height: calc(100vh - 150px);
  
    &::-webkit-scrollbar {
      width: 19px !important;
      scroll-padding-right: 100px !important;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 10px !important;
      border-left: 0px solid transparent !important;
      border-right: 0px solid transparent !important;
      margin: 48px 0 5px 0;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #e6e6e6 !important;
      border-radius: 10px !important;
      border-top: 6px solid white !important;
      border-bottom: 6px solid white !important;
      border-left: 6px solid white !important;
      border-right: 6px solid white !important;
    }
  
    table {
      width: 100%;
  
      thead {
        background-color: #f9fafc;
        border-bottom: 1px solid #979797;
        font-family: Gilroy-Bold;
  
        th {
          position: sticky !important;
          z-index: 1;
          top: 0;
          padding: 12px 0;
          background-color: white;
          vertical-align: middle;
          color: #535b62;
          font-family: Gilroy-Medium;
          font-size: 12px;
          font-weight: 600;
          text-align: center;

  
          &:first-child {
            padding-left: 10px;
          }
        }
      }
  
      tbody {
        font-family: Gilroy;
  
        tr {
          color: #000000;
          border-bottom: 1px solid #e8e3e3;
          background-color: white;
          height: 73px;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          text-align: center;

  
          td {
            vertical-align: middle;
  
            &:first-child {
              padding-left: 10px;
              // padding-right: 70px;
            }
          }
        }
      }
    }
  }

.logo{
    position: relative;
    height: 50px;
    overflow: hidden;
}

.logo img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    padding:1px;
}


.status{
    font-family: Gilroy-SemiBold,sans-serif;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.00019968050764873624px;
    text-align: left;
    padding-top:10px;
    width:90%;
    .statusMet{
        display: inline-block;
        color:#00AA00;
        width: 46%;
    }
    .statusPartially{
        display: inline-block;
        color:#FCBF07;
        width: 27%;
    }
    .statusNotMet{
        display: inline-block;
        color: #939393;
        text-align: right;
        width: 27%;
    }
}

.progressBar {
    width: 90%;
    background-color: #F3F3F3;
    border-radius: 10px;
    height: 10px;
    .metPlusPartial{
        background-color: #FFF2CC;
        height: 100%;
        border-radius: 10px;
        .met {
            background-color: #E5FCDE;
            height: 100%;
            border-radius: 10px;
        }
    }
}

.redColor{
    color:#F40000;
}

.complianceRow:hover{
    cursor: pointer;
    background-color: #f9f9f9;
}

.wid20{
  white-space: nowrap;
  width:20%;
}
.wid15{
  white-space: nowrap;
  width: 15%;
}
.wid10{
  white-space: nowrap;
  width:10%;
}
.truncate{
  padding-left: 10px;
  width:240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4C8DFF;
  text-align: left;
}
.header_input{
  position: relative;
  input{
      background: inherit;
      background-image: url('../../../../../../assets/search.svg');
      background-repeat: no-repeat;
      background-position: center left;
      text-indent: 20px;
      border: none;
  }
}
