.burn_box {
  background-color: #f8f8f8;
  width: 380px;
  height: 223px;
  position: absolute;
  left: 786px;
  z-index: 5;
  opacity: 0.9;
}

.form_format {
  display: flex;
  justify-content: space-between;
}

.burn_display {
  display: flex;
  justify-content: space-between;
  width: 325px;
  font-size: 12px;
  margin-top: 7px;
}

.burn_scroller {
  overflow-y: scroll;
  height: 200px;
}

.burn_values {
  width: 115px;
}

.curr_bal_info_box {
  width: 600px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 0px;
  font-size: 14px;
  position: sticky;
  left: 0px;
}

.curr_bal_info_row{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.view_balances {
  display: flex;
  align-items: center;
  color: #4c8dff;
  font-size: 13px;
  margin-right: 20px;
  cursor: pointer;
  background: none;
  border: none;
  position: relative;
  left: 400px;
  z-index: 5;
  white-space: nowrap;
  /* bottom: 64px; */
}

.balance_box {
  background-color: #eeeee4;
  width: 450px;
  height: 150px;
  position: absolute;
  top: 0px;
  left: 900px;
  z-index: 7;
  opacity: 0.9;
  padding: 5px;
}

.width33 {
  width: 33%;
}

.view_balance_headers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.row_view_balance {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 300;
}

.curr_balance {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
}

.total_line {
  margin: 0;
  padding: 0;
  background-color: grey;
}

.view_balance_table_row {
  overflow-y: scroll;
}

.amountSort,
.dateSort {
  border: none;
  background: none;
}

.anchor_btn {
  font-size: 1.3rem;
}

.SearchBar {
  position: relative;
}

.SearchBar img {
  position: absolute;
  top: 6px;
  right: 6px;
  display: block;
}

.SearchBar input {
  min-width: 150px;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  padding: 5px 25px 5px 5px;
  font-family: 'Gilroy-SemiBold';
  font-size: 10px;
  color: #979797;
}

.SearchBar input:valid {
  color: #000000;
}

.DropdownOptions {
  position: absolute;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.147918);
  border: 1px solid #E4E4E4;
  background-color: #ffffff;
  border-radius: 2px;
  max-height: 200px;
  min-width: 150px;
  margin-top: 5px;
  padding: 5px;
  z-index: 10;
  overflow: overlay;
}

.od_container {
  margin-bottom: 10px;
  font-family: gilroy-regular, sans-serif;
  font-size: 14px;
}

.od_top {
  display: flex;
  align-items: center;
}

.od_bottom {
  display: flex;
  align-items: center;
  color: #8D8D8D;
  font-size: 8px;
  font-weight: 500;
}

.updated_by {
  color: #000;
  font-size: 8px;
  font-weight: 700;
}

.od_value {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.edit_od_input {
  width: 50px;
  line-height: 14px;
  font-size: 12px;
  margin-left: 0px;
  padding: 0 2px;
}

.editImg {
  margin-left: 10px;
  cursor: pointer;
  width: 14px;
}

.checkImg {
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.edit_od_input::-webkit-outer-spin-button,
.edit_od_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}