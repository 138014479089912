#company-detail {
  font-family: "Graphik";
  /* font-family: 'Aktiv-Grotesk'; */
}

.update-stats {
  background-color: #000000;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
}

.mark-as-paid {
  background-color: #2F8FFF;
  color: #ffffff;
  padding: 7px;
  border-radius: 4px;
  border-width: 0px;
  font-size: 10px;
  font-weight: 600;
}

/* 1st row css starts */
.company-detail-row1 {
  background-color: #f0f0f0;
  padding: 20px 20px 35px 20px;
}

.btn-bg-clr {
  height: 40px;
  border-radius: 6px;
  border: none;
  background-color: #000000;
}

.btn-blue-clr {
  height: 40px;
  border-radius: 6px;
  border: none;
  background-color: #4c8dff;
  opacity: unset !important;
}

.company-big-btn {
  height: 40px;
  width: 129px;
  color: #ffffff;
}

.company-small-btn {
  box-sizing: border-box;
  height: 36px;
  min-width: 82px;
  opacity: 0.87;
  color: #f0f0f0;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-right: 6px;
}

.date-go-btn {
  border-radius: 4px;
  border: 1px solid #2f8fff;
  box-sizing: border-box;
  height: 23px;
  min-width: 54px;
  opacity: 0.87;
  color: #2f8fff;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-right: -15px;
  margin-top: 3px;
  opacity: unset !important;
  background-color: white;
}

.underwriting-select {
  min-width: 100%;
}

.sub-classification-data {
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  font-family: Helvetica;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 10px;
  padding: 5px;
  float: left;
  width: 100%;
  min-width: 100%;
  text-align: center;
  /*
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-right: -15px;
  margin-top: 3px; */
  background-color: #f9f9f9;
}

.go {
  height: 12px;
  width: 22px;
  color: #2f8fff;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
}

.small-fonts {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 300;
}

.medium-fonts {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.big-fonts {
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}

.grey-text {
  color: #8d8d8d;
}

.grey-text span {
  color: #037bff;
}

.black-text {
  color: #000000;
}

.black-text span {
  color: #279c13;
  font-weight: 500;
}

.last-edit {
  opacity: 0.53;
  color: #000000;
  font-weight: 500;
  margin-top: 20px;
  margin-right: 50px;
}

.last-edit span {
  font-weight: 300;
}

.price-irr-fees {
  opacity: 0.37;
  color: #222e43;
  line-height: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.price-irr-number {
  height: 44px;
  min-width: 129px;
  border-radius: 4px;
  background-color: #e2e2e2;
  padding: 8px;
  font-size: 14px;
}

.company-name {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin: 20px 0px;
}

/* 1st row css ends */

/* 2st row css starts */
.page-head-bottom-border {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}

.cmp-row2-head {
  opacity: 0.37;
  color: #222e43;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  font-weight: 300;
  margin-top: 20px;
}

.asterisk:after {
  content: " *";
  color: red;
}

.cmp-row2-head1 {
  opacity: 0.37;
  color: #222e43;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  font-weight: 300;
}

.more-width {
  width: 303px;
  height: 44px;
  outline: none;
}

.less-width {
  width: 214px;
  height: 44px;
  outline: none;
}

.cmp-row2-field {
  border-radius: 4px;
  border: none;
  background-color: #f1f1f1;
  max-width: 100%;
  font-weight: 300;
  height: 44px;
  font-size: 14px;
}

.cmp-overview {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 20px;
  height: 208px;
  overflow-y: scroll;
  /* width: 470px; */
}

.cmp-logo {
  width: 100%;
  max-width: 190px;
  object-fit: cover;
}

.company-detail-row2,
.company-detail-row3 {
  background-color: transparent;
  padding: 35px 15px 35px 15px;
  /* width: 1465px; */
  margin: 0 auto;
  box-sizing: border-box;
}

/* 2st row css ends */

/* Table css starts */
.company-table {
  margin-bottom: 0px;
}

.company-table thead tr th {
  padding: 4px !important;
  opacity: 0.27;
}

.company-file-name {
  color: #0179ff;
  text-decoration: underline;
}

.cmp-row2-table {
  border-radius: 4px;
  border: none;
  background-color: #f1f1f1;
}

.cmp-row3-table {
  box-sizing: border-box;
  border: 1px solid #979797;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.cmp-tbl-head {
  color: #000000 !important;
  font-size: 10px !important;
  letter-spacing: 0 !important;
  line-height: 12px !important;
  padding: 3px;
  opacity: 0.27;
}

.cmp-table-row {
  color: #000000 !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 14px !important;
  padding: 0px 30px;
}

.cmp-table-row td,
.cmp-table-row {
  opacity: 0.7;
  padding: 8px;
  /* border-top: 1px solid #000000 !important; */
}

.company-addition-doc {
  opacity: 0.7;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 14px !important;
  padding: 0px 30px;
}

.company-row1 {
  margin: 0 auto;
}

.saas-matric {
  opacity: 0.5;
  color: #000000;
  font-family: Graphik;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  border-top: 1px solid #242424;
  margin: 0px;
  padding: 3px 16px 0px 16px;
}

.saas-matric-head {
  border-top: 1px solid #242424;
}

.company-address {
  width: 100%;
  height: 77px !important;
}

/* Table css ends */

/* Companies Edit Page css starts */
.company-edit-cancel {
  height: 40px;
  width: 85px;
  color: #d71818;
  border: 1px solid #dc1212;
  border-radius: 6px;
  margin-right: 30px;
}

.company-edit-save {
  height: 40px;
  width: 70px;
  border: none;
  color: #ffffff;
}

.edit-dropdown {
  box-sizing: border-box;
  height: 44px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 8px;
  font-weight: 300;
  font-size: 14px;
}

.edit-dropdown:focus {
  outline: none;
}

.edit-upload-addition-btn {
  color: #000000 !important;
  font-size: 10px !important;
  letter-spacing: 0 !important;
  line-height: 12px !important;
  padding: 3px;
}

.dropdown-width1 {
  max-width: 100%;
}

.dropdown-width2 {
  min-width: 100%;
}

.price-irr-width {
  min-width: 129px;
}

.upload-btn {
  height: 26px;
  width: 111px;
  border-radius: 3px;
  border: none;
  background-color: #000000;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.remove-btn {
  box-sizing: border-box;
  height: 26px;
  width: 111px;
  border: 1px solid #d0021b;
  border-radius: 3px;
  background-color: #ffffff;
  color: #d0021b;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  position: absolute;
  top: 80%;
  right: 13%;
}

.upload-btn-onimg {
  position: absolute;
  top: 60%;
  right: 13%;
}

/* Companies Edit Page css ends */

/* Investor Detail Page Css starts  */

.investor-field {
  height: 44px;
  width: 100%;
  border-radius: 4px;
  border: none;
  opacity: unset;
  color: #000000;
  font-weight: 300;
  padding: 0px 5px;
  background-color: #f1f1f1;
}

.investor-field-edit {
  height: 44px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 8px;
  font-weight: 300;
}

.investor-field:focus {
  outline: none;
}

/* Investor Detail Page Css ends  */
@media only screen and (max-width: 1465px) {

  .company-row1,
  .company-detail-row2,
  .company-detail-row3 {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1250px) {
  .cmp-row2-field {
    max-width: 90%;
  }

  .cmp-overview {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 30px;
    height: auto;
    text-align: justify;
    /* width: 470px; */
  }

  .upload-btn,
  .remove-btn {
    margin: 0 auto;
    width: 70%;
  }
}

@media (min-width: 1251px) and (max-width: 1500px) {}

@media (min-width: 1550px) and (max-width: 1750px) {

  .company-row1,
  .company-detail-row2,
  .company-detail-row3 {
    width: 100%;
  }
}

.generate-btn {
  box-sizing: border-box;
  height: 26px;
  width: 111px;
  border: 1px solid #4c8dff;
  border-radius: 3px;
  background-color: #ffffff;
  color: #4c8dff;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate-btn:first-child {
  margin-right: 7px;
}

.director-details {
  font-size: 16px;
  font-family: gilroy-medium, sans-serif;
  color: #1C1C1C;
  font-weight: 500;
}

.compliance-details-table{
  max-width: 1375px;
  max-width:calc(100vw - 305px);
  overflow-x: scroll;
  margin-left: 50px;
  margin-bottom: 50px;
}

.compliance-details {
  font-size: 16px;
  font-family: gilroy-medium, sans-serif;
  color: #1C1C1C;
  font-weight: 500;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
  position: sticky;
  left: 0;
}

.update-button {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 9px;
  color: #FFFFFF;
  background: #4C8DFF;
  border-radius: 4px;
  padding: 10px 12px;
  height: 30px;
  cursor: pointer;
}


.table-content td {
  font-weight: 300;
}

.error-msg {
  color: rgb(237, 2, 2);
  font-family: 'Gilroy-Semibold', sans-serif;
  font-size: 13px;
}

.insights-settings {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 10px 20px;
  width: fit-content;
}

.insights-settings>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  margin: 10px 0;
}

.insights-settings-button {
  padding: 8px 10px 6px 10px;
  color: white;
  background-color: #4C8DFF;
  border-radius: 4px;
  font-family: 'Gilroy-Semibold', sans-serif;
  font-size: 14px;
  margin-left: 20px;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.img-cibil-download{
    width: 17px;
    height:auto;
    margin-left: 5px;
}

.last-updated {

  font-family: 'Gilroy-medium',sans-serif;
  font-style: normal;
  font-size: 9px;
  white-space: nowrap;
  margin-top: 10px;
  color: #000000;
}
