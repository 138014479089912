.header_input{
    position: relative;
    input{
        background: inherit;
        background-image: url('../../../../../../../assets/search.svg');
        background-repeat: no-repeat;
        background-position: center left;
        text-indent: 20px;
        border: none;
    }
}

.uw_width {
    width: 100px
}

.company_status_width {
    width: 140px;
}
.deal_status_width {
    width: 135px;
}
.addOn {
    font-size: 10px;
    padding: 1px 4px;
    background-color: rgba(252, 191, 7, 0.4);
    border-radius: 3px;
    margin-left: 5px;
    color: #A14F17;
}

.s_moderation {
    background-color: #2F8FFF;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    padding: 5px 10px;
}

.deal_status_width {
    width: 127px
}

.addOn {
    font-size: 10px;
    padding: 1px 4px;
    background-color: rgba(252, 191, 7, 0.4);
    border-radius: 3px;
    margin-left: 5px;
    color: #A14F17;
}

.dealStatusStyle {
    border-radius: 4px;
    text-align: center;
    padding: 5px 8px;
}

.deal_status_expired,
.deal_status_completed,
.deal_status_deal_not_created {
    background-color: #F3F3F3;
    color: #939393;
}
.deal_status_not_shared {
    background-color: #E4EEFF;
    color: #2F8FFF;
}     
.deal_status_partially_shared {
    background-color: #E4EEFF;
    color: #2F8FFF;
}    
.deal_status_shared {
    background-color: #D0F1FF;
    color: #2F8FFF;
}

.deal_status_approved {
    background-color: #D3FFF7;
    color: #08B0B3;
}

.deal_status_query {
    background-color: #FBEEEE;
    color: #D06D6D;
}

.deal_status_others {
    background-color: #E4EEFF;
    color: #2F8FFF;
}

.limits_action {
    background-color: #2F8FFF;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    padding: 5px 10px;
    width: 112px;
}
        
.riskFlag {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
}
.red {
  background-color: #F40000;
}
.blue {
  background-color: #2F8FFF;
}
.green {
  background-color: #4CB71A;
}
.yellow {
  background-color: #FCBF07;
}
.wrapperStyles{
    div{
        background-color: white;
    }
}

.filter_arr_wrapper{
    margin: 30px 25px 30px 35px;
    font-family: Gilroy-SemiBold;
    span{
        border-radius: 4px;
        font-size: 12px;
        padding: 4px;
        background-color: #E8F0FF;
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;

        &:first-child{
            margin-left: 0;
        }
    }
   .clear_all{
    font-size: 12px;
    color: #2F8FFF;
  }
}

.cta {
    cursor: pointer;
    width: 20px;
    height: 20px;
    img {
        width: 100%;
        height: 100%;
    }
    &:hover{
        background-color: #F9FAFC;
    }
}

.tableBox {
    &:hover {
        background-color: #e9eaee;
    }
}

.appLimitsTable {
    margin-top: 30px;

    table {
        thead, tbody {
            th, td {
                width: 10.125%;

                &:first-child {
                    width: 14%;
                }
                &:last-child {
                    width: 5%;
                }
            }
        }
        tbody {
            td {
                height: 60px;
                font-size: 12px;
            }
        }
    }
}

.heading{
    font-family: Gilroy-Medium;
    font-weight: normal;
    color: #757575;
}
