#Moderation-Comment {
    z-index: 1101;
}

#Moderation-Comment .modal-dialog {
    margin: 60px auto;
    max-width: 1450px;
}

/* #Moderation-Comment .modal-content{
    margin-left: 5%;
} */
#Moderation-Comment .modal-content .modal-header {
    border-bottom: none;
}

.reviewFinancialCheckbox {
    width: 15px;
}

#addAccountOb1 {
    margin-bottom: 8px;
}