select.needInfoSelect {
  color: #c3c3c3;
}
.needInfoForm {
  width: 100%;
  height: fit-content;
  .needInfoFormCard {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: rgba(112, 144, 176, 0.16) 0px 1px 10px 0px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    .removeIcon {
      position: absolute;
      top: 3%;
      right: 2%;
    }
  }
  label {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
  }

  .needInfoFormWrapper {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 20px;
    .selectWrapper {
      flex: 2;
      .datatypeError {
        margin: 5px 0 0 0;
        color: #df666a;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .selectWrapper label.error {
      color: #df666a;
    }
    .selectWrapper .needInfoSelect.error {
      border: 0.5px solid #df666a;
      color: #df666a;
      background: url("../../../../../../../../../../../../../public/assets/dropdown-red.svg")
        no-repeat;
      background-size: 10px 12px;
      background-position-x: 95% !important;
      background-position-y: 50% !important;
      &::placeholder{
        color: #df666a !important;
      }
    }
    .inputWrapper {
      flex: 3;
    }
  }
  .needInfoSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding-right: 3%;
    min-height: 48px;
    height: 100%;
    width: 100%;
    text-align: left;
    padding-left: 3%;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    color: #000000;
    border-radius: 6px;
    border: 0.5px solid #cecece;
    background: url("../../../../../../../../../../../../../public/assets/dropdown-arrow.svg")
      no-repeat;
    background-size: 10px 12px;
    background-position-x: 95% !important;
    background-position-y: 50% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus,
    &:active,
    &:focus-visible {
      border: 0.5px solid #2f8fff;
    }
    &:disabled {
      background: transparent;
      background-color: #f9f9f9;
      border: 0.5px solid #cecece;
      color: green;
    }
    option[value=""][disabled] {
      color: gray;
    }
  }
  .needInfoInput {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding-right: 3%;
    min-height: 48px;
    height: 100%;
    width: 100%;
    text-align: left;
    padding-left: 3%;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    color: #000000;
    border-radius: 6px;
    border: 0.5px solid #cecece;
    background: url("../../../../../../../../../../../../../public/assets/search-icon.png")
      no-repeat;
    background-size: 24px;
    background-position-x: 95% !important;
    background-position-y: 50% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus,
    &:active,
    &:focus-visible {
      border: 0.5px solid #2f8fff;
    }
    &:disabled {
      background: transparent;
      background-color: #f9f9f9;
      border: 0.5px solid #cecece;
      color: green;
    }
  }
  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 10px 10px 10px;
    textarea {
      resize: none;
      display: flex;
      align-items: center;
      justify-content: center;
      // text-align: center;
      padding: 10px;
      min-height: 48px;
      height: 100%;
      width: 100%;
      font-size: 12px;
      color: #000000;
      border-radius: 6px;
      border: 0.5px solid #cecece;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:focus,
      &:active,
      &:focus-visible {
        border: 0.5px solid #2f8fff;
      }
      &:disabled {
        background: transparent;
        background-color: #f9f9f9;
        border: 0.5px solid #cecece;
        color: green;
      }
    }
  }
  .needInfoCheckBoxWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 20px 10px;
    div {
      display: flex;
      align-items: center;
    }
    input {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }

    label {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #000000;
    }
  }
}
.outerButtonWrapper {
  margin: 15px;
  display: flex;
  align-items: center;
  column-gap: 35px;
  button {
    padding: 10px 20px;
    background-color: #ffffff; /* White background */
    color: #4c8dff; /* Text color */
    border: none; /* Border color */
    border-radius: 4px; /* Rounded corners */
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: rgba(112, 144, 176, 0.16) 0px 1px 10px 0px;
    p {
      margin: 0 0 0 10px;
      font-size: 12px;
      font-weight: 600;
    }
    p.remove {
      color: #df666a;
    }
  }
}
.investorWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 0px 10px 5px 10px;
  flex-wrap: wrap;
  row-gap: 6px;
  div {
    display: flex;
    align-items: center;
    background-color: #e8f0ff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    padding: 4px;
    p {
      margin: 0 10px 0 0;
    }
  }
}

.needInfoDeleteModal {
  p {
    margin: 0 0 30px 0;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  .deleteButtonWrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: flex-end;
    .cancel {
      padding: 10px 20px;
      background-color: #ffffff; /* White background */
      color: #4c8dff; /* Text color */
      border-radius: 4px; /* Rounded corners */
      border: 0.5px solid #2f8fff;
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .delete {
      padding: 10px 20px;
      background-color: #df666a; /* White background */
      color: #ffffff; /* Text color */
      border: none; /* Border color */
      border-radius: 4px; /* Rounded corners */
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
