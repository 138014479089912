*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.full_section {
  display: flex;
}

.metrics_box {
  margin-top: 20px;
  height: 600px;
  /* border: 2px solid black; */
}
.tooltip_class {
  background-color: rgb(47, 45, 45); 
  color: rgb(240, 240, 240); 
  font-size: 10px;
  font-weight: normal; 
   border: 1px solid rgb(205, 205, 205); 
  max-width: 350px; 
  box-shadow: 1px 1px 1px 1px rgba(244, 244, 244, 0.2); 
}
.tooltip_component {
  font-weight: 500;
  font-family: Gilroy-Medium;
  color: #000000;
  margin: 0;
  font-size: 12px;
}
.tooltip_span {
  font-weight: 700;
  font-family: Gilroy-Bold;
  color: #000000;
  display: inline;
}

.from_to_date_box {
  height: 62px;
  width: 354px;
  border: 1px solid #e9e9e9;
  padding-left: 5px;
  border-radius: 10px;
  background-color: #ffffff;
}

.calc_new_section {
  height: 41px;
  width: 273px;
}

.col_1_date,
.col_2_date {
  height: 41px;
  width: 80px;
  margin-top: 13px;
  /* border: 0.5px solid black; */
}

.from {
  height: 10px;
  width: 20.51px;
  color: #8d8d8d;
  font-size: 8px;
  line-height: 10px;
}

.col_1_date_input,
.col_2_date_input {
  height: 28px;
  width: 80px;
  border: 0.5px solid #2f8fff;
  border-radius: 4px;
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}

#col_1_date_input,
#col_2_date_input {
  background-color: #f9f9f9;
}

.to {
  height: 10px;
  width: 9px;
  color: #8d8d8d;
  font-size: 8px;
  line-height: 10px;
}

.calc_metrics_btn {
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  height: 28px;
  width: 90px;
  border-radius: 4px;
  background-color: #4c8dff;
  margin-top: 26px;
  border: none;
}

.three_col {
  height: 41px;
  width: 273px;
  display: flex;
  justify-content: space-between;
  margin-left: 38px;
}

.history_box {
  height: 338px;
  width: 354px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 38px;
  flex: 1;
  overflow-y: scroll;
  position: relative;
}

.history {
  height: 14px;
  /* width: 84.3px; */
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-top: 39px;
  margin-left: 39px;
}

.update_btn {
  height: 28px;
  width: 90px;
  border-radius: 4px;
  background-color: #4c8dff;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 29px;
  text-align: left;
  /* margin-left: 114px; */
  border: none;
}

.disable_update_btn {
  background-color: #c0c0c0;
}
.update_button {
  text-align:left;
  margin-right:35px;
}

.hist_row_one {
  display: flex;
  justify-content: space-between;
  height: 75px;
  position: sticky;
  top: 0px;
  background-color: #ffffff;
}

.company_metrics {
  height: 58rem;
  width: 825px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  margin-left: 25px;
  padding-right: 10px;

}

.comp_metrics_word {
  /* height: 14px;
  width: 102px; */
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  padding-top: 3px;
}

.metrics_table {
  height: 690px;
  /* display: flex;
  flex-wrap: wrap; */
}
.update_date_block{
  display: flex;
  flex-direction: column;
}


.last_update_details {
  border-top: 1px solid #e9e9e9;
  height: 70px;
  display: flex;
  
}

.update_date {
  display: flex;
  width: 200px;
  justify-content: space-between;
  margin-left: 24px;
}

.manual_date_section {
  width: 350px;
  display: flex;
  justify-content: space-between;
}

.date_1 {
  margin-left: 30px;
}

.btn_safari {
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  height: 28px;
  width: 120px;
  border-radius: 4px;
  background-color: #4c8dff;
  margin-top: 16px;
  margin-right: 20px;
  border: none;
}

.list_box {
  height: 30px;
  width: 280px;
  background-color: #f9f9f9;
  margin-bottom: 13px;
  margin-left: 38px;
  border-radius: 4px;
}

.list_box_back_change {
  border: 1px solid #4c8dff;
  background-color: #e6f1ff;
  box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.12);
}

.list_box:hover {
  cursor: pointer;
  border: 1px solid #4c8dff;
}

.from_to_div {
  display: flex;
  /* padding-top: 8px; */
  padding: 8px 2px 0px 3px;
}

.from_date_hist,
.to_date_hist,
.dash,
.created_time {
  color: #000000;
  font-size: 8px;
  letter-spacing: 0;
  line-height: 12px;
}

.last_update_time {
  font-size: 9px;
  position: relative;
  top: 5px;
  left: 24px;
  font-weight: 300;
}

.last_update_span {
  font-weight: 400;
}

.dash {
  margin-left: 2px;
  margin-right: 2px;
}

.activee {
  width: 64px;
  border-radius: 4px;
  background-color: #e5fcde;
  color: #49a71b;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  margin: 0px 13px 0px 12px;
  padding: 3px 19px 4px 20px;
}

.updating {
  width: 64px;
  border-radius: 4px;
  background-color: #fff7e9;
  color: #ca9d17;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  margin: 0px 13px 0px 12px;
  padding: 3px 19px 4px 15px;
}

.inactive {
  display: inline-block;
  width: 91.75px;
}

.input_rectangle {
  display: flex;
  width: 250px;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  background-color: #f9f9f9;
  margin-bottom: 12px;
}

#input_label {
  font-size: 10px;
  font-weight: 300;
  margin: 0;
  padding-left: 10px;
}

.main_input {
  font-size: 12px;
  border: none;
  width: 125px;
  color: #00aa00;
  background-color: inherit;
  text-align: right;
  padding-right: 10px;
  padding-left: 5px;
}

.main_input_blue {
  font-size: 12px;
  border: none;
  width: 125px;
  color: #2F8FFF;
  background-color: inherit;
  text-align: right;
  padding-right: 10px;
  padding-left: 5px;
}

.input_div {
  display: flex;
  align-items: center;
}
.component {
  break-inside: avoid; 
  margin-bottom: 32px; 
   margin-left: 12px; 
}
.container {
  column-count: 3; 
  column-gap: 10px;
}

.col_input {
  margin-left: 20px;
}

.main_input_edit {
  border: 0.5px solid #4c8dff;
  border-radius: 3px;
}

.input_rectangle_edit {
  background-color: #ffffff;
}

.headers {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 14px;
  margin-top: 0px;
}

.pencilImage{
  width: 11px;
  margin-right: 8px;
  margin-left: 8px;
}

/* .col_td {
  display: inline-block;
} */

.SaveBtn {
  height: 28px;
  width: 115px;
  border-radius: 4px;
  background-color: #4c8dff;
  border: none;
  color: #ffffff;
  font-size: 10px;
}

.EditBtn {
  height: 28px;
  width: 115px;
  border-radius: 4px;
  background-color: #2f8fff;
  border: none;
  color: #ffffff;
  font-size: 10px;
}

.SaveBtn:hover,
.CancelBtn:hover {
  border: 1px solid black;
}

.CancelBtn {
  height: 28px;
  width: 115px;
  border: none;
  border-radius: 4px;
  background-color: #df666a;
  color: #ffffff;
  font-size: 10px;
  margin-left: 20px;
}

.ActiveBtn {
  height: 28px;
  width: 115px;
  border-radius: 4px;
  background-color: #00bd00;
  color: #ffffff;
  font-size: 10px;
  margin-left: 20px;
  border: none;
}

.SaveCancelBtn {
  position: relative;
  left: 335px;
  top: 25px;
}

.EditActiveBtn {
  position: relative;
  left: 335px;
  top: 20px;
}

/* 
.cash_position {
  position: relative;
} */
.others_position {
  position: relative;
  top: 283px;
  right: 269px;
}

.outer_table_div {
  display: flex;
}

.QuaterYearBtnDiv {
  position: relative;
  left: 960px;
  bottom: 30px;
  display: flex;
}

.metrics_export {
  margin-top: 3px;
}

.BtnList {
  border-radius: 4px;
}

.QuaterBtn,
.YearBtn {
  border: none;
  color: grey;
  border-radius: 4px;
  font-size: 13px;
}

.BtnBackChange {
  background-color: #4c8dff;
  color: #ffffff;
}

@media only screen and (width > 1440px) {
  #company_metrics {
    width: 845px;
    margin-left: 40px;
  }

  #col_input {
    margin-left: 15px;
  }

  #input_rectangle {
    width: 255px;
  }

  .others_position {
    position: relative;
    top: 200px;
    right: 290px;
  }

  #QuaterYearBtnDiv {
    position: relative;
    left: 1060px;
    bottom: 30px;
    display: flex;
  }

  .SaveCancelBtn {
    position: relative;
    left: 355px;
    top: 20px;
  }

  .EditActiveBtn {
    position: relative;
    left: 355px;
    top: 20px;
  }

  .update_date {
    display: flex;
    width: 200px;
    justify-content: space-between;
    margin-left: 24px;
  }

  #computeType {
    position: relative;
    display: block;
    color: #da2d41;
  }
}

.activeType {
  color: #00aa00;
  font-size: 12px;
}
.comp_metrics_container{
  display: flex;
  flex-direction: row;
  gap: 10px;


}

.computeType {
  display: block;
  color: #da2d41;
}
.containerForComputeType {
  display: flex;
  align-items: center;
  display: flex;
  margin-left: 12px;
  margin-top: 15px;
  justify-content: space-between;
  column-gap: 10px;
}

.star {
  position: relative;
  line-height: 11.93px;
  color: #da2d41;
  margin: 3px;
}

.requiredText {
  font-size: 10px;
  color: black;
}

.company_metrics_row {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #00bd00;
  border-radius: 50%;
  display: inline-block;
}

#dot {
  height: 8px;
  width: 8px;
  background-color: #da2d41;
  border-radius: 50%;
  display: inline-block;
}

.metricsDateRow {
  margin-top: 1rem;
  margin-left: 5px;
}

.spreadPivotBtn {
  margin-left: 5px;
  margin-top: 2px;
}

.export_btn {
  display: flex;
  align-items: center;
  color: #4c8dff;
  font-size: 13px;
  margin-right: 20px;
  cursor: pointer;
  background: none;
  border: none;
}