@font-face {
  font-family: "Aktiv-grotesk";
  src: local("Aktiv-grotesk"),
    url(./fonts/AktivGroteskCorp-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"),
    url(./fonts/graphik/Graphik-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"),
    url(./fonts/graphik/Graphik-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"),
    url(./fonts/graphik/Graphik-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"),
    url(./fonts/graphik/Graphik-Semibold.otf) format("opentype");
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./fonts/GilroyFont/Gilroy-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Gilroy-Regular;
  src: url(./fonts/GilroyFont/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Gilroy-Medium;
  src: url(./fonts/GilroyFont/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: Gilroy-Light;
  src: url(./fonts/GilroyFont/Gilroy-Light.ttf) format("truetype");
}

@font-face {
  font-family: Gilroy-Bold;
  src: url(./fonts/GilroyFont/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: Gilroy-SemiBold;
  src: url(./fonts/GilroyFont/Gilroy-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: Gilroy-ExtraBold;
  src: url(./fonts/GilroyFont/Gilroy-ExtraBold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: Gilroy-Regular;
}