.stepTabs {
    display: flex;
    font-family: 'Gilroy-Medium';
    width: 65%;
    justify-content: space-between;
  
    .stepStyle {
      display: flex;
      cursor: pointer;
  
      :first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 14px;
        color: #ffffff;
      }
  
      :nth-child(2) {
        margin-left: 10px;
        padding-top: 4px;
      }
  
      .greenColor {
        color: #00BA00;
      }
      .blackColor{
        color:#000000
      }
  
      .greyBackground {
        background-color: #E9E9E9;
      }
  
      .greenBackground {
        background-color: #00BA00;
      }

      &.disabled {
        cursor: not-allowed;
      }
      
    }
    .toggle{
      input[type="checkbox"] {
        margin-left: 10px;
        margin-top:10px;
        display: inline-block;
        position: relative;
        width: 24px;
        height: 12px;
        -webkit-appearance: none;
        appearance: none;
        background: #DF666A;
        outline: none;
        border-radius: 2rem;
        cursor: pointer;
        box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #000000;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.5s;
        }
  
        &:checked::before {
          transform: translateX(100%);
          background: #050000;
        }
  
        &:checked {
          background: #00ed64;
        }
      }
    }
  }
  