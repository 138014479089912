.form16Wrapper{
    position: relative;
    border-radius: 6px;
    background-color: #f9f9f9;
    border: 0.5px solid #d9d9d9;
    color: #4c8dff;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px 12px 15px;
    margin-top: 12px;
    z-index: 5;
    cursor: pointer;
    .docname{
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        &:hover{
            text-decoration: underline;
        }
    }
}