$font-family: 'Gilroy-Semibold', sans-serif;
$font-size-14: 0.85rem;

.paymentInputContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1rem;

    .selectDate {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > label {
            font-size: $font-size-14;
            color: #000;
            font-family: $font-family;
        }
    }
    
    .UtrAndAmountDiv {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }
}