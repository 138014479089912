.ImgStyle {
    width: 29px;
    height: 40px;
    margin-top: -30px;
    margin-bottom: 15px;
}
.ButtonContainer {
    margin-top: 34px;
    .Cancel {
        width: 45%;
        height: 40px;
        border-color:#4C8DFF;
        color:white;
        background-color: white;
        font-size: 15px;
        color: #4C8DFF;
        
    }
    .Delete {
        width: 45%;
        height: 40px;
        background-color: red;
        font-size: 15px;
    }
}
.DescriptionMessage {
    font-family: 'Gilroy-Medium';
    font-weight : 500;
    font-size: 16px;
    line-height: 24px;
    .Bold{
        font-family: 'Gilroy-Bold';
        font-weight: bold;
    }
}

  
  
  
  
  
  