.Status {
    border-radius: 4px;
    font-family: 'Gilroy-SemiBold';
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    width: 100px;
    padding: 4px 8px 4px 8px;
    margin-left: 20px;
}

.VerifiedText {
    color: #49A71B
}

.Verified {
    background-color: #E5FCDE;
}

.NotVerifiedText {
    color: #D6BB0A;

}

.NotVerified {
    background-color: #FFFAD7;
}


.NachText {
    color: #6021B3
}

.Nach {
    background-color: #F5EDFF;
}

.GrayButton {
    background-color: #CCCCCC; /* Use any shade of gray you prefer */
    color: #FFFFFF; /* Text color */
    padding: 5px 10px; /* Adjust padding as needed */
    border: none; /* Remove border */
    border-radius: 4px; /* Add border radius for rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    font-size: 10px; /* Adjust font size as needed */
}

.BlueButton {
    background-color: #4C8DFF; /* Use any shade of gray you prefer */
    color: #FFFFFF; /* Text color */
    padding: 5px 10px; /* Adjust padding as needed */
    border: none; /* Remove border */
    border-radius: 4px; /* Add border radius for rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    font-size: 10px; /* Adjust font size as needed */
}

.toggle{
    input[type="checkbox"] {
      margin-left: 10px;
      margin-top:10px;
      display: inline-block;
      position: relative;
      width: 24px;
      height: 12px;
      -webkit-appearance: none;
      appearance: none;
      background: #D8D8D8;
      outline: none;
      border-radius: 2rem;
      cursor: pointer;
      box-shadow: inset 0 0 5px rgba(143, 143, 143, 0.5);

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #000000;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.5s;
      }

      &:checked::before {
        transform: translateX(100%);
        background: #050000;
      }

      &:checked {
        background: #00ed64;
      }
    }
  }