
td {
    font-family: "Gilroy-SemiBold", sans-serif;
}
.repayments {
  width: 194px;
  font-weight: 400;
  font-size: 10px;
  .topRow {
    display: flex;
    justify-content: space-between;
    .paidAmount {
      color: #00aa00;
    }
    .remainingAmount {
      color: #818181;
    }
  }
  .repaymentBar {
    height: 10px;
    background-color: #eeeeee;
    border-radius: 9px;
    .greenBar {
      border-radius: 9px;
      background-color: #00aa00;
      height: 10px;
    }
  }
}

.OrderTransactionListRow_input_box{
  input {
    border: 1px solid #4C8DFF;
    border-radius: 4px;
    color: #4C8DFF;
    width: 114px;
    height: 28px;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
}

.DelayPaidToggle {
  .disableToggle {
   opacity: 0.45;
  }
  .navTabs {
      height: 28px;
      width: 80px;
      border-radius: 4px;
      background-color: #ededed;
      border: none;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 9px;
      text-align: center;
      align-items: center;

      .navLink {
        color: #7b7b7b;
        text-align: center;
        margin: auto;
        margin-bottom: 0px;
        border: none;
        font-size: 12px;
        padding: 3px;
        transition: 0.2s ease;
        border-top-left-radius: none;
        border-top-right-radius: none;

        &.active {
          height: 22px;
          width: 71px;
          border-radius: 2px;
          background-color: #4c8dff;
          color: #ffffff;
          text-align: center;
          padding: 6px;
          font-size: 9px;
        }
      }
    }
  
    .nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      padding: 14px 2px 5px 6px;
      margin-bottom: 0;
      list-style: none;
    }
    
   
    .navItem {
      height: 22px;
      width: 71px;
      text-align: center;
      padding: 0px 3px;
    }
}

.delayChargeAsterisk:after {
  content: " *";
  color: #ce4a3e;
  font-size: 12px;
}

