.UWQueriesContainer {
    // max-width: 700px;
    width: 95%;
    height: 85%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.0754);
    border-radius: 10px;
    margin-left: 24px;
    .InputContainer {
        padding: 11px 23px;
        background: #FFFFFF;
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
        position: relative;
    
        textarea {
            width: 100%;
            height: 120px;
            background: #FFFFFF;
            border: 1px solid #D2D2D2;
            border-radius: 4px;
            padding: 15px;
        }
    
        .Placeholder {
            position: absolute;
            left: 39px;
            padding: 19px 0;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #BCBCBC;
            letter-spacing: 0.5px;
            user-select: none;
            cursor: text;
            span {
                font-style: italic;
                font-weight: 400;
                font-size: 10px;
                color: #D8D8D8;
            }
        }

        .FileUploadAndShow {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            .Attachment {
                cursor: pointer;
                img {
                    margin-bottom: -2px;
                }
            }
        }
    
        .SubmitButton {
            background: #6021b3;
            border-radius: 4px;
            width: 100%;
            height: 40px;
            font-size: 14px;
            color: #ffffff;
            border: 1px solid #6021b3;
            margin-top: 12px;
            &:disabled {
                background: #979797;
                border: none;
            }
        }
    }

    .CheckBoxAndFileContainer{
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
    }
    
    .CheckBoxPadding{ 
        padding-right: 5px;
    }
    .QueryResolveText,.QueryResolveTextFaded{
        font-family: 'Gilroy-medium';
        font-weight: 600;
        font-size: 12px;
    }
    .QueryResolveTextFaded{
        color: #BCBCBC;
    }
    .ErrorText{
        display: flex;
        justify-content: end;
        color: red;
        font-size: 12px;
    }
}