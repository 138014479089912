$font-family: "Gilroy-Semibold", sans-serif;
$font-size-12: 0.75rem;

.selectContainer {
    position: relative;
    width: 100%;

    .selectList {
      position: absolute;
      z-index: 1000;
      list-style: none;
      padding: 0;
      margin: 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 150px;
      overflow-y: auto;
      background-color: #fff;
      width: 100%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      font-family: $font-family;
      font-size: $font-size-12;
    }

    .selectItem {
      padding: 0.5rem;
      cursor: pointer;
      border-bottom: 1px solid #f0f0f0;

      &:hover {
        font-family: $font-family;
        font-size: $font-size-12;
        background-color: #f0f0f0;
      }
    }
    .notFound {
      padding: 0.5rem;
    }
  }