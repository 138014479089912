.FilesContainer {
    display: flex;
    flex-wrap: wrap;
    .FileContainer {
        margin-left: 8px;
        margin-bottom: 5px;
        background: #6021b3;
        border-radius: 5px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        color: #ffffff;
        padding: 5px 3px 5px 6px;
        display: flex;
        justify-content: space-between;
        .FileName {
            display: inline-block; /* so height and width actually take affect */
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
        }
        .FileActionIcons {
            display: flex;
            .Visible {
                visibility: unset;
            }
            .VisibilityHidden {
                visibility: hidden;
            }
            img {
                scale: 0.9;
                margin-right: -2px;
            }
            .DownloadIcon {
                scale: 0.75;
                cursor: pointer;
            }
        }
    }
}
