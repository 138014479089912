.CollectionsHeaderRight {
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 10px 0 rgba(112, 144, 176, 0.16);
    align-content: flex-start;
    margin-right: 1rem;
    .investorOrgName{
        border: none;
        font-family: Gilroy-Bold;
        font-size: 14px;
        color: #535B62;
        background-color: #fff;
        width: 8rem;
        border-radius: 0.5rem;
        box-shadow: 0 2px 5px 4px rgba(113, 109, 109, 0.13);
        padding: 0.5rem 1rem;
       margin-right: 10px;
        background-repeat: no-repeat;
        background-position-x: 96%;
        background-position-y: 50%;
        background-size: 20px;
        cursor: pointer;
    
        &:focus{
         caret-color: transparent;
        }
    }

    .remaining_only_filter {
        font-size: 12px;
        font-family: Gilroy-SemiBold;
        font-weight: normal;
        display: flex;
        align-items: center;
        margin-left: 10px;

        >span {
            margin-right: 5px;
        }

        label {
            margin: 0;
        }
    }
    .RemainingOnlyFilter{
        font-size: 12px;
        font-family: Gilroy-SemiBold;
        font-weight: normal;
        display: flex;
        align-items: center;
        margin-left: 10px;
    
        >span{
            margin-right: 5px;
        }
        label{
            margin: 0;
        }
    }
}



.HeaderSubPart {
    margin: 0 5px;

    div {
        &:nth-child(1) {
            color: #BDBEBE;
            font-size: 10px;
            margin-bottom: -2.5px;
            font-family: Gilroy-Medium;
        }

        &:nth-child(2) {
            font-size: 15px;
            font-family: Gilroy-Bold;
        }
    }

    .Green {
        color: #16B216;
    }

    .Grey {
        color: #B6B6B5;
        font-size: 10px;
    }

    .Blue {
        color: #0B8ADA;
    }

    .Red {
        color: #DB6513;
    }
}

.CollectionDropdown {
    margin: 0 20px 0 0;
    position: relative;
    color: #565E66;
    font-size: 14px;
    cursor: pointer;

    img {
        width: 11px;
        margin-left: 10px;
    }
}

.CollectionDropdownOther {
    position: absolute;
}

.ProgressBottom {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 12px;
    color: #D5D4D5;
    margin-top: 3px;
    font-family: Gilroy-Bold;
}

.ProgressTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-bottom: 3px;
    font-family: Gilroy-Bold;

    .greenText {
        color: #03AA00;
    }

    .redText {
        color: #D80100;
    }
}

.CollectionProgressBar {
    height: 10px;
    width: 260px;
    background-color: #D80100;
    border-radius: 40px;

    .GreyPart {
        background-color: #EEEEED;
        width: 180px;
        height: 10px;
        border-radius: 40px;

        .GreenPart {
            background-color: #03AA00;
            width: 100px;
            height: 10px;
            border-radius: 40px;
        }
    }
}

.tabs {
    height: 28px;
    opacity: 0.45;
    border-radius: 4px;
    background-color: #ededed;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    opacity: unset;
    padding: 3px;
    margin-bottom: 0.4rem;

    button {
        border: none;
        background: none;
        color: #7b7b7b;
    }

    .btn_style {
        border-radius: 2px;
        background-color: #4c8dff;
        color: #ffffff;
        text-align: center;
        padding: 3px;
    }
}

.header_input {
    position: relative;

    input {
        background: inherit;
        background-image: url('../../../../../assets/search.svg');
        background-repeat: no-repeat;
        background-position: center left;
        text-indent: 20px;
        border: none;

        /* &:focus{
            background-image: none;
        } */
        //setting table column width from here
        &[name="collectionDate"] {
            width: 80px;
        }

        &[name="payment_method"] {
            width: 90px;
        }

        &[name="owner"] {
            width: 80px;
        }

        &[name="dpdBucket"] {
            width: 80px;
        }
    }

    &:first-child {
        display: flex;
        margin-right: 10px;
    }
}

.wrapperStyles {
    div {
        background-color: white;
    }
}

.upper_row_for_filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    margin: 30px 35px;
}

.filter_arr_wrapper {
    font-family: Gilroy-SemiBold;

    span {
        border-radius: 4px;
        font-size: 12px;
        padding: 4px;
        background-color: #E8F0FF;
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }

    .clear_all {
        font-size: 12px;
        color: #2F8FFF;
    }
}

.import_csv_button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 30px;
    width: 7rem;
    height: 1.75rem;
    background-color: #4C8DFF;
    border-radius: 4px;
    gap: 8px;
    cursor: pointer;

    .downloadIcon{
        width: 0.75rem;
        height: 0.5rem;
    }

    .exportText{
        color: #FFFFFF;
        font-size: 10px;
        font-weight: 600;
    }
}

.tabsWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .betaSwitch {
        display: flex;
        align-items: center;
        padding: 6px 0 0 5px;

        span {
            font-size: 12px;
            padding-right: 4px;
        }

        label {
            margin: 0;
        }
    }
}

.tableWrapper {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 0px;
    overflow-y: scroll;
    height: 60%;
}

.tableWrapper>div:first-child {
    border-radius: 6px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
    display: block;
    position: relative;
    overflow: scroll;
    scrollbar-color: rgb(168, 168, 168) #f1f1f1;
    scrollbar-width: thin;
    height: calc(100vh - 170px);
    z-index: 1;
}

.companyNameWrap {
    font-family: 'Gilroy';
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
    color: #2F8FFF;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 11px;
}

.progressTop {
    width: 280px;
    margin: 0 2rem;

    .progressBar {
        width: 100%;
        height: 12px;
        border-radius: 20px;
        background-color: #D80000;

        .filledBar {
            background-color: #00AA00;
            height: 100%;
            max-width: 100% !important;
            border-radius: 20px;
        }
    }
}

.progressValues {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.greenTextValues {
    color: #00AA00;
    font-weight: 400;
    font-size: 10px;
    margin: 0;
}

.redTextValues {
    color: #D80000;
    font-weight: 400;
    font-size: 10px;
    margin: 0;
}

.collectedGraphWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    width: 170px;

    .upperAmount {
        font-weight: 600;
        font-size: 10px;
        margin-bottom: 0.2rem;

        span {
            font-weight: 400;
            color: #00AA00;
        }

    }

    .lowerAmount {
        font-weight: 500;
        font-size: 10px;
        color: #979797;
        margin: 0.2rem 0 0;
        font-family: 'Gilroy';
    }

    .graph {
        display: flex;
        width: 100%;
        background-color: #ffffff;
        border-radius: 20px;
        border: 1px solid #E1E1E1;
        height: 10px;

        .filledGraph {
            background-color: #00AA00;
            border-radius: inherit;
            height: 100%;
        }
    }
}

.dueAmountWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    .upperAmount {
        font-family: 'Gilroy-Bold';
        font-size: 12px;
        color: #D80000;
        margin: 0;
    }

    .lowerAmount {
        font-family: 'Gilroy';
        font-weight: 500;
        font-size: 10px;
        color: #979797;
        margin: 0;
    }
}

.dpdBucketWrap {
    color: #000000;
    font-weight: 600;
    font-size: 12px;
    margin: 0 0 16px;
}

.portfolioWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    color: #979797;
    flex-direction: column;

    .upperAmount {
        font-family: 'Gilroy';
        font-weight: 600;
        margin: 0;
        font-size: 12px;
    }

    .lowerAmount {
        font-family: 'Gilroy';
        font-weight: 400;
        margin: 0;
        font-size: 10px;
    }
}

.ptpDateWrapper {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    margin-bottom: 11px;

    .ptpDateWrap {
        font-weight: 600;
        font-size: 12px;
        color: #979797;
        margin: 0;
        font-family: 'Gilroy';
    }
}

.ownerWrapper {
    color: #000000;
    background-color: #F6F6F6;
    padding: 8px;
    border-radius: 8px;
    width: 100px;
    border: unset;
    font-weight: 500;
    font-size: 10px;
    select{
        border: none;
        background-color: #F6F6F6;
        width: 100%;
    }
}

.lastActionWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    color: #979797;
    flex-direction: column;

    .upperAmount {
        font-weight: 600;
        margin: 0;

    }

    .lowerAmount {
        font-weight: 400;
        margin: 0;
        font-family: 'Gilroy';
    }
}

.notesWrapper {
    height: 80px;
    width: 170px;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: 10px 12px;
    overflow-y: scroll;

    .commentsWrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        column-gap: 0.1rem;
        margin-bottom: 0.3rem;

        .timeStamp {
            font-weight: 500;
            font-size: 8px;
            color: #C5C5C5;
            margin: 0;
            font-family: 'Gilroy';
        }

        .comment {
            font-weight: 500;
            font-size: 10px;
            color: #000000;
            margin: 0;
            font-family: 'Gilroy';
            white-space: pre-wrap;
        }
    }

}

.tablebreakdown {
    box-shadow: 0px 0px 16px 5px #0000000C;
    padding: 0 !important;
    display: none;
    visibility: hidden;
    z-index: 9999999;
    position: fixed;
}

.tablebreakdown::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 11px 11px;
    /* Adjusted border widths */
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    left: 10%;
    /* Position at the middle horizontally */
    transform: translateX(-50%);
    /* Center horizontally */
    top: -9px;
    /* Position at the top */
}

.notesTextArea {
    height: 80px;
    width: 100%;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: 10px 12px;
    border: unset;
}

.recoverViewDrawer {
    padding: 10px 14px;

    .drawerHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-weight: 600;
            margin: 0;
            color: #000000;
            font-size: 24px;
        }

        img {
            cursor: pointer;
        }
    }

    .notesDrawerWrapper {
        margin-top: 2rem;

        .notesHeaderWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .addedNotes {
                font-weight: 700;
                margin: 0;
                color: #000000;
                font-size: 18px;
            }

            .addNotesCTAWrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 2rem;

                .ownerInfo {
                    font-weight: 600;
                    margin: 0;
                    font-family: 'Gilroy';
                    color: #000000;
                    font-size: 10px;
                }

                .addNotesCTA {
                    color: #ffffff;
                    background-color: #4C8DFF;
                    border-radius: 8px;
                    font-weight: 600;
                    margin: 0;
                    font-family: 'Gilroy';
                    font-size: 10px;
                    padding: 8px 16px;
                    cursor: pointer;
                    border: none;
                    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
                }
            }
        }

        .commentBox {
            height: 280px;
            width: 100%;
            background-color: #F6F6F6;
            border-radius: 10px;
            padding: 10px 12px;
            border: unset;
            margin-top: 1.5rem;
            overflow-y: scroll;

            .commentsWrapper {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                column-gap: 0.1rem;
                margin-bottom: 0.3rem;

                .timeStamp {
                    font-weight: 500;
                    font-size: 8px;
                    color: #C5C5C5;
                    margin: 0;
                    font-family: 'Gilroy';
                }

                .comment {
                    font-weight: 500;
                    font-size: 10px;
                    color: #000000;
                    margin: 0;
                    font-family: 'Gilroy';
                }
            }
        }
    }

    .otherDetailsWrapper {
        margin-top: 2rem;

        .headerText {
            font-weight: 700;
            margin: 0;
            color: #000000;
            font-size: 18px;
        }

        .otherDetailsBox {
            width: 100%;
            border-radius: 6px;
            border: 1px solid #EAECF0;
            margin-top: 1rem;
            padding: 12px;

            .officeAddress {
                .heading {
                    font-weight: 600;
                    margin: 0;
                    font-family: 'Gilroy';
                    color: #9D9D9D;
                    font-size: 12px;
                }

                .address {
                    font-weight: 600;
                    margin: 0;
                    font-family: 'Gilroy';
                    color: #1C1C1C;
                    font-size: 16px;
                }
            }

            .directorWrapper {
                display: flex;
                margin-top: 1rem;
                align-items: center;
                justify-content: flex-start;
                column-gap: 1.5rem;

                .wrapper {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: column;

                    p {
                        font-weight: 600;
                        margin: 0;
                        font-family: 'Gilroy';
                        color: #9D9D9D;
                        font-size: 12px;
                    }

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        column-gap: 0.2rem;

                        p {
                            font-weight: 600;
                            margin: 0;
                            font-family: 'Gilroy';
                            color: #2F8FFF;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .editCommentWrapper {
        margin-top: 2rem;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-weight: 700;
                margin: 0;
                color: #000000;
                font-size: 18px;
            }

            .ctaWrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 0.4rem;

                .cancle {
                    color: #2F8FFF;
                    border-radius: 8px;
                    font-weight: 600;
                    margin: 0;
                    font-family: 'Gilroy';
                    font-size: 10px;
                    padding: 8px 16px;
                    cursor: pointer;
                    border: 1px solid #2F8FFF;
                    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
                    background-color: #ffffff;
                }

                .save {
                    color: #ffffff;
                    background-color: #4C8DFF;
                    border-radius: 8px;
                    font-weight: 600;
                    margin: 0;
                    font-family: 'Gilroy';
                    font-size: 10px;
                    padding: 8px 16px;
                    cursor: pointer;
                    border: none;
                    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
                }
            }
        }

        .filtersWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 1.5rem;
            margin-top: 1rem;

            .filterWrap {
                display: flex;
                flex-direction: column;
                row-gap: 0.2rem;

                label {
                    font-weight: 500;
                    margin: 0;
                    color: #000000;
                    font-family: 'Gilroy', sans-serif;
                    font-size: 8px;
                }

                .selectaction {
                    padding: 8px;
                    font-size: 12px;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    background-color: #fff;
                    color: #000;
                    cursor: pointer;
                    width: 220px;
                    height: 38px;
                    font-family: 'Gilroy', sans-serif;
                    appearance: none;
                    -webkit-appearance: none;
                    background-image: url('../../../../../../public/assets/expand-more.svg');
                    background-repeat: no-repeat;
                    /* Prevent background image from repeating */
                    background-position: right 8px center;
                    background-size: 26px;
                    /* Adjust size of the background image */
                    padding-right: 30px;
                    /* Add extra padding to accommodate the icon */
                }

                // .dateInput {
                //     padding: 8px;
                //     font-size: 12px;
                //     border: 1px solid #ccc;
                //     border-radius: 8px;
                //     background-color: #fff;
                //     color: #000;
                //     cursor: pointer;
                //     width: 220px;
                //     height: 38px;
                //     font-family: 'Gilroy', sans-serif;
                // }
                input[type=date] {
                    &.dateInput {
                        padding: 8px;
                        font-size: 12px;
                        border: 1px solid #ccc;
                        border-radius: 8px;
                        background-color: #fff;
                        color: #000;
                        cursor: pointer;
                        width: 220px;
                        height: 38px;
                        font-family: 'Gilroy', sans-serif;
                
                        // Apply styles for the required and invalid state
                        &:required:invalid::-webkit-datetime-edit {
                            color: transparent;
                        }
                
                        // Apply styles when the input is focused
                        &:focus::-webkit-datetime-edit {
                            color: black !important;
                        }
                    }
                }
            }
        }

        .editTextArea {
            width: 100%;
            height: 280px;
            background-color: #fff;
            border-radius: 10px;
            padding: 10px 12px;
            border: 1px solid #BEBEBE;
            margin-top: 1rem;
            resize: none;
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
            /* Inner shadow effect */
        }

    }
}

.nodata {
    font-size: 16px !important;
    font-weight: bolder !important;
    text-align: center;
}

.ptpTooltipWrapper {

    // padding: 6px;
    .heading {
        color: #000000;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Gilroy', sans-serif;
        margin: 0 0 0.4rem;
    }

    .ptpDates {
        color: #999999;
        font-size: 10px;
        font-weight: 600;
        font-family: 'Gilroy', sans-serif;
        margin: 0;
        margin-bottom: 8px;
    }
}

.ptpTooltip {
    padding: 8px 8px 0px !important;
    border: unset !important;
    box-shadow: 0px 0px 16px 5px #0000000C;
}

.ptpTooltip::after, .ptpTooltip::before {
    border-top-color: white !important; /* Change this to your desired color */
    border-bottom-color: white !important; /* Change this to your desired color */
  }