.DataIntegration {
    padding: 0 0 0 2px;
    margin-top: 25px;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: visible;

    .Title {
        margin-bottom: 10px;
        position: sticky;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .Icon{
            height: 15px;
            margin-left: 6px;
        }

        &>img {
            margin-left: 10px;
            margin-bottom: 2px;
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }
        }

    }

    &::-webkit-scrollbar {
        width: 19px;
        scroll-padding-right: 100px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        margin: 48px 0 5px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #6a6a6a;
        border-radius: 10px;
        border: 6px solid #ffffff;
    }

    .Top {
        background: #f9fafc;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        width: fit-content;

        display: flex;
        align-items: center;

        .Titles {
            position: sticky;
            left: 0;
            min-width: 366px;
            padding: 14px 40px;
            background: #ffffff;
            box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.15);
            border-radius: 10px;

            font-family: 'Gilroy-SemiBold', sans-serif;
            font-size: 14px;
            line-height: 20px;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .Months {
            display: flex;
            align-items: center;

            .Month {
                width: 90px;
                // padding: 8px 20px;
                white-space: nowrap;
                font-family: 'Gilroy-SemiBold', sans-serif;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                text-align: center;

                display: flex;
                align-items: center;
                justify-content: center;


                &>img {
                    margin-left: 5px;
                    margin-bottom: 2px;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .CollapseHead {
        margin: 30px 0 2px 0;
        display: flex;
        align-items: center;
        background: #fcfcfc;
        width: fit-content;

        .Title {
            position: sticky;
            left: 0;
            min-width: 366px;
            padding: 30px 30px;
            background: #ffffff;
            box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.15);
            // clip-path: inset(0 -10px 0 0);
            border-radius: 10px;
            cursor: pointer;

            font-family: 'Gilroy-SemiBold', sans-serif;
            font-size: 18px;
            line-height: 21px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            &>div {
                display: flex;
                align-items: center;

                &>img {
                    margin: 0 0 0px 5px;
                    transition: all 150ms linear;
                }
            }
        }

        .Data {
            display: flex;
            align-items: center;

            .DataItem {
                width: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .CollapseBody {
        max-height: 0;
        overflow: hidden;
        transition: all 300ms ease-in;

        .CollapseBodyItem {
            background: #ffffff;
            display: flex;
            align-items: center;
            width: fit-content;
            border-radius: 8px;
            // overflow: hidden;

            .Title {
                position: sticky;
                left: 0;
                min-width: 366px;
                background: #ffffff;
                box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.15);
                width: 366px;
                padding: 30px 30px 30px 50px;

                font-family: 'Gilroy-SemiBold', sans-serif;
                font-size: 15px;
                line-height: 18px;

                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .TitleFirst {
                border-radius: 10px 10px 0px 0px;
                clip-path: inset(0 -10px 0 -10px);
            }

            .TitleMid {
                border-radius: 0px;
                clip-path: inset(0 -10px);
            }

            .TitleLast {
                border-radius: 0px 0px 10px 10px;
                clip-path: inset(0 -10px -10px -10px);
            }

            .Data {
                display: flex;
                align-items: center;

                .DataItem {
                    width: 90px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .Expand {
        max-height: fit-content;
        overflow: visible;
        animation: expand 300ms;
    }
}

@keyframes expand {
    0% {
        overflow: hidden;
    }

    90% {
        overflow: hidden;
    }

    100% {
        overflow: visible;
    }
}

.DownloadImg {
    margin-left: 40px !important;
    transition: none !important;

    &:hover {
        transform: scale(1.2);
    }
}

.DownloadAllButton {
    border-radius: 4px;
    border: 1px solid #4c8cff;
    box-sizing: border-box;
    height: 30px;
    min-width: 54px;
    opacity: 2;
    color: #4c8cff;
    font-weight: 500;
    font-size: 12px;
   
    margin-right: 5px;
    background-color: white;
    
    .DivClass{
        display: flex;
        .DownloadAllIcon{
            margin:0px 5px 0px 3px;
        }
        .DownloadAllSpinner{
            margin:0px 3px 0px 1px;
        }
    }
  }