.AdvanceFilterContainer{
  margin-left: 3px;
  border: none;
  font-family: Gilroy-Bold, serif;
  font-size: 0.65rem;
  display: flex;
  align-items: center;
  color: #535B62 !important;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 4px 3px rgba(113, 109, 109, 0.13);
  padding: 4px 4px;
  cursor: pointer;
  height: 2rem;

  option {
    color: #535B62 !important;
    font-family: Gilroy-Bold, serif;
  }

  select {
    color: #535B62 !important;
    font-family: Gilroy-Bold, serif;
  }
}

.FilterSelect{
  width: 7rem;
  border: none;
  padding: 2px 8px;
}