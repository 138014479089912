.EmailUserBtn {
    max-width: 140px;
    border-radius: 4px;
    background-color: #00bd00;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    border: none;
    margin-right: 30px;
    padding: 6.5px 0;
    img {
        width: 14px;
        margin-right: 5px;
    }
    &:disabled {
        background-color: #c7c7c7;
    }
}

.ModerateBtn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 4px;
    background-color: #4C8DFF;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    border: none;
    padding: 7px 24px;
}
.totExpiryDatePicker{
    width: 6.18rem;
    height: 1.75rem;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
    border: 1px solid #4C8DFF;
    padding: 0.5rem;
    font-size:0.625rem;
    color:#4C8DFF;
}
.totExpiryDatePicker::-webkit-inner-spin-button,
.totExpiryDatePicker::-webkit-calendar-picker-indicator{
    display: none;
    -webkit-appearance: none;
}
.disabledDatePicker{
    border: 1px solid #c8c8c8;
    color: #c8c8c8;
}
.DatePickerContainer{
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    margin-right: 3rem;
}
.ActiveTillDate{
    font-size: 0.625rem; 
    font-family: 'Gilroy-Semibold', serif; 
    margin: 0;
}
.disabledFontStyle{
    color:#c8c8c8;
}