.rejectionReasonContainer {
    position: relative;
    font-family: "Gilroy-Medium";
    font-size: 10px;
  
    .disabledSelect {
        border: 0.5px solid #E4E4E4;
        border-radius: 4px;
        background-color: #F9F9F9;
        color: #989898;
        padding: 5px;
        width: 120px;
    }
  
    .enabledSelect {
        display: flex;
        justify-content: space-between;
        border: 0.5px solid #4C8DFF;
        border-radius: 4px;
        background-color: #FFFFFF;
        padding: 5px;
        width: 120px;
        cursor: pointer;
  
        .placeholderText {
            color: #989898;
        }
  
        .normalText {
            color: #000000;
        }
  
        .transformArrow {
            transform: rotate(180deg);
        }
    }
  
    .optionsContainer {
        position: absolute;
        max-width: 120px;
        z-index: 2;
        padding: 5px 0;
        box-shadow: 0px 4px 8px rgb(0 0 0 / 15%);
        border: 1px solid #E4E4E4;
        background-color: #ffffff;
        border-radius: 2px;
        margin-top: 2px;
        font-family: "Gilroy-Regular";
  
        .singleOptionContainer {
            display: flex;
            align-items: flex-start;
            padding: 2px 5px;
            cursor: pointer;
  
            &:hover {
                background-color: #E9f3ff;
            }
  
            input {
                width: 10px;
                margin-right: 5px;
            }
        }
    }
  }
  button {
    &:disabled {
      opacity: 0.5;
    }
  }
  
  table {
    input {
      &[type="checkbox"] {
        width: 19px;
      }
    }
  }

.saveClose {
    height: 12px;
    width: 88px;
    color: #C0C0C0;
    font-family: Gilroy-Medium;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
}

.SaveRowText{
  font-family: 'Gilroy';
  font-style: normal;
  line-height: 36px;
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600px;
}

.HeaderAlignment{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 70px;
}

.LightBlackBorder{
  border: 1px solid #DCDCDC;
  margin-bottom: 30px;
  margin-top: 20px;
}

.crossBtn{
  opacity: 0.4;
}

.TableHeight{
  max-height: 90%;
}

.BodyTableHeight{
  height:calc(95% - 70px);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.UploadAll {
  height: 100%;
  width: 100%;
  margin-right: 10px;
}

.UploadImg {
  border: 1px solid #d2d2d2;
  color: #B8B8B8;
  cursor: pointer;
  height:100%;
}

.UWQueriesContainer {
  width: 100%;
  height: 85%;
  background: #FFFFFF;
  border-radius: 10px;
  .InputContainer {
      padding: 11px 23px;
      background: #FFFFFF;
      border-radius: 0px 0px 10px 10px;
      position: relative;
  
      textarea {
          width: 100%;
          height: 120px;
          background: #FFFFFF;
          border: 1px solid #D2D2D2;
          border-radius: 4px;
          padding: 15px;
      }
  
      .Placeholder {
          position: absolute;
          left: 39px;
          padding: 19px 0;
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #BCBCBC;
          letter-spacing: 0.5px;
          user-select: none;
          cursor: text;
          span {
              font-style: italic;
              font-weight: 400;
              font-size: 10px;
              color: #D8D8D8;
          }
      }

      .FileUploadAndShow {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          .Attachment {
              cursor: pointer;
              img {
                  margin-bottom: -2px;
              }
          }
      }
  
      .SubmitButton {
          background: #6021b3;
          border-radius: 4px;
          width: 100%;
          height: 40px;
          font-size: 14px;
          color: #ffffff;
          border: 1px solid #6021b3;
          margin-top: 12px;
          &:disabled {
              background: #979797;
              border: none;
          }
      }
  }
}
.uploadCTAText{
  margin: 0;
  color: #2F8FFF;
  span{
    color: #979797;
  }
}
.fileErrorMsg{
  color:#DF666A;
  font-weight: 500;
  font-family: 'Gilroy';
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin: 10px 0 0 0;
}