.nach-table-status {
  width: 100%;
  float: left;
  padding: 4px 0;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  max-width: 110px;
  border-radius: 4px;
}
.Scheduled-nbg {
  color: #4c8dff;
  background-color: #e4eeff;
}
.Expired-nbg {
  color: #989898;
  background-color: #f4f4f4;
}
.Executed-nbg {
  color: #d6bb0a;
  background-color: #fffad7;
}
.Bounced-nbg {
  color: #d06d6d;
  background-color: #fbeeee;
}
.Successful-nbg {
  color: #49a71b;
  background-color: #e5fcde;
}
.Open-nbg {
  color: #14213d;
  background-color: #c9ae83;
}
.No-Nach-nbg {
  color: #ade8f4;
  background-color: #b20600;
}

.Scheduled-btn {
  height: 40px;
  width: 112px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  margin-right: 15px;
  background-color: #279c13;
}
