.detailsContainer {
  input {
    border: 0.5px solid #d2d2d2;
    border-radius: 4px;
    background-color: #ffffff;
    height: 65px;
    width: 100%;
    margin-top: 2px;
    font-weight: 500 !important;
    font-size: 10px;
    padding: 10px;
    color: #000000;

    &:disabled {
      background-color: #f1f1f1;
      border: none;
      color: #adadad;
    }
  }
}
