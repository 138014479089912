.BalanceConfirmationWrapper {
  .DrawerHeading {
    padding: 1.5rem;
    border-bottom: 1px solid #DCDCDC;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .HeadingText {
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Gilroy-SemiBold, sans-serif;
      font-size: 1.5rem;
      font-style: normal;
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    .StatusBanner {
      font-family: Gilroy-Semibold, serif;
      display: flex;
      justify-content: center;
      font-size: 0.625rem;
      align-items: center;
      padding: 0.5rem;
      border-radius: 4px;
    }

    .CrossIcon {
      cursor: pointer;
    }
  }
}

.QuestionContainer {
  margin: 0 0 2rem 0;
  padding: 0 1.5rem;
  p {
    font-family: Gilroy-Semibold, serif;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  .ButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
  .PillButton {
    border-radius: 1.75rem;
    border: 1px solid #2f8fff;
    background: white;
    color: #2f8fff;
    font-family: Gilroy-medium, serif;
    display: flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    font-size: 0.875rem;
    align-items: center;
  }
  .SelectedPill {
    @extend .PillButton;
    background: rgba(47, 143, 255, 0.2);
    border: 1px solid rgba(47, 143, 255, 0.2);
  }
  .InputWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .InputContainer {
    display: flex;
    align-items: center;
    gap: 0.38rem;
    label {
      margin: 0;
      color: #000;
      font-family: Gilroy-semibold, serif;
      font-size: 0.75rem;
    }
  }
  .DateInput {
    margin-top: 0.5rem;
    border-radius: 0.375rem;
    border: 0.5px solid #bfbfbf;
    color: #000;
    width: 45%;
    font-family: Gilroy, serif;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    background: #fff;
  }
  .DrawdownContainer {
    margin-top: 2rem;
  }
  .DrawDownListContainer {
    border-radius: 0.5rem;
    border: none;
    overflow-y: auto;
  }
  .DrawdownItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    padding: 1rem 1rem;
    font-family: Gilroy-Semibold, serif;
    font-size: 0.85938rem;
    font-style: normal;
    color: #2F8FFF;
    border-bottom: 1px solid #e5e5e5;
    label {
        margin: 0;
        display: flex;
        align-items: center;
    }
    &:hover {
      background: #fff;
    }

    &.noBorderBottom{
      border: none;
    }
}
}
.TdsDrawerFooter {
  margin-top: auto;
  .FooterButtonsContainer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
    border-top: 1px solid #b7b7b7;
    background: #fff;
    gap: 0.5rem;
    .FooterRejectBtn {
      border-radius: 0.375rem;
      border: 1px solid #2f8fff;
      color: #2f8fff;
      font-family: Gilroy, serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      background: #fff;
    }
    .FooterSendReminderBtn {
      border: 1px solid #2f8fff;
      color: white;
      font-family: Gilroy, serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem;
      background: #2f8fff;
      &:disabled {
        background: #b7b7b7;
        border: 1px solid #b7b7b7;
        color: #fff;
      }
    }
    .FooterResolveBtn {
      border: 1px solid #6021b3;
      color: white;
      font-family: Gilroy, serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem;
      background: #6021b3;
      &:disabled {
        background: #b7b7b7;
        border: 1px solid #b7b7b7;
        color: #fff;
      }
    }

    .FooterCloseBtn {
      border: 1px solid #6021b3;
      color: #6021b3;
      font-family: Gilroy, serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.375rem;
      background: white;
    }
  }
}

.detailsContainer {
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  background: #f9f9f9;
  p {
    margin: 0;
    font-family: Gilroy-semibold, serif;
    font-size: 0.875rem;
    color: #000;
  }
  .ViewOnlyTextContainer {
    p {
      margin: 0;
      font-family: Gilroy-semibold, serif;
      font-size: 0.875rem;
      color: #000;
      span {
        font-family: Gilroy, serif;
        color: #7f7f7f;
      }
    }
  }
  .DrawdownsContainer {
    border-radius: 0.5rem;
    background: #fff;
    .DrawdownItems {
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        border-bottom: 0.5px solid #bcbcbc;
        span {
            color: #7f7f7f;
            font-family: Gilroy-Medium, serif;
            font-size: 0.75rem;
            margin-left: 5px;
        }
    }
  }
}
.AttachmentContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .AttachmentItem {
    color: #2f8fff;
    padding: 1rem;
    border-radius: 0.5rem;
    background: #f9f9f9;
    font-family: Gilroy-Semibold, serif;
    font-size: 0.85938rem;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.UploadContainer {
  margin-top: 2rem;
}

.TextArea {

  padding: 0.5rem 0 2rem;

  textarea {
      width: 100%;
      padding: 0.75rem;
      gap: 10rem;
      border-radius: 0.25rem;
      border: 1px solid #ffff;
      resize: none;
      overflow-y: auto;
      height: 7rem;
      background: #f9f9f9;
      color: #979797;
      font-size: 0.75rem;
  }
}
