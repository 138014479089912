.additional_info_btn{
    margin-top: 5px;
    background-color: #ffff;
    border:2px solid #4C8DFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    font-size: 12px;
    color: #4C8DFF;
    &:disabled{
        cursor: no-drop;
    }
}
.dropdownWrapper{
    width: 30%;
    display: flex;
    flex-direction: column;
}
.addInfoSelect{
    margin-bottom: 5px;
    border: 0.5px solid #D2D2D2;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 25px;
    width: 100%;
    margin-top: 2px;
    font-weight: 500 !important;
    font-size: 10px;
    padding: 0px 10px;
    color: #ADADAD;
    &:focus, &:valid{
        border: 0.5px solid #4C8DFF;
        color: #000000;
    }
}
.addnInfoInput{
    border: 1px solid #D2D2D2;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 25px;
    width: 100%;
    margin-top: 2px;
    font-weight: 500 !important;
    font-size: 10px;
    padding: 12px;
    color: #ADADAD;
    &:focus{
        border: 1px solid #4C8DFF;
        color: #000000;
    }
}
.remove_btn{
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: none;
    font-size: 12px;
    color:#DF666A;
    &:disabled{
        cursor: no-drop;
    }
}
.query_container{
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.commentInput{
    @extend .addnInfoInput;
    height: 40px;
    width: 100%;
    margin-bottom: 5px;
}
.addnInfoContainer{
    padding: 10px;
    background-color: #F1F1F1;
    border-radius: 0 0 10px 10px;
}
.toggleContainer {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    font-family: gilroy-semibold, sans-serif;
    font-size: 0.7rem;
    white-space: nowrap;
    gap: 1rem;
}