/* Built-in css Starts */
.MuiTab-root {
  text-transform: unset !important;
  font-family: "Graphik" !important;
}

.PrivateTabIndicator-root-3 {
  min-width: 127px !important;
  max-width: 127px !important;
  display: none;
}

.makeStyles-indicator-2 {
  height: 5px !important;
  background: grey;
  display: none;
}

/* Built-in css Ends */

/* Custom Css Starts */
/* Admin Header css Starts */
.user-logo {
  width: 20px;
}

.user-logo-btn {
  background-color: transparent;
  border: none;
  color: #c4c4c4;
  font-weight: 300;
  font-size: 14px;
  text-align: left;
  margin: 15px 0 25px 0px;
  padding-left: 25px;
}

.admin-recur-logo {
  width: 120px;
  margin-bottom: 25px;
  margin-top: 40px;
  margin-left: 25px;
}

.blk-clr {
  background-color: #1f1f1f !important;
}

.heit {
  height: 79px;
}

/* Admin Header css Ends */

#admin {
  font-family: "Graphik" !important;
}

#admin-company {
  padding: 20px;
  font-family: "Graphik" !important;
  width: 100%;
}

/* Custom Css Ends */

/* Status css starts */
.Approved-status {
  color: #009f00;
}

.Auto-Approved-status {
  color: #5cda5c;
}

.Rejected-status,
.Not-Approved-status {
  color: #df0000;
}

.Open-status {
  color: #135e9c;
}

/* Status css ends */

/* Companies Page css Starts */
.has-search .form-control {
  padding-left: 2rem;
  width: 344px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  height: 40px;
  opacity: 0.4;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.admin-company-btn {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  height: 40px;
  width: 118px;
  background-color: #000000;
  border: 1px solid #979797;
  border-radius: 6px;
}

.admin-company-btn img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

/* Companies Page css Ends */

/* Trades Page css Starts */
.app-btn {
  height: 30px;
  border-radius: 6px;
  background-color: transparent;
  margin-right: 20px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
}

.btn-approve {
  width: 120px;
  border: none;
  background-color: #279c13;
  color: #ffffff;
}

.btn-approve img {
  height: 14px;
  width: 18px;
  margin-right: 10px;
}

.btn-approve-nopayment {
  width: 124px;
  border: none;
  background-color: #1c67a2;
  color: #ffffff;
}

.btn-approve-nopayment img {
  height: 14px;
  width: 18px;
  margin-right: 10px;
}

.btn-no-approve {
  width: 149px;
  background-color: #980d0d;
  color: #ffffff;
  border: none;
}

.btn-no-approve img {
  height: 14px;
  width: 14px;
  margin-right: 10px;
  margin-top: 0px;
}

.btn-hold {
  width: 109px;
  background-color: #9f9f9f;
  color: #ffffff;
  border: none;
}

.btn-hold img {
  height: 17px;
  width: 18px;
  margin-right: 10px;
  margin-top: 0px;
}

.trade-order .nav-tabs {
  height: 28px;
  width: 148px;
  opacity: 0.45;
  border-radius: 4px;
  background-color: #ededed;
  border: none;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9px;
  text-align: center;
  align-items: center;
  opacity: unset;
}

.trade-order .nav-item {
  height: 22px;
  width: 71px;
  text-align: center;
  padding: 0px 3px;
}

.trade-order .nav-tabs .nav-link {
  color: #7b7b7b;
  text-align: center;
  margin: auto;
  margin-bottom: 0px;
  border: none;
  font-size: 12px;
  padding: 3px;
  transition: 0.2s ease;
  border-top-left-radius: none;
  border-top-right-radius: none;
}

.trade-order .nav-tabs .nav-link.active {
  height: 22px;
  width: 71px;
  border-radius: 2px;
  background-color: #4c8dff;
  color: #ffffff;
  text-align: center;
  padding: 3px;
  font-size: 12px;
}

/* Trades Page css Ends */

.request-data-table {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 0px;
  margin: 25px 0px;
}

.admin-contract-head tr th:nth-child(1) {
  padding-left: 5px;
}

.admin-contract-head tr th {
  font-size: 10px;
}

.admin-contract-head tr th:nth-child(4) {
  width: 10%;
}

.admin-clubaccess tr th:nth-child(1) {
  padding-left: 25px;
}

.admin-help tr th:nth-child(1) {
  padding-left: 47px;
}

/* Contract Detail Page starts */
.admin-contract-table {
  border: 1px solid #d9d9d9;
  border-spacing: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-collapse: unset;
  overflow: hidden;
  color: #878787 !important;
}

.admin-contract-table thead tr th,
.admin-contract-table tbody tr td {
  font-weight: 300 !important;
}

.admin-contract-table thead {
  background-color: #e1e1e1;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 10px;
}

.admin-contract-table thead tr th:nth-child(1),
.admin-contract-table tbody tr td:nth-child(1) {
  padding-left: 25px;
}

.admin-contract-table tbody {
  background-color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}

.contract-detail-row2 {
  padding: 20px 20px 35px 20px;
}

.pay-schedule-table {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-top: 20px;
  border-spacing: 0px;
  overflow: hidden;
  font-size: 12px;
}

.contract-BR {
  border-right: 1px solid #979797;
}

.contract-BB {
  border-bottom: 1px solid #979797 !important;
}

.contract-bold {
  font-weight: 500 !important;
}

.pay-schedule-table thead {
  background-color: #f1f1f1;
}

.pay-schedule-table thead:nth-child(2) tr th {
  color: #878787 !important;
  font-weight: 300;
}

.admin-contract-table thead tr th:after,
.pay-schedule-table thead tr th:after {
  border-bottom: 0px solid #ffffff !important;
}

.pay-schedule-table tbody {
  background-color: #f4f4f4;
  color: #878787 !important;
}

.contract-table-type {
  font-size: 14px;
}

/* Contract Detail Page ends */

/* withdraw css starts */
.ht71px {
  height: 71px;
}

/* withdraw css ends */

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #ccc;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 13px;
}

/* Table columns width starts */
.company-table tr th:nth-child(1) {
  width: 1%;
}

.company-table tr th:nth-child(2) {
  width: 15%;
}

.company-table tr th:nth-child(7) {
  width: 8%;
}

.company-table tr th:nth-child(8) {
  width: 14%;
}

.company-table tr th:nth-child(9) {
  width: 5%;
}

.company-table tr th:nth-child(10) {
  width: 11%;
}

.company-table tr th:nth-child(4) {
  width: 10%;
}

.company-table tr th:nth-child(3),
.company-table tr th:nth-child(5),
.company-table tr th:nth-child(6),
.company-table tr th:nth-child(10) {
  width: 12%;
}

.nachcollection-table tr th:nth-child(1),
.nachcollection-table tr th:nth-child(2),
.nachcollection-table tr th:nth-child(3),
.nachcollection-table tr th:nth-child(4),
.nachcollection-table tr th:nth-child(5),
.nachcollection-table tr th:nth-child(6) {
  width: 12%;
}

.nachcollection-table tr th:nth-child(7) {
  width: 10%;
}

.investor-table tr th:nth-child(1) {
  width: 10%;
}

.investor-table tr th:nth-child(4) {
  width: 7%;
}

.investor-table tr th:nth-child(9) {
  width: 11%;
}

.investor-table tr th:nth-child(5),
.investor-table tr th:nth-child(6) {
  width: 7%;
}

.investor-table tr th:nth-child(11) {
  width: 13%;
}

.investor-table tr th:nth-child(2),
.investor-table tr th:nth-child(3),
.investor-table tr th:nth-child(7),
.investor-table tr th:nth-child(8),
.investor-table tr th:nth-child(10) {
  width: 9%;
}

.invoices-table tr th:nth-child(1) {
  width: 19.5%;
}

.invoices-table tr th:nth-child(2) {
  width: 8%;
}

.invoices-table tr th:nth-child(3) {
  width: 7.5%;
}

.invoices-table tr th:nth-child(4) {
  width: 7.5%;
}

.invoices-table tr th:nth-child(5) {
  width: 9%;
}

.invoices-table tr th:nth-child(6) {
  width: 8%;
}

.invoices-table tr th:nth-child(7) {
  width: 10.5%;
}

.invoices-table tr th:nth-child(8) {
  width: 9%;
}

.invoices-table tr th:nth-child(9) {
  width: 7%;
}

.auto-invest-table tr th:nth-child(7) {
  width: 13%;
}

.auto-invest-table tr th:nth-child(1),
.auto-invest-table tr th:nth-child(2),
.auto-invest-table tr th:nth-child(3),
.auto-invest-table tr th:nth-child(4),
.auto-invest-table tr th:nth-child(5) {
  width: 10%;
}

.auto-invest-table tr th:nth-child(6) {
  width: 8%;
}

.auto-invest-table tr th:nth-child(8) {
  width: 8%;
}

.auto-invest-table tr th:nth-child(9) {
  width: 14%;
}

.auto-invest-table tr th:nth-child(10) {
  width: 7%;
}

.order-table tr th:nth-child(3) {
  width: 13%;
}

.order-table tr th:nth-child(4),
.order-table tr th:nth-child(5),
.order-table tr th:nth-child(6),
.order-table tr th:nth-child(7),
.order-table tr th:nth-child(8),
.order-table tr th:nth-child(9) {
  width: 10%;
}

.order-table tr th:nth-child(1),
.order-table tr th:nth-child(10),
.order-table tr th:nth-child(11) {
  width: 4%;
}

.order-table tr th:nth-child(2) {
  width: 15%;
}

.trade-head tr th:nth-child(1) {
  width: 9%;
}

.trade-head tr th:nth-child(3) {
  width: 9%;
}

.trade-head tr th:nth-child(2),
.trade-head tr th:nth-child(4),
.trade-head tr th:nth-child(5),
.trade-head tr th:nth-child(6),
.trade-head tr th:nth-child(7),
.trade-head tr th:nth-child(11) {
  width: 7%;
}

.trade-head tr th:nth-child(9) {
  width: 9%;
}

.trade-head tr th:nth-child(12) {
  width: 4%;
}

.trade-head tr th:nth-child(10) {
  width: 6%;
}

.trade-head tr th:nth-child(13) {
  width: 8%;
}

.trade-head tr th:nth-child(14) {
  width: 2%;
}

.trade-head tr th:nth-child(8) {
  width: 10%;
}

.contract-table tr th:nth-child(1) {
  width: 2%;
}

.contract-table tr th:nth-child(3) {
  width: 9%;
}

.contract-table tr th:nth-child(5),
.contract-table tr th:nth-child(6) {
  width: 8%;
}

.contract-table tr th:nth-child(9) {
  width: 6%;
}

.contract-table tr th:nth-child(10) {
  width: 7%;
}

.contract-table tr th:nth-child(13) {
  width: 6%;
}

.contract-table tr th:nth-child(2),
.contract-table tr th:nth-child(4),
.contract-table tr th:nth-child(7),
.contract-table tr th:nth-child(8),
.contract-table tr th:nth-child(11),
.contract-table tr th:nth-child(14),
.contract-table tr th:nth-child(15) {
  width: 7%;
}

.contract-table tr th:nth-child(12) {
  width: 5%;
}

.repay-table tr th:nth-child(1) {
  width: 13%;
  text-align: center;
}

.repay-table tr th:nth-child(2) {
  width: 10%;
}

.repay-table tr th:nth-child(3) {
  width: 8%;
}

.repay-table tr th:nth-child(4) {
  width: 10%;
}

/* .repay-table tr th:nth-child(3), */
/* .repay-table tr th:nth-child(4), */
.repay-table tr th:nth-child(5),
.repay-table tr th:nth-child(6),
.repay-table tr th:nth-child(7),
.repay-table tr th:nth-child(8),
.repay-table tr th:nth-child(9),
.repay-table tr th:nth-child(10) {
  width: 9%;
}

.repay-table tr th:nth-child(11) {
  width: 10%;
}

.club-table tr th:nth-child(1) {
  width: 15%;
}

.club-table tr th:nth-child(2) {
  width: 7%;
}

.club-table tr th:nth-child(3) {
  width: 7%;
}

.club-table tr th:nth-child(4) {
  width: 6%;
}

.club-table tr th:nth-child(5) {
  width: 11%;
}

.club-table tr th:nth-child(7) {
  width: 11%;
}

.club-table tr th:nth-child(10) {
  width: 7%;
}

.club-table tr th:nth-child(6),
.club-table tr th:nth-child(8),
.club-table tr th:nth-child(9),
.club-table tr th:nth-child(11) {
  width: 9%;
}

.withdraw-table tr th:nth-child(1) {
  width: 11%;
}

.withdraw-table tr th:nth-child(2) {
  width: 13%;
}

.withdraw-table tr th:nth-child(3) {
  width: 10%;
}

.withdraw-table tr th:nth-child(4) {
  width: 13%;
}

.withdraw-table tr th:nth-child(5) {
  width: 10%;
}

.withdraw-table tr th:nth-child(6) {
  width: 13%;
}

.withdraw-table tr th:nth-child(7) {
  width: 10%;
}

.withdraw-table tr th:nth-child(8) {
  width: 10%;
}

.withdraw-table tr th:nth-child(9),
.withdraw-table tr th:nth-child(10) {
  width: 5%;
}

.swap-table tr th:nth-child(1),
.swap-table tr th:nth-child(2),
.swap-table tr th:nth-child(3),
.swap-table tr th:nth-child(4),
.swap-table tr th:nth-child(5),
.swap-table tr th:nth-child(6),
.swap-table tr th:nth-child(9),
.swap-table tr th:nth-child(10) {
  width: 8%;
}

.swap-table tr th:nth-child(7),
.swap-table tr th:nth-child(8) {
  width: 11%;
}

.swap-table tr th:nth-child(11),
.swap-table tr th:nth-child(12) {
  width: 5%;
}

/* Table columns width ends */

/* E-nach-table starts */
.e-nach-table thead tr th {
  padding: 0 10px 10px 10px;
}

.e-nach-table tbody tr td {
  padding: 10px;
}

.e-nach-table tr th::after {
  border: none;
}

.e-nach-table thead tr {
  border-bottom: 1px solid #353cee;
}

.e-nach-table thead tr th {
  font-size: 16px;
  font-weight: 400;
  color: #353cee;
}

.e-nach-table tbody tr td,
.e-nach-table thead tr th {
  border-left: 1px dotted #353cee;
}

.e-nach-table tbody tr td:nth-child(1),
.e-nach-table thead tr th:nth-child(1) {
  border-left: none;
}

/* E-nach-table ends */
.finance-table-head tr th {
  padding: 6px 0px;
  color: #000000;
  font-weight: 500;
  border-spacing: 0px;
  font-family: "Graphik";
}

.finance-table-head tr th:nth-child(1) {
  padding-left: 0px;
}

.finance-table-head tr th::after {
  border: none;
}

.nav-tabs .nav-link {
  font-size: 14px;
  border: none;
  padding: 0px;
  margin-right: 35px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  font-weight: 500;
}

.nav-link.active,
.profile-tabs a:hover {
  color: #000000 !important;
  font-weight: 500;
  text-decoration: none;
}

.nav-link {
  color: lightgray;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 17px;
}

.to {
  height: 12px;
  width: 10px;
  color: #929292;
  font-family: Graphik;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
}

.nav-linkk {
  cursor: pointer;
  font-size: 13px;
  border: none;
  padding: 0px;
  margin-right: 35px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

.nav-tabss {
  display: flex;
  align-items: center;
  width: 60%;
  z-index: 10;
  flex-wrap: nowrap;
}

.nav-item {
  white-space: nowrap;
}

.activeTab {
  color: #000000 !important;
  font-weight: 600;
  text-decoration: none;
  border-bottom: solid;
  border-bottom-color: dodgerblue;
}

/* Company New Design starts */
.dflex1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.dflex2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sum-aica {
  color: #000;
  font-size: 10px;
  font-weight: 500;
  padding: 8px 18px 5px 18px;
  cursor: pointer;
  border-radius: 4px;
  background: white;
  border: 1px solid black;
  margin-right: 8px;
}

.sum-zeus {
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  padding: 8px 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background: linear-gradient(127.94deg, #2611d6 0%, #9b25a0 100%);
}

.sum-zeus-animation1 {
  animation: zoomInUp;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s;
}

.sum-zeus-animation2 {
  animation: rubberBand;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s;
}

.hearbeat-animation {
  animation: flash;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 10s;
}

.company-tabs {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  min-height: 30px;
  overflow: hidden;
  margin-top: -1px;
  margin-bottom: -1px;
}

.company-tabs .nav-link,
.company-tabs .nav-link.active {
  height: 100%;
  margin: auto;
  min-width: 80px;
  max-width: fit-content;
  border-radius: 0px;
}

.company-tabs .nav-link.active {
  background-color: #f3f3f3;
}

.company-tabs button {
  background-color: transparent;
  border: none;
  padding: 5px;
  font-size: 10px;
  font-weight: 600;
  height: 100%;
  width: 90px;
  color: #939393;
}

.border-right {
  border-right: 1px solid #e5e5e5;
}

.overview-border1 {
  border: 0.5px solid #e5e5e5;
  border-radius: 3px;
  background-color: #f9f9f9;
}

.overview-border2 {
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  background-color: #ffffff;
}

.overview-border {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: #ffffff;
}

.add-investor-select .css-1pahdxg-control,
.add-investor-select .css-yk16xz-control {
  border: 0.5px solid #4c8dff !important;
  border-radius: 3px !important;
  background-color: #ffffff !important;
  font-size: 8px !important;
  width: 100% !important;
  height: 25px !important;
  min-height: 25px !important;
}

.add-investor-select .css-b8ldur-Input,
.add-investor-select input,
.add-investor-select .css-1hwfws3,
.add-investor-select .css-1wy0on6,
.add-investor-select .css-1g6gooi,
.add-investor-select .css-g1d714-ValueContainer,
.add-investor-select .css-1hb7zxy-IndicatorsContainer {
  height: 100%;
}

/* Deal overview modal starts*/
.Trash-modal .modal-header {
  border-bottom: none;
}

.TOT-update-modal {
  border-bottom: none;
  z-index: 1102;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}

.clr-red {
  color: #c80000;
}

/* Deal overview modal ends*/
.overview-border input::-webkit-outer-spin-button,
.overview-border input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.overview-border input[type="number"] {
  -moz-appearance: textfield;
}

.overview-border input,
.overview-border input[type="text"]:focus,
.overview-border select,
.overview-border select[type="text"]:focus {
  border: 0.5px solid #4c8dff;
  border-radius: 3px;
  background-color: #ffffff;
  font-size: 8px;
  width: 100%;
  height: 25px;
}

.overview-border input:disabled,
.overview-border select:disabled {
  border: 0.5px solid #d2d2d2;
  border-radius: 3px;
  background-color: #ffffff;
  color: #adadad;
}

.disable-input {
  padding: 5px;
  border-radius: 3px;
  border: 0.5px solid #e5e5e5;
}

.save-btn svg {
  margin-right: 8px;
}

.disable-btn {
  cursor: no-drop;
  border-radius: 4px;
  background-color: rgb(183, 183, 183);
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  padding: 8px 20px;
  border: none;
}

*:focus {
  outline: none;
}

.status-tab {
  border-radius: 4px;
  background-color: #f3f3f3;
  color: #4f4f4f;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9px;
  height: 28px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 3px;
}

.listing-by-priority-tab {
  border-radius: 4px;
  background-color: #f3f3f3;
  color: #4f4f4f;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9px;
  height: 28px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 3px;
  margin-left: 30px;
}

.ri-on-off {
  border-radius: 4px;
  background-color: #f3f3f3;
  color: #4f4f4f;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9px;
  height: 20px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 3px;
  margin-left: 30px;
}

.stat {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  height: 22px;
  width: 71px;
  padding: 3px 2px 0 2px;
  margin-right: 2px;
  margin-left: 2px;
}

/* Company New Design ends */

.fa-thumbs-down {
  color: rgb(231, 109, 21);
}

.fa-thumbs-up {
  color: #009f00;
}

.w-58 {
  width: 58%;
}