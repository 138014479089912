.container {
    z-index: 1300;
    .header {
        .title {
            color: #000000;
            font-family: 'Gilroy-Medium';
            font-size: 16px;
        }
    }
    .body {
        .cancelReason {
            border-radius: 6px;
            border: 0.5px solid #FFFFFF;
            background: #F9F9F9;
            resize: none;
            width: 100%;
            padding: 16px;
            color: #000000;
            font-family: 'Gilroy-Medium';
            font-size: 12px;
            font-weight: 600;
            &::placeholder {
                color: #C3C3C3;
            }
        }
        .cta {
            display: flex;
            flex-direction: row-reverse;
            .cancelBtn {
                border: none;
                border-radius: 6px;
                background: #DF666A;
                color: #FFFFFF;
                font-family: 'Gilroy-Medium';
                font-size: 12px;
                padding: 12px 16px 10px;
                &:disabled {
                    background: #D8D8D8;
                    cursor: not-allowed;
                }
            }
        }
    }
}
