.Repayment-Data-Modal {
    .modal-content {
        width: 780px;
        padding: 14px 14px 30px 14px;
        margin-left: -100px;
        background-color: #F3F3F3;
    }

    .modal-header {
        font-size: 10px;
        font-weight: 300;
        border: none;
        display: flex;
        align-items: center;
        padding: 5px 0;
    }

    .modal-body {
        padding: 20px;
        background-color: #FFFFFF;
        .line {
            border-top: 2px solid #979797;
            opacity: 0.18;
            margin: 5px 0;
        }
        .textDecor {
            font-family: 'Graphik';
            font-size: 12px;
            font-weight: 300;
            span {
                color: #037BFF;
            }
        }
        .RepaymentDataTable {
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            max-height: 154px;
            overflow: overlay;
            margin: 30px 0;
            table {
                font-size: 8px;
                width: 100%;
                thead {
                    background-color: #F4F4F4;
                    th {
                        font-weight: 300;
                        padding: 8px 0;
                        &:first-child {
                            padding-left: 8px;
                        }
                    }
                }

                tbody {
                    tr {
                        border-top: 1px solid #E5E5E5;
                        td {
                            font-weight: 500;
                            padding: 8px 0;
                            &:first-child {
                                padding-left: 8px;
                            }
                        }
                    }
                }
            }
        }
        .repayment-footer {
            opacity: 0.5;
            color: #646464;
            font-size: 10px;
            font-weight: 300;
            span {
                color: #037BFF;
            }
        }
    }
}