.FetchDataContainer{
    display:flex;
    justify-content: space-between;
    width: 100%;
}
.InProgressText{
    font-family: Gilroy;
    font-size: 10px;
    font-weight: 600;
    line-height:  12px;
    margin-right: 5px;
}
.FetchButtonEnabled {
    border-radius: 4px;
    background-color: #4c8dff;
    color:#FFFFFF;
    columns: #ffffff;
    font-size: 10px;
    font-weight: 500;
    padding :8px 8px;
    border: none;
    height: 28px;
    width: 100px;
}
.FetchButtonDisabled {
    border-radius: 4px;
    background-color: #BCBCBC;
    color:#FFFFFF;
    columns: #ffffff;
    font-size: 10px;
    font-weight: 500;
    padding :8px 8px;
    border: none;
    height: 28px;
    width: 100px;
}