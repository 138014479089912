.nodatarow{
    font-size: 16px !important;
    font-weight: bolder !important;
    text-align: center;
}
.commontableHeadings{
font-size: 14px !important;
font-weight: 700 !important;
color: #535B62 !important;
// text-align: center;
}
.styledTableHeader{
    background-color: #F9FAFC;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}