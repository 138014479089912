
.repaymentModal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

// .header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 20px;
// }

.inputs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.inputs div {
  flex: 1;
}

.inputs label {
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.inputs input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.tableContainer {
 height: 60vh;
  overflow-y: auto;
  /* Make it scrollable */

  thead{ 
    position: sticky;
    top: 0;
    background-color: white;
    width: 100%;
    
  }
  .repaymentTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
}
.repaymentTable th,
.repaymentTable td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.repaymentTable th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.deleteBtn {
  color: red;
  background: none;
  border: none;
  cursor: pointer;
}

.addRepayment {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.HeaderAlignment{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 70px;
}

.deletedRow {
  background-color: #f8d7da; /* Light red background */
  color: #721c24;            /* Dark red text */
  text-decoration: line-through;
  opacity: 0.6;
}


.container {
  width: 100%;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
}

.tableWrapper {
  width: 100%;
  // overflow-x: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.headerGrid, .row {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Ensure both headers and rows have identical columns */
  gap: 1rem; /* Match the gap between headers and rows */
  align-items: center;
}

.headerGrid {
  gap: 5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.headerCell {
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  // text-align: left; /* Aligns text like the input fields */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures the header aligns vertically with row heights */
  white-space: nowrap; /* Prevents text from wrapping */
  text-overflow: ellipsis; /* Trims long header text with an ellipsis */
}

.rowsWrapper {
  display: flex;
  flex-direction: column;
  // gap: 0.75rem;
}

.row {
  padding: 0.5rem 0;
}

.row.deleted {
  opacity: 0.5;
}

.input {
  width: 100%;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  color: #374151;
}

.input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
  outline: none;
}

.input:disabled {
  background-color: #f3f4f6;
}

.deleteButton {
  padding: 0.5rem;
  color: #ef4444;
  background: none;
  border: none;
  cursor: pointer;
}

.deleteButton:hover {
  color: #dc2626;
}

.deleteButton:focus {
  outline: none;
}

.addRepaymentButton {
  margin-top: 1rem;
  color: #3b82f6;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.addRepaymentButton:hover {
  color: #2563eb;
}

.plusIcon {
  margin-right: 0.25rem;
}

.bottomActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.regenerateButton {
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
}

.regenerateButton:hover {
  background-color: #2563eb;
}

.regenerateButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #3b82f6;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #111827;
}

.timestamp {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.closeButton {
  background: none;
  border: none;
  color: #9ca3af;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.25rem;
}

.closeButton:hover {
  color: #6b7280;
}

.topFields {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.fieldGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  color: #6b7280;
  font-size: 0.875rem;
}

.topInput {
  width: 100%;
  padding: 0.75rem;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  color: #111827;
  font-size: 0.875rem;
}