.noDataInTable {
  color: #000000;
  font-size: 12px;
  font-family: 'Gilroy-Medium', serif;
  padding: 2rem;
}
.tableText {
  color: #000000;
  width: 15%;
  font-size: 0.625rem;
  font-family: 'Gilroy-SemiBold', serif;
  margin: 0;
  span {
    color: #df666a;
    font-size: 0.75rem;
    font-family: 'Gilroy-Medium', serif;
  }
}
.tableBanner {
  border-radius: 0.25rem;
  padding: 0.3rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NachdateInput{
  border: 1px solid #4C8DFF;
  padding: 0.25rem;
  border-radius: 0.25rem;
}
.iconsContainer{
  position: relative;
  width: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.searchInput{
  background-image: url('../../../../../assets/search.svg');
  background-repeat: no-repeat;
  background-position: center left 0.5rem;
}
.tooltip{
  padding: 0.5rem 1rem;
  background: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 1rem;
  max-width: 20rem;
  white-space: nowrap;
  border-radius: 0.25rem;
  border: 0.5px solid black;
}
.CommonSpacingOntTop{
  margin-top: 2.5rem;
}