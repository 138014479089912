.TableCard,
.TableCardHover {
  margin: 0;
  border-radius: 6px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);
  display: block;
  position: relative;
  overflow: overlay;
  scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
  scrollbar-width: thin !important;
  max-height: calc(100vh - 130px);

  &::-webkit-scrollbar {
    width: 19px !important;
    scroll-padding-right: 100px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px !important;
    border-left: 0px solid transparent !important;
    border-right: 0px solid transparent !important;
    margin: 48px 0 5px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e6e6 !important;
    border-radius: 10px !important;
    border-top: 6px solid white !important;
    border-bottom: 6px solid white !important;
    border-left: 6px solid white !important;
    border-right: 6px solid white !important;
  }

  table {
    width: 100%;

    thead {
      font-family: 'Gilroy-Bold';
      background-color: #f9fafc;
      height: 47px;
      border-bottom: 1px solid #e9e9e9;

      th {
        position: sticky !important;
        top: 0;
        background-color: #f9fafc;
        vertical-align: middle;
        color: #535b62;
        font-size: 14px;
        font-weight: 500;

        &:first-child {
          padding-left: 10px;
        }
      }
    }

    tbody {

      tr {
        color: #000000;
        font-size: 12px;
        font-weight: 300;
        border-bottom: 1px solid #e8e3e3;
        background-color: white;
        height: 73px;

        td {
          vertical-align: middle;
        }
      }
    }
  }
}

.TableCardHover table tbody tr {
  &:hover {
    background-color: #f9fafc;
  }
}



/* .market h3 {
    color: #29153B;
    font-family: Aktiv-grotesk;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
}
.market p {
  opacity: 0.8;
  color: #303030;
  font-family: Aktiv-grotesk;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
}
.listed-companies {
  opacity: 0.8;
  color: #000000;
  font-family: Aktiv-grotesk;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
}
.listed-companies h5 {
  opacity: 0.55;
  color: #000000;
  font-family: Aktiv-grotesk;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}
.horizontal {
    box-sizing: border-box;
  height: 1px;
  border: 1px solid #DCDCDC;
  width: 100%;
}

.table-content {
  box-sizing: border-box;
  border: 1px solid #E9E9E9;
  border-radius: 6px;
  background-color: #FFFFFF;
  position: relative;
  top: 27px;
  border-spacing: 0px;
  border-collapse: unset;
}
.table-content-upload{
  box-sizing: border-box;
  border: 1px solid #E9E9E9;
  background-color: #FFFFFF;
  position: relative;
  top: 27px;
  border-spacing: 0px;
  border-collapse: unset;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.table-content th {
  font-weight: 400;
}
.table-company {
  color: #4C8DFF;
  font-family: Aktiv-grotesk;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.table-range {
  display: flex;
    flex-wrap: wrap;
    margin: 2em;
    width: 349px;
    padding: 25px;
    position: relative;
    left: -183px;
    background-color: #F2F2F2;
}
.market-RS {
    margin: 2em;
    position: relative;
    left: -183px;
    background-color: #F9F9F9;
}
.market-RS1{
  border: 1px solid #E9E9E9;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 25px;
  margin-bottom: 30px;
}
.slider-tag, #output{
    color: #575757;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}
.advance-filter {
  color: #000000;
  font-family: Aktiv-grotesk;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}
.market {
  padding-left: 40px;
} */