
.SetNachdrawAcc {
  bottom:100;
  width: 280px;
  padding: 0 !important;
  border-radius: 8px;
  opacity: 1 !important;
  background-color: #ffffff;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 6%);
}

.SetNachdrawAcc::before {
    content: none !important;
  }
.SetNachdrawAcc::after {
    content: none !important;
    border-bottom-color: rgb(0, 0, 0);
  }
.sNText {
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    font-weight: 700;
    padding: 17px 20px 0px;
}
.subText {
    color: '#8A8A8A';
    font-family: 'Gilroy';
    padding: 10px 20px;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }

  .toolTipArrow{
    top:-10px;
    left:45%;
    position: absolute;
    background-color: white;
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    border-left: 1px solid rgb(0 0 0 / 6%);
    border-top: 1px solid rgb(0 0 0 / 6%);
  }

  .bankNameLabel{
    margin-left: 5px;
    font-weight: 500;
  }
  .disableColor{
    color:#BCBCBC;
  }
  .accountNoLabel{
    font-family: 'Gilroy-Bold';
    font-weight: 600;
  }
  .Status {
    display: inline-block;
    border-radius: 4px;
    font-family: 'Gilroy-SemiBold';
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    width: 90px;
    padding: 3px 0px;
    float: right;
}

.VerifiedText {
    color: #49A71B
}

.Verified {
    background-color: #E5FCDE;
}

.NotVerifiedText {
    color: #D6BB0A;

}

.NotVerified {
    background-color: #FFFAD7;
}

.bankLineItem{
    margin-bottom: 10px;
}