.NewTdsDrawerWrapper {
  .DrawerHeading {
    padding: 1.5rem;
    border-bottom: 1px solid #DCDCDC;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .HeadingText {
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Gilroy-SemiBold, sans-serif;
      font-size: 1.5rem;
      font-style: normal;
      display: flex;
      align-items: center;
      gap: 2rem;
    }
    .StatusBanner{
        font-family: Gilroy-Semibold, serif;
        display: flex;
        justify-content: center;
        font-size: 0.625rem;
        align-items: center;
        padding: 0.5rem;
        border-radius: 4px;
    }

    .CrossIcon {
      cursor: pointer;
    }
  }
  .DrawerBody{
    padding: 2rem 1.5rem ;
    .FileContainer{
      h6{
        font-size: 0.875rem;
        font-family: Gilroy-Medium,serif;
      }
    }
  }
  .File{
    border-radius: 0.25rem;
    background: #F9F9F9;
    padding: 1rem;
    margin: 0.5rem 0;
  }
  .FileName{
    cursor: pointer;
    color: #2F8FFF;
    font-family: Gilroy-Medium,serif;
    font-size: 0.75rem;
  }
  .TextContainer{
    display: flex;
    gap: 0.46rem;
    align-items: flex-start;
    margin-top: 0.62rem;
    border-radius: 0.375rem;
    background: #F9F9F9;
    padding: 0.5rem
  }
  .Icon{
    display: flex;
    align-items: flex-start;
  }
  .Text{
    h4{
      margin-bottom:0.5rem ;
      color: #000;
      font-family: Gilroy-semibold,serif;
      font-size: 0.875rem;
    }
    span{
      font-family: Gilroy-Bold,serif;
      font-size: 0.75rem;
    }
    p{
      margin: 0;
      font-family: Gilroy,serif;
        font-size: 0.75rem;
    }
  }
}
.TdsDrawerFooter{
  margin-top: auto;
  .FooterButtonsContainer{
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
    border-top: 1px solid #B7B7B7;
    background: #FFF;
    gap: 0.5rem;
    .FooterRejectBtn{
      border-radius: 0.375rem;
      border: 1px solid #DF666A;
      color: #DF666A;
      font-family: Gilroy,serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      background: #FFF;
    }
    .FooterSendReminderBtn{
      border-radius: 0.375rem;
      border: 1px solid #2F8FFF;
      color: white;
      font-family: Gilroy,serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      background: #2F8FFF;
    }
    .FooterCloseBtn{
      border-radius: 0.375rem;
      border: 1px solid #2F8FFF;
      color: #2F8FFF;
      font-family: Gilroy,serif;
      font-size: 0.75rem;
      display: flex;
      padding: 0.75rem 1.875rem;
      justify-content: center;
      align-items: center;
      background: white;
    }
  }
}