.tooltip {
  display: none;
}

.tooltipShow {
  display: block;
}

.upArrow {
  width: 0px;
  position: absolute;
  top: 100%;
  left: 70%;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid grey;
}

.overlayStyles {
  position: absolute;
  /* Position the tooltip absolutely within the relative container */
  top: 100%;
  /* Place the tooltip just below the component */
  left: 50%;
  /* Center the tooltip horizontally */
  transform: translateX(-50%);
  /* Adjust horizontal alignment */
  width: 600px;
  /* Set the width as needed */
  background-color: rgba(255, 255, 255, 0.5);
  /* Tooltip background color with transparency */
  border-radius: 15px;
  z-index: 9999;
}

;

.overlayArrow {
  height: 0px;
  width: 0px;
  margin-left: 50%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f8f8f8;
}

.dealStatuslement {
  text-align: left;
  color: #606060;
  font-size: 14px;
  font-family: "Gilroy";
  font-weight: 500;
}

.lineItembaseStyle {
  text-align: left;
  font-family: Gilroy;
  font-size: 10px;
  display: flex;
  align-items: center;
}

.lineItembaseCompletedStyle {
  margin-left: 10px;
  color: #979797;
  font-weight: 400
}

.lineItembaseCompletedStageNameStyle {
  color: #00AA00;
  font-weight: 600;
  flex: 30%
}

.lineItembasePendingStyle {
  margin-left: 9px;
  color: #FCBF07;
  font-weight: 700
}

.lineItembaseNotStartedStyle {
  margin-left: 10px;
  color: #979797;
  font-weight: 400
}

.dashedLineTop {
  border: 1px dashed #EBEBEB;
  margin-top: 15px;
  margin-bottom: 5px;
}

.dashedLineBottom {
  border: 1px dashed #EBEBEB;
  margin: 5px 0;
}

.lineItemImg {
  flex: 8%;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
}

.lineItemBody {
  display: flex;
  align-items: center;
  width: 100%;
}
.headers{
  display: flex;
  justify-content: space-between;
}

.flex30 {
  flex: 30%;
}
.flex25 {
  flex: 25%;
}
.flex20 {
  flex: 20%;
}
.flex15 {
  flex: 15%;
}
.contentStyles {
  box-shadow: 0 0 1px 0px #ebebeb;
  border-radius: 15px; 
  padding: 20px;
  border-radius: 15px;
  color: white;
  height: 90%;
  z-index: 9999;
  background-color: #f8f8f8; 
  text-align: center;
}