.custom-DatePicker {
    z-index: 2;
    position: relative;
    .react-datepicker__month-container{
        min-width: 243px;
    }
    .react-datepicker__input-container {
        input {
            border: none;
            font-family: Gilroy-Bold;
            font-size: 14px;
            color: #535B62;
            background-color: #fff;
            width: 8.6rem;
            border-radius: 4px;
            box-shadow: 0 2px 5px 4px rgba(113, 109, 109, 0.13);
            padding: 4px 8px;
            background-image: url('../../public/assets/expand-more.svg');
            background-repeat: no-repeat;
            background-position-x: 96%;
            background-position-y: 50%;
            background-size: 20px;
            cursor: pointer;

            &:focus{
             caret-color: transparent;
            }
        }
    }

    .react-datepicker__navigation {
        top: 10px;
    }

    .react-datepicker__month-text {
        padding: 3px 5px;
    }
}