/* Common CSS */
.dflex-je {
  display: flex;
  align-items: center;
  justify-content: end;
}

.dflex {
  display: flex;
  justify-content: space-between;
}

.dflexJA {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dflexJAT {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dflex-ac {
  display: flex;
  align-items: center;
}

.dflex-js {
  display: flex;
  align-items: start;
}

.dflex-ja {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dflex-jca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-45 {
  width: 45% !important;
}

.w-100 {
  width: 100% !important;
}

.border1 {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
}

.bb1 {
  border-bottom: 1px solid #979797;
}

.bb2 {
  border-bottom: 1px solid #979797;
}

.bb {
  padding: 5px 0px;
  border-bottom: 1px solid #f2f2f2;
}

.no-lb th:after {
  bottom: 0px !important;
  border-bottom: none !important;
}

.color1 {
  color: #4c4c4c;
}

.color2 {
  color: #4c8dff;
}

.color3 {
  color: #1c1c1c;
}

.color4 {
  color: #b2b2b2;
}

.color5 {
  color: #d95656;
}

.color6 {
  color: #00aa00;
}

.color6-1 {
  color: #00c73c;
}

.color7 {
  color: #dddddd;
}

.color8 {
  color: #1872d0;
}

.color9 {
  color: #000000 !important;
}

.color10 {
  color: #eb5e00;
}

.color11 {
  color: #d28f00;
}

.clr8 {
  color: #df0000;
}

.clr9 {
  color: #ca9b0c;
}

.clr10 {
  color: #009f00;
}

.clr11 {
  color: #2f8fff;
}

.clr12 {
  color: #4c4c4c;
}

.clr13 {
  color: #575757;
}

.clr14 {
  color: #b3b3b3;
}

.clr15 {
  color: #989898;
}

.clr16 {
  color: #8E8E8E;
}

.clr17 {
  color: #909090;
}

.clr-purple {
  color: #8f0a70;
}

.ml-24 {
  margin-left: 24px;
}

.company-link a {
  opacity: 0.7;
  color: #000000 !important;
  text-decoration: underline;
}

.company-status span {
  float: left;
  width: 100px;
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
}

.Waived-bg {
  border-radius: 2px;
  background-color: #faefce;
  max-width: 61px;
  color: #d28f00;
  font-size: 9px;
  font-weight: 600;
  padding: 5px 16px;
}

.app {
  border-radius: 4px;
  background-color: #fff5ee;
  text-align: center;
  float: left;
  color: #d09c6d;
  padding: 4px 0px;
}

.app-active {
  border-radius: 4px;
  background-color: #e5fcde;
  color: #49a71b;
  text-align: center;
  padding: 4px 0px;
}

.app-sub {
  border-radius: 4px;
  background-color: #4c8dff;
  text-align: center;
  color: #ffffff;
  padding: 4px 0px;
}
.outlinedbtn {
  border-radius: 4px;
  border: 1px solid #4c8dff !important;
  background-color: #ffffff;
  text-align: center;
  font-size: 10px;
  color: #000000;
}

.app-lock {
  border-radius: 4px;
  background-color: #d06d6d;
  text-align: center;
  color: #ffffff;
  padding: 4px 0px;
}

.doc {
  border-radius: 4px;
  background-color: #fdf4ff;
  text-align: center;
  float: left;
  color: #9e3cb9;
  padding: 4px 0px;
}

.wait {
  border-radius: 4px;
  background-color: #f4f4f4;
  text-align: center;
  float: left;
  color: #989898;
  padding: 4px 0px;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 2px 10px 0 0;
  float: left;
}

.invoices-status-Paid {
  border-radius: 4px;
  background-color: #e5fcde;
  text-align: center;
  color: #49a71b;
  padding: 4px 20px;
  font-size: 9px;
  font-weight: 600;
}

.invoices-status-Pending {
  border-radius: 4px;
  background-color: #fffad7;
  text-align: center;
  color: #d6bb0a;
  padding: 4px 12px;
  font-size: 9px;
  font-weight: 600;
}

.white {
  background-color: #ffffff;
}

.blue {
  background-color: #2f8fff;
}

.grey {
  background-color: #515151;
}

.red {
  background-color: #f40000;
}

.yellow {
  background-color: #fcbf07;
}

.green {
  background-color: #009f00;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600 !important;
}

.fw300 {
  font-weight: 300;
}

.fs26 {
  font-size: 26px;
}

.fs21 {
  font-size: 21px;
}

.fs18 {
  font-size: 18px;
}

.fs16 {
  font-size: 16px;
}

.fs14 {
  font-size: 14px;
}

.fs12 {
  font-size: 12px;
}

.fs10 {
  font-size: 10px;
}

.fs9 {
  font-size: 9px !important;
}

.fs8 {
  font-size: 8px;
}

.br10 {
  border-radius: 10px;
}

.br6 {
  border-radius: 6px;
}

.br4 {
  border-radius: 4px;
}

.br2 {
  border-radius: 2px;
}

.cp {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.btn1 {
  background-color: transparent;
  border: 0.5px solid #000000;
  border-radius: 4px;
  padding: 5px 15px;
}

.btn2 {
  border-radius: 4px;
  background-color: #d3d3d3;
  color: #ffffff;
  border: none;
  padding: 5px 15px;
  cursor: not-allowed;
}

.btn3 {
  border-radius: 4px;
  background-color: #4c8dff;
  color: #ffffff;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
}

.btn3:disabled {
  background-color: #CFCFCF;
  color: #ffffff;
  cursor: not-allowed;
}

.btn4 {
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  padding: 5px 15px;
}

.btn5 {
  border-radius: 4px;
  background-color: #4c8dff;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  padding: 8px 20px;
  border: none;
}

.btn6 {
  border: 1px solid #df666a;
  border-radius: 4px;
  background-color: #df666a;
  padding: 8px 20px;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
}

.btn-link {
  border: none;
  background-color: transparent;
  color: #4C8DFF;
  font-size: 10px;
  font-weight: 600;
  padding: 0px;
}

.btnht1 {
  height: 28px;
  width: 115px;
}

.blackcheck {
  height: 12px;
  width: 12px;
  background-color: #000000 !important;
}

.bluecheck {
  height: 12px;
  width: 12px;
  background-color: #4c8dff;
}

.check:disabled {
  cursor: default;
  background-color: #000000 !important;
  color: white !important;
  border-color: black !important;
}

/* Data-vault css starts */
.DV-RS-option {
  padding: 5px;
  margin: 1px 0;
}

.DV-RS-option:hover {
  background-color: #4c8dff;
  color: #ffffff;
}

.data-vault th:after {
  border-bottom: none;
}

.BgGreyBorder {
  border-radius: 6px;
  background-color: #f8f8f8;
  padding: 20px;
}

.BgGreyBorderDark {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #f1f1f1;
  padding: 20px;
}

.BgWhite {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 15px;
}

.Bgmoderate {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #ffffff;
  height: 60px;
  display: flex;
  align-items: center;
}

.shadowBorder {
  border-radius: 12px !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.05) !important;
}

.add-cp {
  margin-left: 25%;
  margin-top: 5px;
  color: #4c8dff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: end;
}

.add-cp span {
  cursor: pointer;
}

.add-cp img {
  width: 7px;
  margin-right: 2px;
  margin-top: -2px;
}

.add-new {
  height: 11px;
  width: 61px;
  color: #4c8dff;
  font-family: Graphik;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 11px;
}

.p20px {
  padding: 20px;
}

.dvhr {
  border-top: 2px solid #d7d7d7;
}

/* Data-vault css ends */

/* Sidebar CSS Starts */
.main-body {
  font-family: "Graphik";
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box;
  display: flex;
  min-height: 100vh;
  flex-wrap: nowrap;
}

.sidebar {
  max-width: 190px !important;
  /* justify-content: space-between; */
}

.admin-sidebar,
.admin-sidebar .MuiTabs-scroller {
  overflow: visible !important;
}

.MuiTabs-indicator {
  display: none !important;
}

.admin-sidebar .MuiTabs-flexContainer {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.line {
  border-top: 1px solid #555555;
  margin: 10px;
}

.admin-sidebar .MuiTab-wrapper {
  flex-direction: unset !important;
  justify-content: left;
  padding-left: 25px;
}

.admin-sidebar .MuiButtonBase-root {
  padding: 10px 0px;
  min-height: unset;
  font-weight: 300;
}

.admin-sidebar .Mui-selected {
  background-color: #2d2d2d;
}

.content-1 {
  margin: 25px 0 0 20px;
}

.content-1 .admin-tag-common {
  padding: 0 0 15px 0;
}

/* Sidebar CSS Ends */

.page-head {
  padding: 20px 25px 5px;
  align-items: center;
  height: 80px;
}

.page-head-right-side {
  width: 31%;
}

.number {
  color: #6a6a6a;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.forgot-login {
  position: relative;
  top: 7px;
}

.page-title {
  color: #1e3c71;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0px;
  padding: 0px;
}

.page-title-sub-para {
  color: #9b9b9b;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 40px;
  font-size: 12px;
  margin: auto;
}

.page-head-btn {
  border: none;
  background: transparent;
  letter-spacing: 0;
  line-height: 17px;
  text-align: left;
  align-items: center;
}

.page-head-btn img {
  height: 13px;
  width: 13px;
}

.page-head-btn-logout {
  color: #9da1a7;
  margin: 10% 0px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: 300;
  width: 60px;
}

.page-head-btn-logout:hover {
  font-weight: 500 !important;
  color: #ffffff !important;
}

.page-head-bottom-border {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}

.round-border {
  border: 1px solid #979797;
  border-radius: 10px;
}

.ffg {
  font-family: gilroy-regular, sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
}

.light-round-border {
  border: 1px solid #e9e9e9;
  border-radius: 10px 10px 0 0;
}

.page-body {
  padding: 0px;
  margin: 0px;
}

.page-body-margin {
  margin: 20px 15px;
  font-family: "Graphik";
  cursor: pointer;
}

.modal-content {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 4px rgba(0, 0, 0, 0.23);
}

/* KYC Modal css starts */
.KYCModal .modal-content {
  width: 546px;
}

.CardBorder {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  padding: 20px;
}

.CardBorder:first-child {
  margin-bottom: 30px;
}

.kyc-card-heading img {
  width: 16px;
}

/* KYC Modal css ends */
.modal-title {
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin: auto 0px;
}

.modal-dialog {
  margin-top: 100px;
}

/*Company Onboarding Head css */
.CO-head-contactBtn {
  height: 35px;
  width: 97px;
  background-color: #ffffff;
  border: 1px solid #017aff;
  border-radius: 6px;
  color: #4c8dff;
  font-weight: 300;
}

.help-head-fontsize {
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 16px;
  align-self: center;
}

/* Table Css */

/* new table with separate body starts*/
.table-head {
  border: 1px solid #e9e9e9;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0px;
  margin: 30px 0 0 0;
}

.table-body td {
  vertical-align: middle;
  word-wrap: break-word !important;
}

.table-body {
  border: 1px solid #e9e9e9;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* new table with separate body ends*/
.data-table {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 0px;
  /* min-width: max-content; */
  margin: 30px 25px;
  /* text-align: center; */
}

.admin-data-table {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 0px;
  /* min-width: max-content; */
  margin: 25px 0px;
}

.admin-data-table1 {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 0px;
  overflow: auto;
  margin: 10px 20px 35px 20px;
}

.admin-data-table1::-webkit-scrollbar {
  width: 5px !important;
}

.admin-data-table1::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.admin-data-table1::-webkit-scrollbar-thumb {
  background-color: rgb(168, 168, 168) !important;
}

.admin-form-field {
  width: 100%;
  height: 38px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  color: #d2d2d2;
  font-size: 12px;
  padding: 3px 5px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 12px;
}

.admin-form-field-testarea {
  height: 100px;
}

.admin-form-field:focus {
  outline: none;
}

.admin-modal-btn {
  width: 180px !important;
  margin-left: 61%;
}

button:focus {
  outline: none;
}

.team {
  /* opacity: 0.7; */
  color: #000000;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 0px;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0px;
}

.teamm {
  /* opacity: 0.7; */
  color: #000000;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 0px;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0px;
}

.last-updated-on {
  height: 13px;
  width: 88px;
  opacity: 0.53;
  color: #000000;
  font-family: Graphik;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 13px;
  margin-left: 6px;
}

.last-updated-on-date {
  height: 13px;
  width: 118px;
  opacity: 0.53;
  color: #000000;
  font-family: Graphik;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
}



.team-body {
  font-size: 16px;
  overflow-y: scroll;
  cursor: pointer;
}

.team-body tr td,
.investor-rows tr td {
  font-size: 12px;
  font-weight: 300;
  vertical-align: middle;
}

.team-body-underwriting tr td {
  font-size: 11px;
  font-weight: 300;
  vertical-align: middle;
}

.table td,
.table thead th {
  /* padding: 20px 0px; */
  font-size: 12.5px;
  font-weight: 500;
  box-sizing: border-box;
}

.table thead th {
  border-top: 0px solid #dee2e6;
  border-bottom: 0px solid #dee2e6;
  vertical-align: middle;
}

.table td {
  border-bottom: 0px solid #dee2e6;
}

.otpBtn {
  height: 70px;
}

.table tbody tr:nth-child(1) {
  border-top: 0px;
}

.table-row-border-right {
  border-right: 1px solid #979797;
}

.bgclr1 {
  background-color: #f1f1f1;
}

.bgclr2 {
  background-color: #f9f9f9;
}

.bgclr3 {
  background-color: #f3f3f3;
}

.bgclr4 {
  background-color: #4c8dff;
}

.bgclr5 {
  background-color: #df666a;
  color: #ffffff;
}

.bgclr6 {
  background-color: #27ba27;
  color: #ffffff;
}

.bgclr7 {
  background-color: #00aa00;
  color: #ffffff;
}

.bgclr8 {
  background-color: #faefce;
}

.bgclr9 {
  background-color: #f5f5f5;
}

.bgclr10 {
  background-color: #f8f8f8;
}

.bgclr11 {
  background-color: #cfcfcf;
}

.bgclr12 {
  background-color: #F7F7F7;
}

.CC {
  color: #d06d6d;
  background-color: #fbeeee;
  padding: 3px 27px;
  border-radius: 4px;
}

.Upsell {
  color: #056774;
  background-color: #83d7e6;
  padding: 3px 25px;
  border-radius: 4px;
  font-size: 10px;
}

.Low {
  color: #49a71b;
  background-color: #e5fcde;
  padding: 3px 25px;
  border-radius: 4px;
  font-size: 10px;
}

.Medium {
  color: #fcbf07;
  background-color: #fff5d5;
  padding: 3px 18px;
  border-radius: 4px;
  font-size: 10px;
}

.High {
  color: #f40000;
  background-color: #ffd4d4;
  padding: 3px 23px;
  border-radius: 4px;
  font-size: 10px;
}

.FANTASTIC {
  color: #8f00ff;
  border-radius: 4px;
  font-size: 12px;
}

.GOOD {
  color: #00AA00;
  border-radius: 4px;
  font-size: 12px;
}

.SPARK {
  color: #5A5A5A;
  border-radius: 4px;
  font-size: 12px;
}

.DIFFICULT {
  color: #FCBD07;
  border-radius: 4px;
  font-size: 12px;
}

.REJECT {
  color: #f40000;
  border-radius: 4px;
  font-size: 12px;
}

.active-status {
  border-radius: 4px;
  background-color: #e5fcde;
  color: #49a71b;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 13px;
}

.inactive-status {
  border-radius: 4px;
  background-color: #fad9d9;
  color: #b72d2d;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 13px;
}

.processing-status {
  border-radius: 4px;
  background-color: #f7d92f;
  color: #020000;
  font-family: Graphik;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 13px;
}

.listed-bg {
  border-radius: 4px;
  /* background-color: #E9ED3A; */
  padding: 5px 22px;
  background-color: #eaeaea;
  color: #000000;
  font-weight: 500;
}

.processing-bg {
  border-radius: 4px;
  background-color: #e4eeff;
  padding: 5px 10px;
  color: #4c8dff;
  font-weight: 500;
}

.active-bg {
  border-radius: 4px;
  background-color: #e5fcde;
  padding: 5px 20px;
  color: #49a71b;
  font-weight: 500;
}

.cancel-bg {
  border-radius: 4px;
  background-color: #e78c8c;
  padding: 5px 10px;
  color: #b51b1b;
  font-weight: 500;
}

.Need-Information-bg {
  white-space: nowrap;
  border-radius: 4px;
  background-color: #fff5ee;
  padding: 5px 10px;
  color: #d09c6d;
  font-weight: 500;
}

.expire-bg {
  border-radius: 4px;
  background-color: #b7b7b7;
  padding: 5px 18px;
  color: #5f5f5f;
  font-weight: 500;
}

.scheduled-bg {
  border-radius: 4px;
  background-color: #e4eeff;
  padding: 5px 10px;
  color: #4c8dff;
  font-weight: 500;
}

.completed-bg {
  border-radius: 4px;
  background-color: #b7b7b7;
  padding: 5px 10px;
  color: #5f5f5f;
}

.Expired-bg {
  border-radius: 4px;
  background-color: #ffe7e8;
  color: #de666b;
  font-size: 10px;
  padding: 5px 12px;
}

.Submitted-bg {
  border-radius: 4px;
  background-color: #f4f4f4;
  color: #989898;
  font-size: 10px;
  padding: 5px 12px;
  font-weight: 600;
}

.Approved-bg, .Verified-bg {
  border-radius: 4px;
  background-color: #e5fcde;
  color: #49a71b;
  font-size: 10px;
  padding: 5px 13px;
  font-weight: 600;
}

.Approved-For-Debt-Syndication-bg {
  border-radius: 4px;
  background-color: #e5fcde;
  color: #49a71b;
  font-size: 10px;
  padding: 3px 3px;
  font-weight: 600;
}

.bold-table-cel {
  color: #000000;
  font-weight: 400 !important;
}

.blue-table-cel {
  color: #4c8dff;
}

.darkblue-table-cel {
  color: #135e9c;
  /* font-weight: 700; */
}

.status-open-table-cel {
  color: #135e9c;
  /* font-weight: 700; */
}

.status-uploaded-table-cel {
  color: #135e9c;
  /* font-weight: 700; */
}

.status-approved-table-cel {
  color: #279c13;
  /* font-weight: 700; */
}

.status-swapped-table-cel {
  color: #279c13;
  /* font-weight: 700; */
}

.status-not-approved-table-cel {
  color: #cc0707;
  /* font-weight: 700; */
}

.status-on-hold-table-cel {
  color: #000000;
  /* font-weight: 700; */
}

th:after,
th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

th:after {
  bottom: -1px;
  border-bottom: 1px solid #dee2e6;
}

/* Table Css Ends */

/* ScrollBar for contract detail */
.tableFixHead-contract {
  display: block;
  position: relative;
  overflow: overlay;
  height: calc(100vh - 400px) !important;
}

.tableFixHead-contract thead th {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
  vertical-align: middle;
}

.tableFixHead-contract::-webkit-scrollbar {
  width: 10px !important;
  scroll-padding-right: 100px !important;
}

.tableFixHead-contract::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 10px !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  margin-top: 54px;
  margin-bottom: 2px;
}

.tableFixHead-contract::-webkit-scrollbar-thumb {
  background: #e6e6e6 !important;
  border-radius: 0px !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid white !important;
}

/* ScrollBar for contract detail */

/* ScrollBar Css Starts */
.tableFixHead {
  display: block;
  position: relative;
  overflow: overlay;
  scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
  scrollbar-width: thin !important;
  max-height: calc(100vh - 200px) !important;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
  vertical-align: middle;
}

.tableFixHead::-webkit-scrollbar {
  width: 10px !important;
  scroll-padding-right: 100px !important;
}

.tableFixHead::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 10px !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  margin-top: 0px;
  margin-bottom: 2px;
}

.tableFixHead::-webkit-scrollbar-thumb {
  background: #e6e6e6 !important;
  border-radius: 0px !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid white !important;
}

/* ScrollBar Css Ends */

.expand:hover {
  overflow: visible;
  white-space: pre-wrap;
}

.text-truncate {
  cursor: pointer;
}

/* Tooltip css starts */
.tooltips {
  position: relative;
  display: contents;
  cursor: pointer;
  border-bottom: 1px dotted black;
}

.tooltips .tooltiptexts {
  visibility: hidden;
  /* width: 120px; */
  /* background-color: black; */
  /* color: #fff; */
  text-align: center;
  /* border-radius: 6px; */
  padding: 10px;
  text-align: left;
  position: absolute;
  z-index: 1;
  top: 31px;
  left: 36%;
  margin-left: -60px;
  width: 230px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.06);
  color: #575757;
  font-size: 9px;
  letter-spacing: 0;
  line-height: 12px;
}

.tooltips .tooltiptexts::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 25%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.tooltips .after1::after {
  left: 50% !important;
}

.tooltips .after2::after {
  left: 80% !important;
}

.tooltips:hover .tooltiptexts {
  visibility: visible;
}

/* Tooltip css ends */

/* Common CSS Ends */
@media only screen and (max-width: 1000px) {
  .page-head {
    display: block;
  }

  .page-title-sub-para {
    font-size: 15px;
  }

  .page-head-btn {
    padding: 0px;
  }
}

@media only screen and (max-width: 1320px) {
  /* .page-title-sub-para {
        line-height: inherit;
    } */
}

.rectangle-swap {
  height: 31px;
  width: 40px;
  display: flex;
  /* background-color: #4C8DFF; */
  align-content: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 3px;
  flex-wrap: wrap;
}

.rectangle-liquidate {
  height: 31px;
  width: 40px;
  display: flex;
  background-color: #a46023;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 3px;
  flex-wrap: wrap;
}

/* Spin Loader css starts */
#loading-spinner {
  display: inline;
  position: relative;
  z-index: 999999;
}

#loading-spinner .spin-icon {
  margin: auto;
  width: 15px;
  height: 15px;

  border: solid 3px transparent;
  border-top-color: #666;
  border-left-color: #666;
  border-radius: 80px;

  -webkit-animation: loading-spinner 500ms linear infinite;
  -moz-animation: loading-spinner 500ms linear infinite;
  -ms-animation: loading-spinner 500ms linear infinite;
  -o-animation: loading-spinner 500ms linear infinite;
  animation: loading-spinner 500ms linear infinite;
}

@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes loading-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes loading-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes loading-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Spin Loader css ends */

.need-trigger:after {
  content: " *";
  color: red;
  font-size: 17px;
}

.need-trigger-xnpv:after {
  content: " *";
  color: rgb(4, 0, 255);
  font-size: 17px;
}
.underwritting-link{
  margin-top: 30px;
}
.underwritting-link a:hover {
  text-decoration: none !important;
}

.data-vault-data-top{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}

.underwritting-table tr th:nth-child(1),
.team-body-underwriting tr td:nth-child(1) {
  border-left: 1px solid #e9e9e9;
}

.underwritting-table tr th:nth-child(1) {
  border-top-left-radius: 6px;
}

.crm-sync-btn {
  border-radius: 4px;
  background-color: #4c8dff;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  padding: 7px 17px;
  padding-bottom: 8px;
  border: none;
}

.delist-btn {
  border-radius: 4px;
  background-color: #a53939;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 7px 17px;
  padding-bottom: 8px;
  border: none;
}

.add-new-bank-btn {
  border-radius: 4px;
  background-color: #4c8dff;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  padding: 4px 16px;
  padding-top: 6px;
  border: 1px solid #4c8dff;
}
.change-nach-btn{
  border: 1px solid #4c8dff;
  background: none;
  color: #4c8dff;
  font-size: 10px;
  font-weight: 600;
  padding: 6px 16px 4px;
  border-radius: 4px;
  margin-right: 10px;
}
#nach-account-change{
  margin-top: 20px;
}

.create-new-nach-btn {
  border-radius: 4px;
  background-color: #4c8dff;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  padding: 9px 16px 8px;
  border: none;
}

.admin-bank-account {
  display: block;
  position: relative;
  overflow: overlay;
  height: 200px;
}

.admin-bank-account,
.admin-nach {
  overflow: auto;
}

.admin-nach thead th,
.admin-bank-account thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.admin-nach thead th,
.admin-bank-account thead th {
  background-color: #ffffff;
  border-bottom: 1px solid #dee2e6;
}

.admin-nach {
  display: block;
  position: relative;
  overflow: overlay;
  height: calc(100vh - 500px) !important;
}

.create-nach-btn {
  border-radius: 4px;
  background-color: #4c8dff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 17px;
  padding-bottom: 8px;
  margin-top: 20px;
  border: none;
  width: 467px;
}

.create-nach-field {
  background-color: #f9f9f9;
  width: 100%;
  height: 46px;
  border: 0.1px solid #ffffff;
  border-radius: 4px;
  color: #d2d2d2;
  font-size: 12px;
  padding: 3px 5px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 12px;
}

.create-nach-field-testarea {
  height: 100px;
}

.create-nach-field:focus {
  outline: none;
}

.nach-status-SUCCESS{
  border-radius: 4px;
  background-color: #E5FCDE;
  text-align: center;
  color: #49A71B;
  padding: 4px 18px;
  font-size: 11px;
  font-weight: 600;
}

.nach-status-IN{
  border-radius: 4px;
  background-color: #FFFAD7;
  text-align: center;
  color: #D6BB0A;
  padding: 4px 18px;
  font-size: 11px;
  font-weight: 600;
}

.nach-status-NOT{
  border-radius: 4px;
  background-color: #F4F4F4;
  text-align: center;
  color: #989898;
  padding: 4px 18px;
  font-size: 11px;
  font-weight: 600;
}


.nach-status-Generated {
  border-radius: 4px;
  background-color: #f4f4f4;
  text-align: center;
  color: #989898;
  padding: 4px 12px;
  font-size: 11px;
  font-weight: 600;
}

.nach-status-FAILED {
  border-radius: 4px;
  background-color: #FFD6D6;
  text-align: center;
  color: #8B0000;
  padding: 4px 24px;
  font-size: 11px;
  font-weight: 600;
}

.nach-status-Aborted {
  border-radius: 4px;
  background-color: #ffbebc;
  text-align: center;
  color: #bb6054;
  padding: 4px 18px;
  font-size: 11px;
  font-weight: 600;
}

.nach-type-E-NACH {
  border-radius: 4px;
  background-color: #fdf4ff;
  text-align: center;
  color: #9e3cb9;
  padding: 4px 20px;
  font-size: 11px;
  font-weight: 600;
  width: 200px;
}

.nach-type-Paper {
  border-radius: 4px;
  background-color: #fff5ee;
  text-align: center;
  color: #d09c6d;
  padding: 4px 25px;
  font-size: 11px;
  font-weight: 600;
}

.nach-type-FAC-ENACH {
  border-radius: 4px;
  background-color: #FFCFAD;
  text-align: center;
  color: #8A5F38;
  padding: 4px 25px;
  font-size: 11px;
  font-weight: 600;
}

.nach-type-FAC-PAPER {
  border-radius: 4px;
  background-color: #F9D2FF;
  text-align: center;
  color: #763C86;
  padding: 4px 25px;
  font-size: 11px;
  font-weight: 600;
}

.nach-entry-style {
  color: "#4C4C4C";
  font-family: "Gilroy-SemiBold"; 
}

.gst-link-button {
  font-size: 14px;
  padding-top: 2px;
  padding-left: 5px;
  color: #4c8cff;
  cursor: pointer;
}

.CustomRadioButton .switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 18px;
}

.CustomRadioButton .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.CustomRadioButton .slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0px;
  bottom: 0px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.CustomRadioButton .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 1px;
  background-color: black;
  -webkit-transition: .4s;
  transition: .4s;
}

.CustomRadioButton input:checked+.slider {
  background-color: #2196F3;
}

.CustomRadioButton input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.CustomRadioButton input:checked+.slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.CustomRadioButton .slider.round {
  border-radius: 34px;
}

.CustomRadioButton .slider.round:before {
  border-radius: 50%;
}

/* Document Vault custom radio btn starts */
.DocumentVaultSwitch .switch {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 12px;
  border: none;
}

.DocumentVaultSwitch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.DocumentVaultSwitch .slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0px;
  bottom: 0px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.DocumentVaultSwitch .slider:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  left: 1px;
  bottom: 1.5px;
  background-color: black;
  -webkit-transition: .4s;
  transition: .4s;
}

.DocumentVaultSwitch input:checked+.slider {
  background-color: #8ED375;
}

.DocumentVaultSwitch input:focus+.slider {
  box-shadow: 0 0 1px #8ED375;
}

.DocumentVaultSwitch input:checked+.slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.DocumentVaultSwitch .slider.round {
  border-radius: 34px;
}

.DocumentVaultSwitch .slider.round:before {
  border-radius: 50%;
}

/* Document Vault custom radio btn ends */
.popupOverlay{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background:rgba(0, 0, 0, 0.5);
  z-index: 1200;
}
.popupMessage{
  border-radius: 10px;
  box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.05) !important;
  background-color: #ffffff;
  text-align: center;
  padding: 50px 20px 20px 20px;
  height: 280px;
  width: 600px;
}

.font-italic {
  font-style: italic;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.link_anchor {
  color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.link_anchor:hover {
  color: #007bff;
  text-decoration: underline;
}

.moderation_upload{
  border: 1px solid #4C8DFF;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  color: #4C8DFF;
  background-color: #fff;
  cursor: pointer;
}

#FileName:hover {
  text-decoration: underline;
  text-decoration-color: #4C8DFF;
  cursor: pointer;
 }
