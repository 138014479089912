.RemainingOnlyFilter{
    font-size: 12px;
    font-family: Gilroy-SemiBold;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-left: 10px;

    >span{
        margin-right: 5px;
    }
    label{
        margin: 0;
    }
}
.investorOrgName{
    border: none;
    font-family: Gilroy-Bold;
    font-size: 14px;
    color: #535B62;
    background-color: #fff;
    width: 8rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px 4px rgba(113, 109, 109, 0.13);
    padding: 0.5rem 1rem;
   margin-right: 10px;
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 50%;
    background-size: 20px;
    cursor: pointer;

    &:focus{
     caret-color: transparent;
    }
}

.investorOrgNameInput {
    & input{
    font-size: 14px;
    color: #535B62;
    width: 16rem;
    height: 1.85rem;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
   margin-right: 10px;
}
}