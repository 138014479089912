.requestedRowMiddle{
    font-size: 10px;
    white-space: nowrap;
}

.riskTooltipWrapperUwFlag{
    width: 15rem;
    h6{
        white-space: nowrap;
    }
    
}

.riskRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    color: #999999;
}
.riskRowLeft{
    font-size: 10px;
    white-space: nowrap;
    width: 40%;
}
.requestedRowRight{
    font-size: 10px;
    white-space: nowrap;
    width: 18%;
}

.FANTASTIC {
    color: #8f00ff;
    border-radius: 4px;
    font-size: 12px;
}
  
.GOOD {
    color: #00AA00;
    border-radius: 4px;
    font-size: 12px;
}
  
.SPARK {
    color: #5A5A5A;
    border-radius: 4px;
    font-size: 12px;
}
  
.DIFFICULT {
    color: #FCBD07;
    border-radius: 4px;
    font-size: 12px;
}
  
.REJECT {
    color: #f40000;
    border-radius: 4px;
    font-size: 12px;
}