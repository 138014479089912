.MessageConatiner {
  display: flex;
  padding:1rem;
  gap: 1rem;
  font-size: 14px;
  font-family: Gilroy-medium, san-serif, serif;
}
.Icon {
  width: 1rem;
  height: 1rem;
}
