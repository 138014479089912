$fontFamilyMedium: "Gilroy-Medium", sans-serif;
$crossButtonHeightandWidth: 14px;

.wireTransferDrawerContainer {
  display: flex;
  flex-direction: column;
  height: 100%; 
  justify-content: space-between;

  .headerComponent {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D2D2D2;
    padding: 0 2rem;

    .headerText {
      font-size: 1rem;
      font-family: $fontFamilyMedium;
    }

    & > img {
      color: rgba(188, 188, 188, 1);
      height: $crossButtonHeightandWidth;
      width: $crossButtonHeightandWidth;
      cursor: pointer;
    }
  }

  .wireTransferBox {
    padding: 2rem;
    flex: 1;
    overflow-y: auto; 
  }

  .wireTransferDrawerFooter {
    border-top: 1px solid #D2D2D2;
    height: 80px;
    width: 100%;
    display: flex;
    gap: 1.5rem;
    justify-content: end;
    align-items: center;
    padding-right: 1.5rem;
  }
}

.drawerPaper {
  width: 41rem;
  z-index: 100;
  animation: slideInFromRight 0.3s ease-out;

  &.drawerPaperClose {
    animation: slideOutToRight 0.3s ease-out;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}