.InputBox {
    border: 0.5px solid #d6d0d0;
    border-radius: 6px;
    background-color: #f9f9f9;
    width: 100%;
    color: #adadad;
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 10px;
    margin-bottom: 0px !important;
    padding: 12px;
    outline: none;

    &:valid,
    &:focus {
        color: black;
        font-family: 'Gilroy-SemiBold', sans-serif;
        border: 0.5px solid #2f8fff;
        background-color: #ffffff;
    }

    &:disabled {
        border: 0.5px solid #f9f9f9;
        color: #000000;
    }
}

.FF {
    font-family: 'Gilroy', sans-serif;
}

.FormHead {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.StyledSpan {
    color: #939393;
    font-size: 15px;
    font-weight: 300;
    line-height: 16px;
}

.UploadSmallBox {
    width: 100%;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #bfbfbf;
    border-radius: 6px;
    background-color: #ffffff;
    cursor: pointer;
}

.StyledContentContainer {
    box-sizing: border-box;
    width: 65%;
    height: 115px;
    display: flex;
    align-items: center;
    padding: 27px;
    justify-content: center;
}

.StyledContentContainerSingle {
    box-sizing: border-box;
    min-width: min-content;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: flex-start;
    color: #4c8dff;
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 10px;
}

.FontSize20 {
    font-size: 15px;
    font-weight: 600;
}

.FontSize16 {
    font-size: 12px;
    font-weight: 300;
}

.UploadedFile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #4c8dff;
    padding: 3px 0;
    margin-top: 12px;
}

.AfterUpload {
    font-weight: 500;
    width: 100%;
    border: 0.5px solid #ffffff;
    border-radius: 6px;
    background-color: #f9f9f9;
    color: #4c8dff;
    font-size: 10px;
    padding: 10px;
}

.AfterUploaddiv {
    border-bottom: 1px solid #4c8dff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}
.AdditionDataModalSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 3%;
    text-align: center;
    min-height: 48px;
    height: 100%;
    width: 45% !important;
    font-family: gilroy-semibold, sans-serif;
    font-size: 12px;
    color: #000000;
    border-radius: 6px;
    text-align: left;
    padding-left: 3%;
    border: 0.5px solid #cecece;
    background: url('https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/select-dropdown.svg') no-repeat;
    background-size: 10px 12px;
    background-position-x: 95% !important;
    background-position-y: 50% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus,
    &:active,
    &:focus-visible {
        border: 0.5px solid #2f8fff;
    }
    &:disabled {
        background: transparent;
        background-color: #f9f9f9;
        border: 0.5px solid #cecece;
        color: #c3c3c3;
    }
}

.UploadAnother {
    margin-left: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    width: max-content;
    color: #4c8dff;
    font-size: 12px;
    padding: 8px 15px;
    border-radius: 6px;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.UploadedPDF {
    position: relative;
    border-radius: 6px;
    background-color: #f9f9f9;
    border: 0.5px solid #d9d9d9;
    color: #4c8dff;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px 12px 15px;
    margin-top: 12px;
    z-index: 2;

    .Link {
        color: #4c8dff;
        font-size: 10px;
        white-space: nowrap;

        &:hover {
            text-decoration: underline;
        }
    }

    .Imported {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 10px;
        line-height: 16px;
        color: #959595;
        cursor: default;

        & > img {
            margin-bottom: 2px;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

.UploadedPDFError {
    color: #d06d6d;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 12px;

    img {
        margin-bottom: 2px;
        margin-right: 5px;
    }
}

.styledUploadContainerSingle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 44px;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    background-color: #ffffff;
    position: relative;
    cursor: pointer;
}

.bankStmtBtn {
    border: 1px solid #ebebeb;
    border-radius: 6px;
    background-color: #ffffff;
    width: 100%;
    padding: 4px 15px 2px 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.additionalDoc {
    height: 33px;
    width: 100%;
    border: none;
    color: #4c8dff;
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 12px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.width_265 {
    width: 265px;
}
.uploadedPDFWrapper {
    width: 50%;
}
.fullwidth {
    width: 100%;
    margin-bottom: 10px;
}

.additionDataModalInput {
    border: 0.5px solid #d6d0d0;
    border-radius: 6px;
    background-color: #f9f9f9;
    width: 45% !important;
    color: #adadad;
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 10px;
    margin-bottom: 0px !important;
    padding: 12px;

    &:valid,
    &:focus {
        color: black;
        font-family: 'Gilroy-SemiBold', sans-serif;
        border: 0.5px solid #2f8fff;
        background-color: #ffffff;
    }

    &:disabled {
        border: 0.5px solid #f9f9f9;
    }
}

.uploadImg {
    // width: 40px !important;
    margin-top: 3px;
    height: 30px;
    margin-left: -4px;
    margin-right: 5px;
}

.width100 {
    width: 100%;
}

.textTruncate {
    max-width: 410px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    // display: flex;
    // align-items: center;
}

.textTruncate2 {
    max-width: 370px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    // display: flex;
    // align-items: center;
}

.downloadable {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.underline {
    text-decoration: underline;
}

.shortname {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.FileHasError {
    border: 1px solid rgb(127, 210, 2);
    position: relative;
    z-index: 2;
}

.ErrorBlock {
    padding: 5px 0px 0px 0px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    border-radius: 6px;
    color: #d06d6d;

    & > img {
        margin-bottom: 2px;
        margin-right: 5px;
    }

    .Link {
        color: #2f8fff;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.ProcessingContainer {
    margin-top: 10px;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    padding: 12px 15px 12px 15px;
    background-color: #f9f9f9;

    .ProcessingFile {
        background-color: #f9f9f9;
        color: #4c8dff;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .smallText {
            font-size: 10px;
            line-height: 16px;
            color: #959595;
            white-space: nowrap;
        }
    }

    .ProgressContainer {
        margin-top: 5px;
        height: 5px;
        background: #ededed;
        border-radius: 4px;
        position: relative;

        .Bar {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #4c8dff;
            border-radius: 4px;

            transition: all 200ms ease-in-out;
        }
    }
}

.AdditionalData {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.additionDataModalInputBox {
    border: 0.5px solid #d6d0d0;
    border-radius: 6px;
    background-color: #f9f9f9;
    // max-width: 265px !important;
    width: 50% !important;
    height: 100%;
    min-height: 48px;
    color: #adadad;
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 10px;
    margin-bottom: 0px !important;
    padding: 12px;

    &:valid,
    &:focus {
        color: black;
        font-family: 'Gilroy-SemiBold', sans-serif;
        border: 0.5px solid #2f8fff;
        background-color: #ffffff;
    }

    &:disabled {
        border: 0.5px solid #f9f9f9;
    }
}
.UploadDocBox {
    height: 0;
    transition: height 0.1s linear;
    overflow: hidden;
}
.DisabledUploadBox {
    @extend .UploadDocBox;
    cursor: no-drop !important;
}
