.CustomRadioButton .switch1 {
  position: relative;
  display: inline-block;
  width: 0.5rem !important;
  height: 0.69rem !important;
}
.switchContainer {
  position: absolute;
  right: 0.2rem;
  top: 0.5rem;
}
.CustomRadioButton .switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.CustomRadioButton .slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0px;
  bottom: 0px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.CustomRadioButton .slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 1px;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.CustomRadioButton input:checked + .slider {
  background-color: #2196f3;
}

.CustomRadioButton input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.CustomRadioButton input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.CustomRadioButton .slider.round {
  border-radius: 34px;
}

.CustomRadioButton .slider.round:before {
  border-radius: 50%;
}

/* Document Vault custom radio btn starts */
.switch1 {
  position: relative;
  display: inline-block;
  width: 1.875rem;
  height: 15px;
  border: none;
  margin: 0;
}

.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0px;
  bottom: 0px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 1px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 11px;
  width: 13px;
  left: 2px;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  bottom: 0.1rem;
  // margin-bottom: 1px;
}

input:checked + .slider {
  background-color: #8ed375;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8ed375;
}

input:checked + .slider:before {
  -webkit-transform: translateX(0.8rem);
  -ms-transform: translateX(0.8rem);
  transform: translateX(0.8rem);
}

/* Rounded sliders */ 
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 45%;
}
.switchContainerCompanyLevel {
  position: relative !important;
  top: 0.5rem;
  right: 1.5rem;
}