
@font-face {
  font-family: 'Graphik';
  font-weight: 600;
  src: url('../loader/loader/fonts/GraphikSemibold.otf') format('opentype');
}

.loader-screen {
  /* width: 100vh;
  height: 100vh; */
  /* background: rgba(0, 0, 0, 0); */
  background: rgba(17, 6, 1, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
}
.loader-div{
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.25);
  /* justify-content: center;
  align-items: center; */
  /* transform: scale(0.25); */
}

.loader {
  height: 100px;
  width: 100px;
  border-radius: 6px;
  color: white;
  text-align: center;
  margin: 10px;
}

.text {
  font-size: 80px;
  font-family: 'Graphik';
  font-weight: 600;
  position: relative;
  top: 60%;
  transform: translateY(-60%);
  -webkit-transform: translateY(-60%);
}

.letter-first {
  background: black;
  animation: loader-letter-first 1.5s infinite;
}

.loader.letter-mid {
  background: black;
  animation: loader-letter-mid 1.5s infinite;
}

.loader.letter-last {
  background: black;
  animation: loader-letter-last 1.5s infinite;
}

@keyframes loader-letter-first {
  from {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(0px, -120px);
  }
  50% {
    transform: translate(480px, -120px);
  }
  75% {
    transform: translate(480px, 0px);
  }
  to {
    transform: translate(480px, 0px);
  }
}
@keyframes loader-letter-last {
  from {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(0px, 120px);
  }
  50% {
    transform: translate(-480px, 120px);
  }
  75% {
    transform: translate(-480px, 0px);
  }
  to {
    transform: translate(-480px, 0px);
  }
}
@keyframes loader-letter-mid {
  from {
    color: white;
  }
  25% {
    color: black;
  }
  50% {
    color: black;
  }
  75% {
    color: white;
  }
  to {
    color: white;
  }
}