$font-family: 'Gilroy-Semibold', sans-serif;
$font-size-12: 0.75rem;

.outerDiv {
  display: flex;
  flex-direction: column;
  position: relative;

  & > label {
    position: absolute;
    top: 50%;
    left: 1rem;
    color: rgba(141, 141, 141, 1);
    font-size: $font-size-12;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.5s ease;
    z-index: 10;
    font-family: $font-family;
  }

  & > input {
    border: none;
    outline: none;
    border-radius: 6px;
    background-color: rgba(249, 249, 249, 1);
    padding: 0.625rem 1rem;
    position: relative;
    font-family: $font-family;
    font-size: $font-size-12;
    border: 0.5px solid rgba(217, 217, 217, 1);
  }

  .inputBox {
    background-color: #fff !important;
  }

  & > img {
    width: 1rem;
    height: 1rem;
    right: 1rem;
    position: absolute;
    top: 35%;
  }

  &:focus-within > label,
  &.hasValue > label {
    top: 0;
    left: 0;
    transform: translateY(-100%);
    font-size: 0.5rem;
    color: rgba(141, 141, 141, 1);
  }

  &:focus-within > input {
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border: 0.5px solid rgba(76, 141, 255, 1);
  }
}