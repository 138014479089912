// top card css starts
.TopCard {
    height: 95px;
    background: #F7F7F7;
    border-radius: 7px;
    font-family: Gilroy-SemiBold;

    .TopCardHeader {
        font-size: 12px;
        color: #323232;
    }

    .GreenText {
        font-size: 24px;
        color: #00AA00;
    }

    .GreyBigText {
        font-size: 24px;
        color: #AEAEAE;
        margin-right: 20px;
    }

    .GreySmallText {
        font-size: 10px;
        color: #AEAEAE;
    }
}

.TableCard {
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 10px;

    .w650 {
        width: 650px;
    }

    table {
        width: 100%;

        thead {
            tr {
                height: 58px;
            }

            th {
                font-family: Gilroy-SemiBold;
                font-size: 12px;
                padding: 10px;
            }
        }

        tbody {
            .TableBodyHeaders {
                height: 50px;
                background: #F8F8F8;
                border-top: 1px solid #E9E9E9;
                border-bottom: 1px solid #E9E9E9;

                td {
                    padding: 10px;

                    &:nth-child(1) {
                        font-family: Gilroy-Bold;
                        font-size: 12px;

                        img {
                            transform: rotate(-90deg);
                        }
                    }

                    &:nth-child(2) {
                        .NotVerified {
                            background: #EBEBEB;
                        }

                        .Verified {
                            background: #E5FCDE;
                        }

                        div {
                            padding: 5px 8px;
                            border-radius: 5px;
                            font-family: Gilroy-SemiBold;
                            font-size: 10px;
                            color: #00AA00;
                            max-width: fit-content;
                        }
                    }
                }
            }

            .TableBody {
                font-family: Gilroy-Medium;
                font-size: 10px;

                td {
                    padding: 10px;
                    &:nth-child(1) {
                        font-family: Gilroy-SemiBold;
                        text-decoration-line: underline;
                        color: #4C8DFF;
                        cursor: pointer;
                    }

                    .NotDone {
                        background: #E3E3E3;
                    }

                    .Done {
                        background: #49A71B;
                    }

                    .Circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        float: left;
                    }

                    .Verified {
                        background: #E5FCDE;
                        color: #49A71B;
                    }

                    .NeedUpdate {
                        background: #FBEEEE;
                        color: #D06D6D;
                    }

                    .NotUploaded {
                        background: #F3F3F3;
                        color: #939393;
                    }

                    .Uploaded {
                        background: #E4EEFF;
                        color: #2F8FFF;
                    }

                    .Status {
                        border-radius: 4px;
                        font-family: 'Gilroy-SemiBold';
                        font-size: 10px;
                        text-transform: uppercase;
                        text-align: center;
                        width: 100px;
                        padding: 8px 0;
                    }

                    .DetailBtn {
                        font-family: 'Gilroy-SemiBold';
                        font-size: 10px;
                        text-transform: uppercase;
                        border: none;
                        border-radius: 4px;
                        text-align: center;
                        width: 70px;
                        padding: 5px 0;
                    }

                    .UploadBtn {
                        background-color: #FFFFFF;
                        border: 1px solid #2F8FFF;
                        color: #4C8DFF;
                    }

                    .VerifyBtn {
                        background: #2F8FFF;
                        border: 1px solid #2F8FFF;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}

.FlexBasis20 {
    flex-basis: 20%;
}

.MaxWidth80 {
    max-width: 80px;
}

// top card css ends


.DownloadAllButton {
    border-radius: 4px;
    border: 1px solid #4c8cff;
    box-sizing: border-box;
    height: 30px;
    min-width: 54px;
    opacity: 2;
    color: #4c8cff;
    font-weight: 500;
    font-size: 12px;
    background-color: white;
    
    .DivClass{
        display: flex;
        .DownloadAllIcon{
            margin:0px 5px 0px 3px;
        }
        .DownloadAllSpinner{
            margin:0px 3px 0px 1px;
        }
    }
  }