.TableContainer{
    position: absolute;
    border-radius: 0.5rem;
    background: white;
    left: 0;
    width: 800px;
    z-index: 100;
}
.TableHeader {
    font-size: 12px;
    padding: 0px 25px 0px 0px !important;
}
.TableHeader__InvestorName{
   @extend .TableHeader;
}
.TableHeader__CollectionAmount{
    @extend .TableHeader;
}
.TableHeader__NACH{
    @extend .TableHeader;
}
.TableHeader__NEFT{
    @extend .TableHeader;
}
.TableHeader__Remaining{
    @extend .TableHeader;
}
.TableHeader__DelayCharges{
    @extend .TableHeader;
   
}
.TableHeader__AccountNumber{
    @extend .TableHeader;
}
.TableHeader__IFSC{
    @extend .TableHeader;
}
.includeText{
    font-size: 10px;
}
.TableRow{
    font-size: 10px;
    width: max-content;
}
.TableRow__NEFT{
    @extend .TableRow;
}
.TableRow__NACH{
    @extend .TableRow;
    color: #999999;
}
.TableRow__Remaining{
    @extend .TableRow;
}
.TableRow__CollectionAmount{
    @extend .TableRow;
    
}
.TableRow__InvestorName{
    @extend .TableRow;
    color: #999999;
    width: 20%;
    margin-left: 20px;
    padding-left: 30px !important;
}
.TableRow__AccountNumber{
    @extend .TableRow;
    white-space: nowrap;
    width: auto;
}
.TableRow__IFSC{
    @extend .TableRow;
    white-space: nowrap;
    width: auto;
}