.StyledUploadContainerSingle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 44px;
    border-radius: 6px;
    background-color: #ffffff;
    position: relative;
    cursor: pointer;

    .StyledContentContainerSingle {
        box-sizing: border-box;
        min-width: min-content;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: flex-start;
        font-family: 'Gilroy-SemiBold';
        font-size: 10px;
        span {
            color: #4c8dff;
        }
    }
}