.TabContainer {
    border-radius: 8px;
    font-family: 'Gilroy-Bold';
    background-color: transparent;
    display: flex;
    align-items: center;
    width: 100%;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        list-style: none;
        border: none;
        padding: 0px;

        li {
            margin-right: 65px;

            a {
                width: max-content;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 20px;
                padding: 10px 0px;
                text-align: left;
                display: flex;
                align-items: center;
                margin-right: 0px;
                text-decoration: none;
            }
        }
    }
}

.TabLinks {
    color: #828282;
    font-weight: 300;

    &:hover {
        color: #000000;
    }
}

.TabLinksActive {
    border-bottom: 3px solid #000000;
    color: #000000;

    &:hover {
        color: #000000 !important;
    }
}

.BlueTabLinks {
    color: #C5C5C5;
    border-bottom: 3px solid transparent;
    font-weight: 300;

    &:hover {
        color: #2F8FFF;
    }
}

.BlueTabLinksActive {
    border-bottom: 3px solid #2F8FFF;
    color: #2F8FFF;

    &:hover {
        color: #2F8FFF!important;
    }
}