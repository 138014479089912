.mainBody {
    width: 100%;
  }
  
  .headerSection {
    display: flex;
    justify-content: space-between;
    padding: 28px 16px;
  }
  
  .title {
    color: #000;
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    padding: 33px 24px 0px 24px;
    gap: 0.75rem;
    
    button{
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 14px;
      min-width: 122px;
      height: 40px;
      background-color: #4C8DFF;
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
    }
  }
  
  .searchContainer {
    width: 355px;
    position: relative;
    margin-bottom: 1rem;
    
    input {
        width: 100%;
        padding: 8px 32px 8px 12px;
        border: 0.5px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        
        &:focus {
            outline: none;
            border-color: #4C8DFF;
        }
    }
    
    .searchIcon {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        
        img {
            width: 16px;
            height: 16px;
            opacity: 0.5;
        }
    }
  }
  
  .tableContainer {
    max-height: 600px;
    padding: 12px 0px;
  }
  
  .emptyMessage {
    text-align: center;
  }
  
  .statusHeader {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .statusFilter {
    padding: 4px;
    border: none;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
    cursor: pointer;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    
    &:focus {
      outline: none;
      border: 0.5px solid #0066cc;
    }
  }
  
  .loadingMessage {
    text-align: center;
    padding: 1rem;
    color: #666;
  }
  
  .filterTags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 4px;
    padding: 0px 36px 8px 36px;

    span {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 8px;
        background: #F5F5F5;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;

        img {
            width: 12px;
            height: 12px;
        }
    }
  }

  .filterContainer {
    min-height: 40px;
  }
  
  .filterTag {
    background: #E6F0FF !important;
    color: black;
  }
  
  .clearAll {
    background: #E6F0FF !important;
    color: #2F8FFF;
  }
  
  .loadingMore {
    text-align: center;
    padding: 1rem;
    font-style: italic;
    color: #666;
  }