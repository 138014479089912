.section {
    position: relative;
    div {
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .downloadBox {
        width: 180px;
        height: auto;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        position: absolute;
        z-index: 1;
        top: 19px;
        right: 2px;
        font-family: 'Gilroy-SemiBold';
        .option:hover {
            background-color: #F2F2F2;
            cursor: pointer;
        }
        .option {
            font-size: 12px;
            padding: 5px 10px;
        }
    }
}

.investorDrawdown {
    display: flex;
    justify-content: space-between;
    width: 30%;
}

.editButton{
    cursor: pointer;
    width: 13px;
  }