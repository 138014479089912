.SetupFrequency {
    cursor: pointer;
}

.Heading {
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    color: #000000;
}

.SetupFrequencyModalBody {
    padding-bottom: 0px;

    select,
    input {
        width: 100%;
        background: #F9F9F9;
        border: 0.5px solid #FFFFFF;
        border-radius: 6px;
        font-family: 'Gilroy-SemiBold';
        font-size: 12px;
        padding: 12px;
        margin-bottom: 30px;
        color: #000000;

        &:valid,
        &:focus {
            color: #000000;
            border: 0.5px solid #4c8dff;
            background-color: #ffffff;
        }
    }
}

.SetupFrequencyModalFooter {
    border: 0;
    padding-top: 0px;

    button {
        width: 100%;
        border: none;
        font-family: 'Gilroy-SemiBold';
        font-size: 12px;
        color: #FFFFFF;
        background: #4C8DFF;
        border-radius: 6px;
        padding: 10px 0;
    }
}