.MultipleTotFooterCard {
    margin: 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
   
    button {
        border-radius: 4px;
        font-family: 'Gilroy-SemiBold';
        font-size: 10px;
        color: #FFFFFF;
        border: none;
        padding: 6.5px 10px;

        img {
            width: 15px;
        }
    }

    .ModerateBtn {
        background: #4C8DFF;
    }

    .emailBtn {
        background: #00BD00;
        margin-right: 20px;
    }

    select {
        border: 1px solid #4C8DFF;
        border-radius: 4px;
        color: #4C8DFF;
        font-family: Gilroy-Medium;
        font-size: 10px;
        height: 32px;
        min-width: 199px;
        margin-right: 20px;
    }
}

.lastEmailStyle {
     flex: 0.45;
     font-size: 10px;
}

.applicationActiveToggle {
    flex:0.1;
    display: flex;
    align-items: center;
    font-family: 'Gilroy-SemiBold';
    font-size: 12px;
    white-space: nowrap;
    gap: 0.6rem;
    margin-right: 1rem;
}

.renderButtonStyle{
    flex:0.45;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.toggleContainer{
    input[type="checkbox"] {
        margin-top: 11px;
        display: inline-block;
        position: relative;
        width: 24px;
        height: 12px;
        -webkit-appearance: none;
        appearance: none;
        background: #DF666A;
        outline: none;
        border-radius: 2rem;
        cursor: pointer;

        &::before {
            content: "";
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #000000;
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
        }

        &:checked::before {
            transform: translateX(100%);
            background: #050000;
        }

        &:checked {
            background: #8ed375;
        }
        &:disabled {
            opacity: 0.7;
        }
    }
}

.disabledFontStyle{
    color: #9e9e9e;
}