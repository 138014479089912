.FilterContainer{
  border: none;
  font-family: Gilroy-Bold, serif;
  font-size: 14px;
  display: flex;
    align-items: center;
  color: #535B62;
  gap: 1rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px 4px rgba(113, 109, 109, 0.13);
  padding: 4px 8px;
  cursor: pointer;

  option {
    color: #535B62 !important;
    font-family: Gilroy-Bold, serif;
  }

  select {
    color: #535B62 !important;
    font-family: Gilroy-Bold, serif;
  }
}

.FilterSelect{
  width: 7rem;
  border: none;
  padding: 2px 8px;

}