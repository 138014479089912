.overallView {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    font-family: "gilroy-medium",sans-serif;
    > div {
        width: 23.3%;
        border-radius: 5px;
        background-color: #f9fafc;
        padding: 15px 0px 10px 20px;
        font-size: 14px;
    }
}
.repaymentRemainingCard{
    font-family: Gilroy-SemiBold,sans-serif;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    }
.repaymentTotalColor{
    color: #AEAEAE;
}
.overallViewCard{
    font-family: 'Gilroy-SemiBold',sans-serif;
    font-size: 24px;
    line-height: 30px;
}
.cardDate{
    width: 71px;
    height: 12px;
    left: 1325px;
    top: 189px;
    font-family: 'Gilroy-SemiBold',sans-serif;
    font-size: 10px;
    line-height: 20px;
    padding-left: 5px;
    color: #AEAEAE;
}

.notAvailable{
    color: #aeaeae;
    font-size: 24px;
    margin-right: 20px;
}