.ModeratorDetailsCard {
    font-family: Gilroy-SemiBold;
    font-size: 12px;

    .ModeratorDetailsLine1 {
        font-family: Gilroy-SemiBold;
        font-size: 14px;
    }

    // .actionBtns {
    //     span {
    //         cursor: pointer;
    //     }
    // }

    .editModerateBox {
        width: 450px;
        border: 1px solid #E4E4E4;
        border-radius: 6px;

        textarea {
            background: #FFFFFF;
            // border: 1px solid #E4E4E4;
            // border-radius: 6px;
            height: 150px;
            border: none;
            font-family: 'Gilroy-Medium';
            font-size: 10px;
            line-height: 12px;
            padding: 10px;
            margin-top: 6px;
            width: 90%;
            min-height: 60%;
            max-height: 200px;
            position: relative;
            overflow: overlay;
            scrollbar-color: rgb(168, 168, 168) #f1f1f1;
            scrollbar-width: thin;
            resize: none;

            &::-webkit-scrollbar {
                width: 19px;
                scroll-padding-right: 100px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 10px;
                border-left: 0px solid transparent;
                border-right: 0px solid transparent;
                margin: 48px 0 5px 0;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #e6e6e6;
                border-radius: 10px;
                border-top: 6px solid white;
                border-bottom: 6px solid white;
                border-left: 6px solid white;
                border-right: 6px solid white;
            }

            &::placeholder {
                color: #8D8D8D;
            }
        }

        .editBtns {
            padding: 10px;
            button {
                width: 120px;
                border-radius: 10px;
            }
            .cancelBtn {
                background-color: #FFFFFF;
                border: 1px solid #D06D6D;
                color: #D06D6D;
            }
            .saveBtn {
                background-color: #4C8DFF;
                color: #FFFFFF;
                // border: 1px solid #FFFFFF;
                border: none;
            }
            display: flex;
            justify-content: space-between;
        }

    }

    .moderateBox {
        font-family: gilroy-medium, sans-serif;
        background-color: #f6f6f6;
        border-radius: 6px;
        border-color: #f6f6f6;
        overflow: auto;
        width: 450px;
        height: 200px;
        padding: 10px;
        .commentRow {
            color: #C5C5C5;
            font-size: 10px;
        }
    }

    .LogRequestCallBtns {
        color: #4C8DFF;
        span {
            cursor: pointer;
        }
    }
    .disableBtns {
        color: #8D8D8D;
    }
}