.ReviewFinancialDataTitle {
    font-family: 'Gilroy-SemiBold';
    font-size: 20px;
    color: #CCCCCC;
    margin-bottom: 20px;
    margin-left: 70px;
    width:100%;
}

.ReviewFinancialDataCard {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(112, 144, 176, 0.16);
    border-radius: 8px;
    margin-left: 70px;
}
.toggleContainer {
    position: absolute;
    top: 53px;
    right: 100px;
    align-items: center;
    margin-top: 0.5rem;
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 10px;
    white-space: nowrap;
    gap: 1rem;
}