.PrepareTermsTitle {
    font-family: 'Gilroy-SemiBold';
    font-size: 20px;
    color: #CCCCCC;
    margin-bottom: 20px;
    margin-left: 30px;
}

.OuterCardHeader {
    margin-left: 30px;
    width:100%;
    display: flex;
    position: relative;

    .whiteCardHeading {
        color: #000;
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 14px;
        padding-bottom: 5px;
    }

    .greyCardHeading {
        text-align: center;
        color: #000;
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 12px;
        border-radius: 6px 6px 0 0;
        background: #D8D8D8;
        padding: 8px 0 5px;
    }

    .blueCardHeading {
        text-align: center;
        color: #000;
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 12px;
        border-radius: 6px 6px 0 0;
        background: #B7DCFC;
        padding: 8px 0 5px;
    }

    .yellowCardHeading {
        text-align: center;
        color: #000;
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 12px;
        border-radius: 6px 6px 0 0;
        background: linear-gradient(180deg, #FFF363 0%, #FFE234 100%);
        padding: 8px 0 5px;
    }

    .diffPercentage {
        color: #0A0;
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 10px;

        &.negativeRevenue {
            color: #DF666A;
        }
    }
}

.PrepareTermsCard {
    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(112, 144, 176, 0.16);
    border-radius: 8px;
    overflow: hidden;
    margin-left: 30px;
    width:100%;
    height: auto;
    overflow: auto;

    .OuterCardPositionRelative {
        display: flex;
        width: 100%;
        height: 600px;
        position: relative;
    }

    .OuterCardPositionRelativeDS {
        display: flex;
        width: 100%;
        height: 600px;
        position: relative;
        flex-direction: column;
    }

    .InnerCardPositionAbsolute {
        position: absolute;
        width: 100%;
        height: 600px;
        overflow: overlay;

        // .InnerCardPositionAbsoluteDS {
        //     width: 45.45%;
        // }

        .TwoInput {
            display: flex;
            justify-content: center;
            gap: 8px;
            select,
            input {
                width: 100%;
            }
        }

        textarea {
            min-height: 66px;
            max-height: 66px;
            overflow: auto;
        }

        select,
        textarea,
        input {
            background: #FFFFFF;
            border-radius: 3px;
            font-family: 'Gilroy-Regular';
            font-size: 10px;
            padding: 8px 10px;

            &:focus,
            &:valid {
                border: 0.5px solid #4C8DFF;
                color: #000000;
            }

            &:disabled {
                border: none;
                background: #F9F9F9;
                border: 0.5px solid #E5E5E5;
            }
        }
    }
}
.ShowMaxLimitCheckbox, .ShowStandardLimitCheckbox {
    width: 15px;
}
.ProjectedTradingLimit {
    min-width: 180px;
    margin-top: 8px;
    // margin-bottom: 30px;
    font-weight: 300;
    font-size: 15px;
    clear: both;
    float: right;
    .TotalAvailableLimit {
        color: #212529;
    }
}

    .tradingLimitInput::-webkit-outer-spin-button,
.tradingLimitInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Generic classes
.left {
    min-width: max-content;
}

.middle {

    select,
    textarea,
    input {
        border: 0.5px solid #F2F9FF;
        // align-items: center;
    }
}

.centerAlign {
    align-items: center;
}

.right {

    select,
    textarea,
    input {
        border: 0.5px solid #FFFDE9;
    }
}

.p_20_20 {
    padding : 0 20px 0 20px
}

.p_20 {
    padding: 5px 20px;
}
.p_3 {
    padding: 0px 0px 0px 0px;
}
.w_15 {
    width: 15%;
}
.w_18 {
    width: 18%;
}
.w_20 {
  width: 20%;
}
.w_25 {
    width: 25%;
}
.w_27 {
    width: 27%;
}
.w_30 {
    width: 30%;
}

.w_35 {
    width: 35%;
}

.w_60 {
    width: 60%;
}

.w_80 {
    width: 80%;
}

.greyCard {
    background-color: #F8F8F8;
}
.whiteCard {
    background-color: #FFFFFF;
}

.blueCard {
    background: #F2F9FF;
}

.yellowCard {
    background: #FFFDE9;
}

.customDropdown {
    background-color: #FFFFFF;
    border: 0.5px solid #4C8DFF;
    color: #000000;
  }
.customDropdownDisabled{
    border: none;
    background: #F9F9F9;
    border: 0.5px solid #E5E5E5;
    color: #adabab;
}  

.customDropdownDisabled,
.customDropdown{
    display: flex;
    justify-content: space-between;
    padding-left: 13px;
    align-items: center;
    font-size: 10px;
    width: 100%;
    height: 35px;
    border-radius: 5px;
}
.customDropdownText {
    display: flex;
    font-family: 'Gilroy-Regular';
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  .dropdownOptions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1; 
  }
  
  .option {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkboxLabel {
    padding-top: 5px;
    font-family: 'Gilroy-Regular';
  }
  
  /* Style for checkbox if needed */
  .optionsBox input[type="checkbox"] {
    
  }
  
  .downArrowImage {
    width: 15px;
    margin-right: 5px;
    padding-left: 5px;
    float: right; /* This property doesn't apply to images, consider using float or text-align for positioning */
  }
  .optionsContainer {
    justify-content: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    z-index:999;
  }


  .optionsBox{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #FFFFFF;
    border-width: 0.5px;
    border-color: #4C8DFF;
    border-style: solid;
    color: #000000;
    min-height: 30px;
    padding-left: 8px;
    font-size: 10px;
    height: 35px;
    width: 84%;
    margin-left: 20px;
 
   margin-top: -1px;
  }


.optionsBox:nth-child(1) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  
  .optionsBox:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .downloadEL {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #4C8DFF;
    margin-right: 105px;
  }

  .lowOpacity {
    opacity: 0.4;
  }

  .engagementLetter {
    margin: 10px 0 0 0;
    padding-right: 20px;
    width: 222px;
  }

  .signingDateStyling {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -85px;
  }

  .engagementLetterFlex {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
  }